import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import FlexBox from '@/atomic-components/FlexBox';
import { Alert, Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import WarningIcon from '@/assets/toaster/warningToastIcon.svg';
import InfoIcon from '@/assets/toaster/infoToastIcon.svg';
import CancelIcon from '@/assets/toaster/cancelToastIcon.svg';
import SuccessIcon from '@/assets/toaster/successToastIcon.svg';
import CrossIcon from '@/assets/toaster/closeIcon.svg';
import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import { useRouter } from 'next/router';

export enum NotificationSeverity {
	WARNING = 'Warning',
	INFO = 'Info',
	SUCCESS = 'Success',
	ERROR = 'Error',
}

export type NotifyToastProps = {
	id?: any;
	ctaText?: string;
	ctaLink?: string;
	message?: string;
	severity: NotificationSeverity;
	onClose?: () => void;
};

const SeverityMapper = new Map([
	[
		NotificationSeverity.WARNING,
		{
			icon: <WarningIcon />,
			background: 'linear-gradient(90deg, #FEE6A6 0%, #FEF4D1 100%)',
			defaultMessage: 'Something Went Wrong',
		},
	],
	[
		NotificationSeverity.INFO,
		{
			icon: <InfoIcon />,
			background: 'linear-gradient(90deg, #E8E8E8 0%, #F3F3F3 100%)',
			// message: 'Something Went Wrong',
		},
	],
	[
		NotificationSeverity.SUCCESS,
		{
			icon: <SuccessIcon />,
			background: 'linear-gradient(90deg, #B4E1E5 0%, #E6F5F6 100%);',
			defaultMessage: 'Success',
		},
	],
	[
		NotificationSeverity.ERROR,
		{
			icon: <CancelIcon />,
			background: 'linear-gradient(90deg, #FEAFA8 0%, #FEDBD5 100%)',
			defaultMessage: 'Something Went Wrong',
		},
	],
]);

const NotifyToast = (props: NotifyToastProps) => {
	const [visible, setVisible] = useState<boolean>(true);
	const vertical = 'top';
	const horizontal = 'right';

	const onClose = (e: any) => {
		e?.stopPropagation();
		if (typeof props.onClose == 'function') props.onClose();
		setVisible(false);
	};

	const severity = props?.severity || NotificationSeverity.WARNING;

	const { icon, background, defaultMessage } = SeverityMapper.get(severity)!;
	const message = props?.message || defaultMessage;

	const router = useRouter();

	return (
		<>
			<Snackbar
				autoHideDuration={5000}
				anchorOrigin={{ vertical, horizontal }}
				key={props.id}
				message={props?.message}
				open={visible}
				onClose={onClose}
				sx={{
					position: 'relative',
					marginBottom: '12px',
				}}
			>
				<Alert
					variant='filled'
					icon={icon}
					sx={{
						background: background,
						borderRadius: '16px',
						alignItems: 'center',
						paddingX: '12px',
						paddingY: '4px',
					}}
				>
					<FlexBox
						sx={{
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '280px',
						}}
					>
						<FlexBox direction='column'>
							<LencoTypography
								sx={{ ...getStyle('Small_Medium'), color: getColor('Gray 0') }}
							>
								{message}
							</LencoTypography>
							{props?.ctaText && (
								<LencoTypography
									sx={{
										...getStyle('Small_Medium'),
										color: getColor('Gray 0'),
										textDecoration: 'underline',
										cursor: 'pointer',
									}}
									onClick={() => router.push(`${props?.ctaLink}`)}
								>
									{props?.ctaText}
								</LencoTypography>
							)}
						</FlexBox>
						<Box
							sx={{ marginLeft: '24px', cursor: 'pointer' }}
							onClick={onClose}
						>
							<CrossIcon />
						</Box>
					</FlexBox>
				</Alert>
			</Snackbar>
		</>
	);
};

export default NotifyToast;
