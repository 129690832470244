import useForm from '@/hooks/useForm';
import {authService} from '@/services/auth';
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import UserSignUp from './UserSignUp';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import VerifyOtp from './VerifyOtp';
import EmailConfirmationPopUp from './UserSignUp/EmailConfirmationPopUp';
import AuthModal from './AuthModal';
import { ApiState } from '@/models/generics';
import { dataLayerPushWithEvent } from '@/utils/dataLayer';
import { useNotification } from '@/hooks/useNotification';
import { NotificationSeverity } from '../../../components/NotifyToast';
import { useLogin } from '@/hooks/useLogin';

export enum AUTH_STATES {
	VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER',
	VERIFY_OTP = 'VERIFY_OTP',
	USER_SIGN_UP = 'USER_SIGN_UP',
	EMAIL_ALREADY_REGISTERED = 'EMAIL_ALREADY_REGISTERED',
}

const formConfig = {
	mobile: {
		required: {
			isRequired: true,
			masg: 'Required',
		},
		valid: {
			regex: /^[6789]\d{9}$/,
			msg: 'Please enter a valid phone number.',
		},
	},
};

type AuthProps = {
	open: boolean;
	onClose: () => void;
};
const Auth = (props: AuthProps) => {
	const [page, setPageHandler] = useState<AUTH_STATES>(
		AUTH_STATES.VERIFY_PHONE_NUMBER
	);
	const [userDetails, setUserDetails] = useState<any>({});
	const [loginError, setLoginError] = useState('');
	const [loginErrorCode, setLoginErrorCode] = useState('');
	const [verifyingAccount, setVerifyingAccount] = useState(false);
	const [otpCount, setOtpCount] = useState(0);
	const verify = async () => {
		verifyAccount(values);
	};
	const [viewState, setViewState] = useState<ApiState>(ApiState.IDLE);
	const { values, errors, handleSubmission, handleChange } = useForm(
		formConfig,
		verify
	);
	const { actions } = useNotification();
	const { onLogin } = useLogin();

	const verifyAccount = async (values: any) => {
		const accountNumber = values.email
			? values.email.toLowerCase()
			: values.mobile;

		const payload = {
			account: accountNumber,
		};

		try {
			setVerifyingAccount(true);
			setViewState(ApiState.LOADING);
			const res = await authService.verifyPhone(payload);
			const data = res.data?.data;

			if (!!data) {
				if (!values.email && values.mobile) {
					dataLayerPushWithEvent('moe_event', {
						moe_event_name: 'Login Mobile Number Submitted',
						moe_event_data: { Status: 'Success' },
					});
				}
				const { otp_length, message } = data;
				setUserDetails((prev: any) => ({
					...prev,
					otp_length,
					message,
					mobile: accountNumber,
					email: values.email,
					otpCount,
				}));
				actions.notifyUser({
					message: 'OTP sent',
					severity: NotificationSeverity.SUCCESS,
				});
				setPageHandler(AUTH_STATES.VERIFY_OTP);
				setLoginError('');
				setLoginErrorCode('');
				setViewState(ApiState.SUCCESS);
				return;
			}
		} catch (err) {
			if (!values.email && values.mobile) {
				dataLayerPushWithEvent('moe_event', {
					moe_event_name: 'Login Mobile Number Submitted',
					moe_event_data: { Status: 'Failure' },
				});
			}
			const loginErr = (err as any).response?.data;
			actions.notifyUser({
				message: loginErr?.error?.message,
				severity: NotificationSeverity.ERROR,
			});
			setLoginError(loginErr?.error?.message);
			setLoginErrorCode(loginErr?.error?.code);
			setViewState(ApiState.FAILED);
			return err;
		}
		setVerifyingAccount(false);
		setViewState(ApiState.COMPLETED);
	};

	function onClose() {
		props.onClose();
		setPageHandler(AUTH_STATES.VERIFY_PHONE_NUMBER);
	}

	function onLoginSuccess() {
		onLogin && onLogin();
		onClose();
	}
	return (
		<AuthModal open={props.open} onClose={onClose}>
			<Box>
				{page === AUTH_STATES.VERIFY_PHONE_NUMBER && (
					<VerifyPhoneNumber
						values={values}
						errors={errors}
						handleChange={handleChange}
						handleSubmission={handleSubmission}
						loginError={loginError}
						loginErrorCode={loginErrorCode}
						userDetails={userDetails}
						verifyingAccount={verifyingAccount}
						setPage={setPageHandler}
						viewState={viewState}
					/>
				)}
				{page === AUTH_STATES.VERIFY_OTP && (
					<VerifyOtp
						userDetails={userDetails}
						resendOTP={verifyAccount}
						loginError={loginError}
						loginErrorCode={loginErrorCode}
						setUserDetails={setUserDetails}
						setPage={setPageHandler}
						onLogin={onLoginSuccess}
					/>
				)}
				{page === AUTH_STATES.USER_SIGN_UP && (
					<UserSignUp
						setUserDetails={setUserDetails}
						setPage={setPageHandler}
						userDetails={userDetails}
						onSignUp={onLoginSuccess}
					/>
				)}
				{page === AUTH_STATES.EMAIL_ALREADY_REGISTERED && (
					<EmailConfirmationPopUp
						setPage={setPageHandler}
						userDetails={userDetails}
						verifyEmailId={verifyAccount}
					/>
				)}
			</Box>
		</AuthModal>
	);
};

export default Auth;
