import {HaptikActions} from './actionTypes'

const initialState={
    isHaptikEnabled:false,
    showLogin:false
}

export default function HaptikReducer(state = initialState, action: any){
    switch(action.type){
        case HaptikActions.HAPTIK_ENABLED :return{
            ...state,
            isHaptikEnabled:action.payload
        }
        case HaptikActions.SHOW_LOGIN :return{
            ...state,
            showLogin:action.payload
        }
        default: return state
    }
}