import { TLocation } from '@/store/location/reducer';
import { setDataToLocalStorage, LocalStorageKeys, getDataFromLocalStorage } from '@/utils/storage';
import { dataLayerPush } from './dataLayer';
const cities = ENV.CITIES;
const pipedCityNames = Object.keys(cities).join('|');

export function getLocationFromLocalStorage(): TLocation {
  const selectedLocationJsonFromLocalStorage = getDataFromLocalStorage(
    LocalStorageKeys.SELECTED_LOCATION
  );

  let selectedLocationFromStorage: TLocation = {
    cityId: undefined,
    pincode: undefined,
    cityName: 'Bengaluru',
  };

  try {
    if (
      selectedLocationJsonFromLocalStorage &&
      typeof selectedLocationJsonFromLocalStorage === 'string'
    ) {
      const parsedJson = JSON.parse(selectedLocationJsonFromLocalStorage);

      const cityId = Number(parsedJson.cityId);
      const pincode = Number(parsedJson.pincode);
      const cityName = parsedJson.cityName;

      if (!isNaN(cityId) && !isNaN(pincode) && typeof cityName === 'string') {
        selectedLocationFromStorage.cityId = cityId;
        selectedLocationFromStorage.pincode = pincode;
        selectedLocationFromStorage.cityName = cityName;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return selectedLocationFromStorage;
}

export function saveLocationOnLocalStorage(location?: TLocation) {
  let locationJson = "";
  const defaultLocationJson = JSON.stringify({ cityId: 1, pincode: 560068, cityName: 'Bengaluru' });

  try {
    if (location?.pincode && location?.cityId && location?.cityName) {
      locationJson = JSON.stringify(location);
    } else {
      locationJson = defaultLocationJson;
    }
  } catch (error) {
    console.error(error);
    locationJson = defaultLocationJson;
  }

  dataLayerPush({ city_id: location?.cityId ?? 1 });

  setDataToLocalStorage(LocalStorageKeys.SELECTED_LOCATION, locationJson);
}


export function getCityFromUrl(){
  const regexResponse = window.location.pathname.match(
    `^/(?<city>${pipedCityNames}($|/$|/()))`
  );
  const pathCity = regexResponse?.groups?.['city'];
  return pathCity
}