import ContactUsMenuItem from '@/components/ProfilePageMenuItems/ContactUs';
import HelpCenterMenuItem, {
	MenuItemProps,
} from '@/components/ProfilePageMenuItems/HelpCenter';
import MyOrdersMenuItem from '@/components/ProfilePageMenuItems/MyOrders';
import MyRefunds from '@/components/ProfilePageMenuItems/MyRefunds';
import MySusbscriptionMenuItem from '@/components/ProfilePageMenuItems/MySubscriptions';
import WishlistsMenuItem from '@/components/ProfilePageMenuItems/Wishlists';
import { MenuItemNames } from '@/services/user/models/profile-menu.types';

// import Loyalty from '@/assets/profile/loyalty.svg';
// import ReferFriend from '@/assets/profile/refer-friend.svg';
// import MyAddress from '@/assets/profile/my-address.svg';
// import DeleteProfile from '@/assets/profile/delete-account.svg';
// import MyPayments from '@/assets/profile/my-payment.svg';
// import MySubscription from '@/assets/profile/my-subscription.svg';
// import ProfileArrow from '@/assets/profile/profile-arrow.svg';

export const MenuMap = new Map([
	[
		MenuItemNames.CONTACT_US,
		{
			Icon: (props: MenuItemProps) => <ContactUsMenuItem {...props} />,
			path: '/contact-us',
		},
	],
	[
		MenuItemNames.HELP_CENTER,
		{
			Icon: (props: MenuItemProps) => <HelpCenterMenuItem {...props} />,
			path: 'https://help.furlenco.com/hc/en-us',
		},
	],
	[
		MenuItemNames.WISHLIST,
		{
			Icon: (props: MenuItemProps) => <WishlistsMenuItem {...props} />,
			path: '/my/wishlist',
		},
	],
	[
		MenuItemNames.ORDERS,
		{
			Icon: (props: MenuItemProps) => <MyOrdersMenuItem {...props} />,
			path: '/my/orders',
		},
	],
	[
		MenuItemNames.REFUNDS,
		{
			Icon: (props: MenuItemProps) => <MyRefunds {...props} />,
			path: '/my/refunds',
		},
	],
	[
		MenuItemNames.SUBSCRIPTION,
		{
			Icon: (props: MenuItemProps) => <MySusbscriptionMenuItem {...props} />,
			path: '/my/subscriptions',
		},
	],
	// [MenuItemNames.LOYALTY, { Icon: <Loyalty /> }],
	// [MenuItemNames.REFERRALS, { Icon: <ReferFriend /> }],
	// [MenuItemNames.PAYMENTS, { Icon: <MyPayments /> }],
	// [
	// 	MenuItemNames.SUBSCRIPTION,
	// 	{ Icon: <MySubscription />, path: '/my/subscriptions' },
	// ],
	// [MenuItemNames.ADDRESSES, { Icon: <MyAddress /> }],
]);
