import Hoverable from '@/atomic-components/Hoverable';
import RefundsIcon from '@/assets/refund/RefundIcon.svg'
import HelpCenterHoveredIcon from '@/assets/profile/help-center-hovered.svg';
import { LencoTypography } from '@/atomic-components/LencoTypography';

export type MenuItemProps = {
	active: boolean;
};

const MyRefunds = (props: MenuItemProps) => {
	return (
		<Hoverable
			icons={{ before: <RefundsIcon />, after: <RefundsIcon /> }}
			sx={{ display: 'flex', flexDirection: 'row' }}
			active={props.active}
		>
			<LencoTypography ml='16px'>My Refunds</LencoTypography>
		</Hoverable>
	);
};

export default MyRefunds;
