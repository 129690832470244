import { TWishlistProduct } from '@/services/wishlist/models/wishlist';
import { keyBy } from 'lodash';
import { wishlistService } from '../../services/wishlist';
import { GET_WISHLISTS } from './actionTypes';


export const getWishlistsAction = (query?:any) => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_WISHLISTS.LOADING
    });
    const res = await wishlistService.getWishlists(query);
    const wishlistedProducts = res.data?.data?.products ?? [];

    const wishlistedProductsMap = keyBy(wishlistedProducts, (product: TWishlistProduct) => product.id);

    dispatch({
      type: GET_WISHLISTS.SUCCESS,
      payload: wishlistedProducts,
      wishlistedProductsMap,
    });
  } catch (error) {
    dispatch({
      type: GET_WISHLISTS.FAILED,
      payload: error
    });
  }
};

