import BaseService from '@/services/base-service';
import { getDataFromLocalStorage } from '@/utils/storage';
import { TCreateCartOrderResponse } from './models/create-cart-order';
import { TGetPaymentDataApiResponse } from './models/get-payment-data';
import { TProcessRZPPaymentPayload, TProcessRZPPaymentResponse } from './models/process-rzp-payment';
import { getLocationFromLocalStorage } from '@/utils/location';

class CheckoutService extends BaseService {
  constructor() {
    super({
      serviceName: "Checkout",
    });
  }

  getPaymentData(paymentId: number): Promise<TGetPaymentDataApiResponse> {
    return this.client.post('/payments/config', null, {
      params: {
        identifier: paymentId
      },
      headers: {
        'x-tenant-id': 1
      }
    });
  }

  processRazorpayPayment(payload: TProcessRZPPaymentPayload): Promise<TProcessRZPPaymentResponse> {
    return this.client.post('/payments/processRazorpayPayment', payload, {
      headers: {
        'x-tenant-id': 1
      }
    });
  }
  createCheckoutOrder(cartId: number): Promise<TCreateCartOrderResponse> {
    const location = getLocationFromLocalStorage();

    const { cityId, pincode } = location;
    return this.client.post(`/carts/${cartId}/create-checkout-order?cityId=${cityId}&pincode=${pincode}`, null, {
    });
  }
}

export const checkoutService = new CheckoutService();
