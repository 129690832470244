import { useSelector, useDispatch } from 'react-redux';
import {
	CART_TYPE,
} from '@/services/cart/models/cart';
import {
	
	removeItemFromCartFulfilled,
	addItemToCartFulfilled,
	getCartPending,
	getCartFulfilled,
	getCartRejected,
	updateCartItemQuantityFulfilled,
} from '@/store/unlmtd/slices/cartSlice';
import { TUpdateCartItemQuantityPayload } from '@/services/cart/models/update-cart-quantity';
import {useRef} from'react';
import { unlmtdCartService } from '@/services/unlmtd/cart';
import { useNotification } from '@/hooks/useNotification';
import { NotificationSeverity } from '@/components/NotifyToast';

export function useCart() {
	const dispatch = useDispatch();
	const {actions:{notifyUser}} = useNotification();
	const cartState = useSelector((state: any) => state.cart);
	const totalCartItems = cartState?.cart?.data?.totalCartItems || 0;
	const isFetching = useRef(false);
	function getCartApiState() {
		return cartState?.cart?.status;
	}
	const fetchCart = async (query?: any) => {
		if (isFetching.current) return;

		isFetching.current = true;
		dispatch(getCartPending());
		try {
			const response = await unlmtdCartService.getCarts(query);
			dispatch(getCartFulfilled(response.data));
			
		} catch (error: any) {
			dispatch(getCartRejected(error.toString()));
			
		}finally {
			isFetching.current = false;
		}
	};

	const addCartItem = async (payload: any) => {
		try {
			await unlmtdCartService.addItemToCart(payload);
			// dispatch(addItemToCartFulfilled(response.data));
			fetchCart();
			
			notifyUser({
				message: 'Product Successfully added to cart',
				severity:NotificationSeverity.SUCCESS,
			})
		} catch (error:any) {
			console.error('Failed to add item to cart:', error);
			notifyUser({
				message: error?.response?.data?.error?.message || 'Failed to add item to cart',
				severity:NotificationSeverity.ERROR,
			})
		}
	};

	const removeCartItem = async (payload: any) => {
		try {
			await unlmtdCartService.removeItemFromCart(payload);
			fetchCart();
			notifyUser({
				message: 'Product Successfully removed from cart',
				severity:NotificationSeverity.SUCCESS,
			})
		} catch (error:any) {
			console.error('Failed to remove item from cart:', error);
			notifyUser({
				message: error?.response?.data?.error?.message || 'Failed to remove item from cart',
				severity:NotificationSeverity.ERROR,
			})
		}
	};

	const updateQuantity = async (
		cartId: number,
		itemId: number,
		cartType: CART_TYPE,
		payload: TUpdateCartItemQuantityPayload
	) => {
		try {
			const response = await unlmtdCartService.updateCartItemQuantity(
				cartId,
				itemId,
				cartType,
				payload
			);
			dispatch(updateCartItemQuantityFulfilled(response.data));
		} catch (error) {
			console.error('Failed to update item quantity:', error);
		}
	};

	function clearCart() {
		dispatch(getCartFulfilled({}));
	}

	return {
		totalCartItems,
		getCartApiState,
		cartActions: {
			fetchCart,
			addCartItem,
			removeCartItem,
			updateQuantity,
			clearCart
		},
		cart: cartState,
	};
}
