import { SeoActions, SeoDataState } from "./types";

const initialState = {
    storeState: SeoDataState.LOADING,
    footerData: "",
    title: "",
    description: "",
    schemas: []
};

export default function SeoReducer(state = initialState, action: any) {
    switch (action.type) {
        case SeoActions.SEO_DATA_LOADING: return {
            ...state,
            storeState: SeoDataState.LOADING
        };
        case SeoActions.SEO_DATA_SUCCESS: return {
            ...state,
            storeState: SeoDataState.SUCCESS,
            ...action.payload
        };
        case SeoActions.SEO_DATA_ERRORED: return {
            ...state,
            storeState: SeoDataState.ERROR,
        };
        default: return state;
    }
}