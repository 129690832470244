import FlexBox from '@/atomic-components/FlexBox';
import { LencoImage } from '@/atomic-components/Image';
import { Box, Typography, useTheme } from '@mui/material';
import SelectedLocationDisplay from '@/components/unlmtd/SelectedLocationDisplay';
import { useRouter } from 'next/router';
import SearchBar from '@/components/unlmtd/SearchBar/SearchBar';
import CartIcon from '@/components/unlmtd/Icons/CartIcon';
import WishlistIcon from '@/components/unlmtd/Icons/WishlistIcon';
import { CatalogueService } from '@/services/catalogue';
import { useEffect, useState } from 'react';
import { Categories, Category } from '@/services/catalogue/models/categories';
import AccountPopover from './AccountPopover';
import { getColor } from '@/utils/theme/colors';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { useCart } from '@/hooks/unlmtd/useCart';
import UnlmtdIcon from '@/assets/unlmtdIcon.svg';
import UnlmtdIconHover from '@/assets/unlmtdIconHover.svg';
import UnlmtdFurlencoLogo from '@/assets/unlmtd/logo.svg';
import UnlmtdLogo from '@/assets/unlmtd/unlmtdLogo.svg';
import Link from 'next/link';
import { getStyle } from '@/utils/theme/fonts';
import Hoverable from '@/atomic-components/Hoverable';
import { useLogin } from '@/hooks/useLogin';
import { emitMoeEvent } from '@/utils/dataLayer';
import { useUser } from '@/hooks/useUser';
import UnlmtdBuyLogo from '@/assets/unlmtd/buyLogo.svg';
import UnlmtdRentLogo from '@/assets/unlmtd/rentLogo.svg';

type TGlobalHeaderProps = {};

const GlobalHeader = (props: TGlobalHeaderProps) => {
	const router = useRouter();
	const [buyCategories, setBuyCategories] = useState<Categories>();
	const [rentCategories, setRentCategories] = useState<Categories>();
	const [animateCartIcon, setAnimateCartIcon] = useState<boolean>(false);
	const { totalCartItems } = useCart();
	const { isLoggedIn, loginActions } = useLogin();
	const { ghostUser, user  ,profileMenu} = useUser();
	const theme = useTheme();

	function getCategoriesForVerticals() {
		CatalogueService.getCategories().then((res) => {
			const data = res.data?.data;

			if (data) {
				const { buy, rent } = data;

				buy && setBuyCategories(buy);
				rent && setRentCategories(rent);
			}
		});
	}

	const {cartActions} = useCart();

	const navigateToCart = () => {
		emitMoeEvent('Cart Clicked', {
			items_count: totalCartItems,
		});
		router.push('/unlmtd/cart');
	};

	function navigateToWishlist() {
		router.push('/unlmtd/my/wishlist');
	}
	const wishlistIconClicked = () => {
		if (isLoggedIn) {
			navigateToWishlist();
		} else {
			loginActions.showLoginFlow(navigateToWishlist);
		}
	};

	function navigateToHome() {
		router.push('/');
	}

	useEffect(() => {
		if (typeof totalCartItems == 'number') {
			setAnimateCartIcon(true);
			setTimeout(() => setAnimateCartIcon(false), 300);
		}
	}, [totalCartItems]);

	useEffect(() => {
		getCategoriesForVerticals();
		profileMenu;
	}, []);

	return (
		<FlexBox
			p='18px 40px'
			fullWidth
			sx={{
				zIndex: 10,
				position: 'fixed',
				width: '100%',
				left: '0px',
				top: '0px',
				boxShadow: '0 4px 12px 0 rgba(0,0,0,.05)',
				background: theme.colors?.lencoPrimaryP4,
			}}
			alignItems='center'
			justifyContent='center'
		>
			<FlexBox
				fullWidth
				id='header-content'
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<FlexBox fullWidth alignItems={'center'} justifyContent='flex-start'>
					<Box
						className='cursor-pointer'
						sx={{ height: '20px', width: '188px' }}
						mr='27px'
						onClick={navigateToHome}
					>
						<UnlmtdFurlencoLogo />
					</Box>
					<SelectedLocationDisplay />
					<Box ml='24px'>
						<Link href={'/buy'}>
							<UnlmtdBuyLogo />
						</Link>
					</Box>
					<Box ml='24px'>
						<Link href={'/rent'}>
							<UnlmtdRentLogo />
						</Link>
					</Box>
					<Box sx={{ ml: '24px' }}>
						<Link href='/unlmtd'>
							<UnlmtdLogo />
						</Link>
					</Box>
				</FlexBox>
				<FlexBox fullWidth justifyContent={'flex-end'} alignItems='center'>
					<Box mr='32px'>
						<SearchBar />
					</Box>
					{/* <LoginTriggerButton isLoggedIn={isLoggedIn} /> */}
					<Box mr='18px'>{(ghostUser || user) && <AccountPopover />}</Box>
					<Box
						mr='18px'
						sx={{ display: 'flex', alignItems: 'center' }}
						onClick={wishlistIconClicked}
					>
						<WishlistIcon />
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							position: 'relative',
						}}
						onClick={navigateToCart}
					>
						<CartIcon id='header-cart-icon'>
							{(isLoggedIn && totalCartItems > 0 )&& (
								<Box
									sx={{
										position: 'absolute',
										top: '-6px',
										right: '-4px',
										background: theme?.colors?.lencoSecondaryP0,
										borderRadius: '50%',
										padding:"2px 4px",
										color: 'white',
									}}
								>
									<LencoTypography variant='Tiny_Regular' textAlign={'center'}>
										{totalCartItems}
									</LencoTypography>
								</Box>
							)}
						</CartIcon>
					</Box>
				</FlexBox>
			</FlexBox>
		</FlexBox>
	);
};

export default GlobalHeader;
