import { styled } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import FlexBox from './FlexBox';

export const BubbleLoader = styled(FlexBox)`
	flex-direction: column;
	.lds-ripple {
		display: inline-block;
		position: relative;
		width: 70px;
		height: 70px;
	}
	.lds-ripple div {
		position: absolute;
		border: 4px solid white;
		opacity: 1;
		border-radius: 50%;
		animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}
	.lds-ripple div:nth-child(2) {
		animation-delay: -0.5s;
	}
	@keyframes lds-ripple {
		0% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 0;
		}
		4.9% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 0;
		}
		5% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 1;
		}
		100% {
			top: 0px;
			left: 0px;
			width: 72px;
			height: 72px;
			opacity: 0;
		}
	}
`;

type LoaderProps = {
	sx?: SxProps;
	isErrored?: boolean;
};

function Loader(props?: LoaderProps) {
	return (
		<FlexBox
			alignItems='center'
			justifyContent='center'
			width='inherit'
			sx={{ flexDirection: 'column' }}
			{...props}
		>
			<BubbleLoader>
				<Box className='lds-ripple'>
					<Box />
					<Box />
				</Box>
			</BubbleLoader>
		</FlexBox>
	);
}

export default Loader;
