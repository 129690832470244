import { AxiosError, AxiosResponse } from 'axios';

export type TSVGProps = {
  height?: number;
  width?: number;
  fill?: string;
  strokeColor?: string;
};

type CiagoErrorResponse = {
  name: string;
  message: string;
  resolution_message: string;
  code: string;
  // TODO: fix from api and remove from here
  error?: {
    name: string;
    message: string;
    resolution_message: string;
    code: string;
  };
};

export type BaseApiResponse<T> = AxiosResponse<{
  success: boolean;
  data: T;
  error: CiagoErrorResponse;
}>;

export type BaseAxiosApiError = AxiosError<CiagoErrorResponse>;

export interface IStoreAction {
  type: string;
  payload: any;
}

export enum ApiState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
  
}

export enum ViewStates {
  WAITING = 'WAITING',
  VISIBLE = 'VISIBLE',
  NOT_VISIBLE = 'NOT_VISIBLE',
}

export type Faq = {
  answer: string;
  question: string;
};

export type TCallbacks<TOnSuccess = any, TOnFailed = any, TFinally = any> = {
  onSuccess?: (data?: TOnSuccess) => void;
  onFailed?: (error?: TOnFailed) => void;
  finally?: (data?: TFinally) => void;
};