import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiState } from '@/models/generics';
import { TLocation } from '@/store/location/reducer';
import { SET_SELECTED_LOCATION, LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';

export interface ILocationStoreState {
  selectedLocationSyncState: ApiState;
  selectedLocation: TLocation;
  isLocationSelectorOpen: boolean;
}

const initialState: ILocationStoreState = {
  selectedLocationSyncState: ApiState.LOADING,
  selectedLocation: {
    pincode: undefined,
    cityId: undefined,
    cityName: "",
  },
  isLocationSelectorOpen: false
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_SAVING, (state) => {
        state.selectedLocationSyncState = ApiState.LOADING;
      })
      .addCase(SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_SUCCESS, (state, action:any) => {
        state.selectedLocation = action.selectedLocation;
        state.selectedLocationSyncState = ApiState.COMPLETED;
      })
      .addCase(SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_FAILED, (state) => {
        state.selectedLocationSyncState = ApiState.FAILED;
      })
      .addCase(LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_OPEN, (state) => {
        state.isLocationSelectorOpen = true;
      })
      .addCase(LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_CLOSE, (state) => {
        state.isLocationSelectorOpen = false;
      });
  }
});

// Export the reducer
export default locationSlice.reducer;
