import FlexBox from '@/atomic-components/FlexBox';
import { LencoImage } from '@/atomic-components/Image';
// import { Category } from '/services/catalogue/models/categories';

import { Category as CategoryProps } from '@/services/catalogue/models/categories'
import { ListingUrlHelper } from '@/utils/seo-url-helper';
import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import { Box, Grid } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import Link from 'next/link';

const Category = ({ category }: { category: CategoryProps }) => (
	<FlexBox
		direction='column'
		alignItems='center'
		justifyContent='center'
		mr='24px'
		mb='14px'
		width='96px'
		className='cursor-pointer'
	>
		<img width='auto' height='76px' src={category?.image?.url} />
		<LencoTypography
			sx={{
				...getStyle('Small_Regular'),
				color: getColor('Gray -1'),
			}}
		>
			{category.label}
		</LencoTypography>
	</FlexBox>
);

type CategoryPopoverProps = {
	title: string;
	description: string;
	categories: CategoryProps[];
};

const CategoryPopover = (props: CategoryPopoverProps) => {
	return (
		<Box
			sx={{
				width: '810px',
				p: '40px',
				background: '#FFFFFF',
				// boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.05)',
				border: '1px solid #047992',
				boxShadow: '0px 8px 16px rgba(64, 182, 194, 0.3)',
				borderRadius: '8px',
			}}
		>
			<Grid container justifyContent={'space-between'}>
				<Grid xs={3}>
					<FlexBox>
						<Box>
							<LencoTypography
								sx={{
									...getStyle('H3_Regular'),
									color: getColor('Gray 0'),
									lineHeight: 1,
								}}
							>
								{props.title}
							</LencoTypography>
							<LencoTypography
								mt='8px'
								variant='H14_Regular'
								sx={{ lineHeight: 1.25, color: getColor('Gray -1') }}
							>
								{props.description}
							</LencoTypography>
							<LencoTypography
								mt='8px'
								sx={{ lineHeight: 1, color: getColor('Gray -3') }}
							>
								Choose from over 2000 Products
							</LencoTypography>
						</Box>
					</FlexBox>
				</Grid>
				<Grid xs={8}>
					<FlexBox fullWidth sx={{ flexWrap: 'wrap' }}>
						{props.categories?.map((category) => (
							<Link
								href={`${ListingUrlHelper({
									collectionType: category.collectionType,
									name: category.name,
								})}`}
								onClick={(e) => e.stopPropagation()}
								key={category.name}
							>
								<Category category={category} />
							</Link>
						))}
					</FlexBox>
				</Grid>
			</Grid>
		</Box>
	);
};

export default CategoryPopover;
