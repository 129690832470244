import BaseService from '@/services/base-service';
import { getDataFromLocalStorage } from '@/utils/storage';
import { Suggestions } from './model';
import { SearchResults } from './models/search_model';

class SearchService extends BaseService {
	constructor() {
		super({
			serviceName: 'search-service',
		});
	}

	public getSuggestions(query: string): Promise<Suggestions> {
		return this.client.get(`/catalogue/search/lookahead?q=${query}`);
	}
	public getSearchResults(params: string): Promise<any> {
		return this.client.get(`/catalogue/products?${params}`);
	}
}

export const Search_Service = new SearchService();
