import { useLencoDispatch } from '@/hooks/useLencoDispatch';
import { useLencoSelector } from '@/hooks/useLencoSelector';
import { ICartItem } from '@/services/cart/models/cart';
import { cartService } from '@/services/cart';
import { CART_TYPE } from '@/services/cart/models/cart';
import {
	TApplyCouponCodeApiPayload,
	TApplyVasPayload,
	TUpdateCartApiResponse,
} from '@/services/cart/models/update-cart';
import { TUpdateCartItemQuantityPayload } from '@/services/cart/models/update-cart-quantity';
import { getUserCartAction } from '@/store/cart/actions';
import {
	ADD_ITEM_TO_CART,
	APPLY_COUPON,
	APPLY_VAS,
	REMOVE_COUPON,
	REMOVE_ITEM_FROM_CART,
	UPDATE_CART_ITEM_QUANTITY,
	UPDATE_CART_ITEM_TENURE,
} from '@/store/cart/actionTypes';
import { useEffect, useState } from 'react';
import {
	TUseCart,
	CART_STATUS,
	TUpdateCartItemPayload,
	TUpdateTenurePayload,
	CartAttributes,
} from './types';

function getIdListAndLopFromCartItems(cartItems: ICartItem[]): {
	ids: number[];
	lops: string[];
} {
	const cartItemsLops: Record<string, boolean> = {};

	const productIds = cartItems?.map((item) => {
		cartItemsLops[item.lineOfProduct] = true;
		return item.id;
	});

	const lopsArray = Object.keys(cartItemsLops) ?? [];

	return {
		ids: productIds,
		lops: lopsArray,
	};
}

export function useCart(): TUseCart {
	const {
		getCartApiState,
		cart,
		rentCart,
		buyCart,
		rentalCartItemsCount,
		buyCartItemsCount,
		canCheckout,
		cartErrorDisplayMessage,
		buyCartItems = [],
		rentalCartItems = [],
	} = useLencoSelector((state) => ({
		getCartApiState: state.cart?.getCartApiState,
		cart: state.cart?.cart,
		rentCart: state.cart?.cart?.rentCart,
		buyCart: state.cart?.cart?.buyCart,
		rentalCartItemsCount: state.cart?.cart?.rentCart?.rentalCartItemsCount ?? 0,
		buyCartItemsCount: state.cart?.cart?.buyCart?.buyCartItemsCount ?? 0,
		buyCartItems: state.cart?.cart?.buyCart?.buyCartItems,
		rentalCartItems: state.cart?.cart?.rentCart?.rentalCartItems,
		canCheckout: state.cart?.cart?.canCheckout as boolean,
		cartErrorDisplayMessage: state.cart?.displayErrorMessage,
	}));

	const dispatch = useLencoDispatch();

	const totalCartItems = buyCartItemsCount + rentalCartItemsCount;

	async function getCart(query?: any) {
		dispatch(getUserCartAction(query));
	}

	function addItemToCart(
		payload: TUpdateCartItemPayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.addItemToCart(cart?.id as number, payload)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: ADD_ITEM_TO_CART.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			}).catch((err) => {
				console.log(err);
				return err;
			});
	}

	function updateTenure(
		itemId: number,
		payload: TUpdateTenurePayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.updateTenure(cart?.id as number, itemId, payload)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: UPDATE_CART_ITEM_TENURE.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function removeItemFromCart(
		itemId: number,
		cartType: CART_TYPE
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.removeItemFromCart(cart?.id as number, itemId as number, cartType)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: REMOVE_ITEM_FROM_CART.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function updateCartItemQuantity(
		itemId: number,
		cartType: CART_TYPE,
		payload: TUpdateCartItemQuantityPayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.updateCartItemQuantity(
				cart?.id as number,
				itemId as number,
				cartType,
				payload
			)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: UPDATE_CART_ITEM_QUANTITY.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function applyCouponCode(
		cartType: CART_TYPE,
		payload: TApplyCouponCodeApiPayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.applyCouponCode(cart?.id as number, cartType, payload)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: APPLY_COUPON.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function removeCoupon(cartType: CART_TYPE): Promise<TUpdateCartApiResponse> {
		return cartService
			.removeCouponCode(cart?.id as number, cartType)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: REMOVE_COUPON.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function applyVas(
		cartType: CART_TYPE,
		payload: TApplyVasPayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.applyVas(cart?.id as number, cartType, payload)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: APPLY_VAS.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function getCartAttributes(): CartAttributes {
		let buyProductIds: number[] = [];
		let rentalProductIds: number[] = [];
		let lineOfProducts: string[] = [];
		let verticals: string[] = [];
		let buyProductIdsString = '';
		let rentalProductIdsString = '';
		let lineOfProductsString = '';
		let cartStatus: CART_STATUS = CART_STATUS.EMPTY_CART;
		let verticalsString = '';

		if (rentalCartItemsCount > 0) {
			const { ids, lops: rentalLops } = getIdListAndLopFromCartItems(
				rentCart?.rentalCartItems as ICartItem[]
			);
			rentalProductIds = ids;
			rentalProductIdsString = ids.join(',');
			lineOfProducts = [...rentalLops];
			verticals.push('FURLENCO_RENTAL');
			cartStatus = CART_STATUS.PRODUCTS_IN_CART;
		}
		if (buyCartItemsCount > 0) {
			const { ids, lops: buyLops } = getIdListAndLopFromCartItems(
				buyCart?.buyCartItems as ICartItem[]
			);
			buyProductIds = ids;
			buyProductIdsString = ids.join(',');
			lineOfProducts = [...lineOfProducts, ...buyLops];
			verticals.push('FURLENCO_SALE');
			cartStatus = CART_STATUS.PRODUCTS_IN_CART;
		}

		verticalsString = verticals.join(',');
		lineOfProductsString = lineOfProducts.join(',');

		return {
			verticals,
			lineOfProducts,
			lineOfProductsString,
			buyProductIds,
			buyProductIdsString,
			rentalProductIds,
			rentalProductIdsString,
			cartStatus,
			verticalsString: verticals.join(','),
		};
	}

	return {
		id: cart?.id,
		getCartApiState,
		cart,
		rentCart,
		buyCart,
		rentalCartItemsCount: rentalCartItemsCount,
		buyCartItemsCount: buyCartItemsCount,
		totalCartItems,
		canCheckout,
		cartErrorDisplayMessage,
		buyCartItems,
		rentalCartItems,
		getCartAttributes,
		cartActions: {
			getCart,
			updateTenure,
			addItemToCart,
			removeItemFromCart,
			updateCartItemQuantity,
			applyCouponCode,
			applyVas,
			removeCoupon,
		},
	};
}
