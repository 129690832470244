/* eslint-disable @next/next/no-img-element */
import Box from '@mui/system/Box';
import React, {
	CSSProperties,
	MouseEventHandler,
	useEffect,
	useRef,
	useState,
} from 'react';
import { getTopPaddingFromAspectRatio } from './utils/aspectRatioHelper';
import { isValidForLazy, sourceGenerator } from './utils/imageUrlHelper';
import {
	getInterSectionObserver,
	IntersectionObserverConfigOptions,
} from './utils/intersectionObserverHelper';
import { Interpolation, SxProps } from '@mui/material';
import { Theme } from '@mui/system';

interface ILencoImageProps {
	aspectRatio?: number;
	url: string;
	sources?: [];
	sizes?: string;
	className?: string;
	lazy?: boolean;
	alt?: string;
	overrideOptions?: any;
	reblur?: boolean;
	loading?: boolean;
	intersectionObserverConfig?: IntersectionObserverConfigOptions;
	imageCss?: CSSProperties;
	containerSx?: SxProps<Theme>;
	onMouseEnter?: MouseEventHandler<HTMLImageElement>;
	onMouseMove?: MouseEventHandler<HTMLImageElement>;
	onMouseLeave?: MouseEventHandler<HTMLImageElement>;
	width?: string;
	height?: string;
}

export const LencoImage = (props: ILencoImageProps) => {
	let {
		aspectRatio,
		url,
		sizes = '',
		lazy = true,
		alt = '',
		overrideOptions = {},
		imageCss = {},
		containerSx = {},
		width = '100%',
		height = 'auto',
	} = props;

	const imgRef: any = React.createRef();
	const [{ src, srcset }, setSources] = useState<{
		src: string | undefined;
		srcset: string | undefined;
	}>({ src: '', srcset: '' });

	const [imageIntersectionObserver, setImageIntersectionObserver] =
		useState<IntersectionObserver>();

	const paddingTop: any = {
		paddingTop: aspectRatio ? getTopPaddingFromAspectRatio(aspectRatio) : '0px',
	};

	const containerRef = useRef();

	useEffect(() => {
		if (imgRef.current && imageIntersectionObserver) {
			lazy && imgRef.current.classList.add('lazy');
			imageIntersectionObserver.observe(imgRef.current);
			const containerBox = containerRef.current;
			const clientWidth = containerBox && (containerBox as any)?.clientWidth;
			// eslint-disable-next-line react-hooks/exhaustive-deps
			overrideOptions = {
				...overrideOptions,
				...(clientWidth
					? {
							width: Math.ceil(clientWidth / 50) * 50,
							crop: 'scale',
					  }
					: {}),
			};
		}
	}, [imgRef]);
	useEffect(() => {
		if (url && isValidForLazy(url)) {
			setImageIntersectionObserver(getInterSectionObserver());
		}
		const { src, srcset } = sourceGenerator(url, overrideOptions);
		setSources({ src, srcset });
	}, []);
	return (
		<Box
			ref={containerRef}
			sx={{
				position: 'relative',
				img: {
					position: 'absolute',
					top: '0px',
					height,
					'&.lazy': {
						'&:not(.loaded)': {
							filter: 'blur(5px)',
						},
						'&.loaded': {
							filter: 'blur(0)',
						},
					},
				},
				...containerSx,
			}}
			className={`${props.className ? props.className : ''}`}
			style={paddingTop}
		>
			{imageIntersectionObserver ? (
				<img
					onMouseEnter={props.onMouseEnter}
					onMouseMove={props.onMouseMove}
					src={src}
					alt={alt}
					style={{
						height,
						width,
						visibility: src || srcset ? 'visible' : 'hidden',
						...imageCss,
					}}
					data-srcset={srcset}
					sizes={sizes}
					ref={imgRef}
				/>
			) : null}
		</Box>
	);
};
