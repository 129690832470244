import Hoverable from '@/atomic-components/Hoverable';
import MySubscriptionsIcon from '@/assets/profile/my-subscription.svg';
import MySubscriptionsHoveredIcon from '@/assets/profile/my-subscription-hovered.svg';
import { MenuItemProps } from './HelpCenter';
import { LencoTypography } from '@/atomic-components/LencoTypography';

const MySusbscriptionMenuItem = (props: MenuItemProps) => {
	return (
		<Hoverable
			icons={{
				before: <MySubscriptionsIcon />,
				after: <MySubscriptionsHoveredIcon />,
			}}
			sx={{ display: 'flex', flexDirection: 'row' }}
			active={props.active}
		>
			<LencoTypography ml='16px'>My Subscriptions</LencoTypography>
		</Hoverable>
	);
};

export default MySusbscriptionMenuItem;
