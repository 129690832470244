import BaseService from '@/services/base-service';
import { TAddAddressesApiResponse, TGetAddressesApiResponse } from './models/addresses';
import { MetaApiResponse } from './models/meta.types';
import { ProfileMenuApiResponse } from './models/profile-menu.types';
import { UpdateWhatsappConsentStatusApiResponse } from './models/UpdateWhatsappConsentStatus';

class UserService extends BaseService {
  constructor() {
    super({
      serviceName: 'User'
    });
  }

  getUser(): Promise<MetaApiResponse> {
    return this.client.get('/users/meta');
  }

  getUserProfileMenu(): Promise<ProfileMenuApiResponse> {
    return this.client.get('/users/menu');
  }

  getAddress(): Promise<TGetAddressesApiResponse> {
    return this.client.get('/users/addresses');
  }
  addAddress(data: any): Promise<TAddAddressesApiResponse> {
    return this.client.post('/users/addresses', data);
  }
  updateUserWhatsAppConsentStatus(payload: {
    user_consent: { opt_in: boolean; };
  }): Promise<UpdateWhatsappConsentStatusApiResponse> {
    return this.client.post('/users/whatsapp-consent', payload);
  }
}

export const userService = new UserService();
