import FlexBox from '@/atomic-components/FlexBox';
import LencoButton from '@/atomic-components/LencoButton';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { TCity } from '@/models/location';
import { Box, Divider } from '@mui/material';
import City from './City';

type TopCitiesProps = {
	topCities?: TCity[];
	onCityClicked: (city: TCity) => void;
	displayOtherCities: () => void;
	showOtherCitiesButton: boolean;
};

const TopCities = (props: TopCitiesProps) => {
	return (
		<Box>
			<FlexBox
				mt={'28px'}
				justifyContent='space-between'
				alignItems={'center'}
				fullWidth
			>
				<Divider />
				<LencoTypography variant='H11A_Medium'>
					Or select your city
				</LencoTypography>
				<Divider />
			</FlexBox>
			<FlexBox
				direction='row'
				justifyContent='space-evenly'
				alignItems='center'
				wrap={'wrap'}
				sx={{ overflow: 'auto' }}
			>
				{props?.topCities?.map((city) => (
					<City key={city.name} city={city} onClick={props.onCityClicked} />
				))}
			</FlexBox>
			{props.showOtherCitiesButton && (
				<LencoButton
					variant='text'
					sx={{
						marginTop: '16px',
						
						textTransform: 'capitalize',
						textDecoration: 'underline',
						':hover': {
							textDecoration: 'underline',
						},
					}}
					onClick={props.displayOtherCities}
				>
					Other Cities
				</LencoButton>
			)}
		</Box>
	);
};

export default TopCities;
