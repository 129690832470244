export enum AUTH_FLOW_TRIGGER {
    OPEN_AUTH_FLOW = 'OPEN_AUTH_FLOW',
    CLOSE_AUTH_FLOW = 'CLOSE_AUTH_FLOW',
}

export enum NOTIFCATION_TOAST {
    ADD_TOAST = "ADD_NOTIFCATION_TOAST",
    REMOVE_TOAST = "REMOVE_NOTIFCATION_TOAST"
}

export enum APP_HISTORY {
    ADD_TO_APP_HISTORY = "ADD_TO_APP_HISTORY"
}

export enum UPDATE_TOKEN_VALIDITY {
    TOKEN_INVALID = 'TOKEN_INVALID'
}