import '../styles/globals.css';
import '../styles/global-fonts-dev.css';
import '../styles/Home.module.css';
import type { AppProps } from 'next/app';
import store from '@/store';
import { Provider } from 'react-redux';
import { withRouter } from 'next/router';
import Root from '@/components/Root';
import UnlmtdRoot from '@/components/unlmtd/Root'
import LencoHead from '@/components/LencoHead';
import { useEffect, useState } from 'react';
import unlmtdStore from '@/store/unlmtd';

function MyApp(props: AppProps) {
	const [theme, setTheme] = useState<string>();
	useEffect(() => {
		if (props?.router?.pathname?.includes('unlmtd')) {
			setTheme('unlmtd');
		} else {
			setTheme('sf');
		}
	}, [props?.router?.pathname]);
	return (
		<>
			{theme == 'unlmtd' ? (
				<Provider store={unlmtdStore}>
					<LencoHead />
					<UnlmtdRoot {...props} />
				</Provider>
			) : (
				<Provider store={store}>
					<LencoHead />
					<Root {...props} />
				</Provider>
			)}
		</>
	);
}

export default withRouter(MyApp);
