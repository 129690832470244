import { Typography, TypographyProps, TypographyTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

type LencoTypographyExtraProps = {
	componentUsage?: 'PARAGRAPH' | 'STANDALONE';
};

export const LencoTypography: OverridableComponent<
	TypographyTypeMap<LencoTypographyExtraProps>
> = (props: TypographyProps & LencoTypographyExtraProps) => {
	let updatedSx;
	if(!(props.sx || {}).hasOwnProperty('lineHeight')){
		if(props.componentUsage === 'PARAGRAPH'){
			updatedSx = {...props.sx, lineHeight: 1.6}
		} else {
			updatedSx = {...props.sx, lineHeight: 1.3}
		}
	} else {
		updatedSx = props.sx;
	}

	const {sx, ...otherProps} = props
	return <Typography sx={updatedSx} {...otherProps} />;
};
