import { HaptikActions } from "./actionTypes";

export const setHaptik = (payload?: any) => async (dispatch: any) => {
        dispatch({
            type: HaptikActions.HAPTIK_ENABLED,
            payload:payload ,
        });
};

export const setshowLogin = (payload?: any) => async (dispatch: any) => {
    dispatch({
        type: HaptikActions.SHOW_LOGIN,
        payload:payload ,
    });
};