import { createTheme } from "@mui/material";
import { getColor } from "../colors";
import { getStyle } from "../fonts";
import { UnlmtdColors } from "./colors";
import { typoGraphyConfig, variantMapping } from '../fonts';
import { muiButtonOverrides } from "../button";
import { getRootStyles } from "./button";


export const unlmtdTheme = createTheme({
    name:'unlmtd',
     colors:{
      ...UnlmtdColors.primary,
      ...UnlmtdColors.secondary
    },
    typography: {
      fontFamily: ['Work Sans', 'Recline'].join(','),
      fontSize: 12,
      ...typoGraphyConfig,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: getRootStyles,
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping,
        },
      },
    },
  });
  