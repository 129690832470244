import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { Box } from '@mui/material';
import CategoryPopover from './CategoryPopover';
import { Category } from '@/services/catalogue/models/categories';
import RentIcon from '@/components/Icons/RentIcon';
import { useRouter } from 'next/router';
import { useLocation } from '@/hooks/useLocation';

type RentPopoverProps = {
	categories?: Category[];
};

const RentPopover = (props: RentPopoverProps) => {
	const router = useRouter();
	const { selectedLocation } = useLocation();

	function navigateToRent() {
		router.push(`/${selectedLocation?.cityName?.toLocaleLowerCase()}`);
	}

	return (
		<Box className='popover_wrapper' onClick={navigateToRent}>
			<Box id='mouse-over-popover' className='popover_content'>
				<CategoryPopover
					title='Rent Furniture'
					description='Select from rental plans starting at 1 month to 12 months'
					categories={props.categories as Category[]}
				/>
			</Box>
			<FlexBox
				alignItems='center'
				className='popover_title'
				sx={{
					'&:hover': {
						color: getColor('Minty 0'),
					},
				}}
			>
				<Box>
					<RentIcon />
				</Box>
			</FlexBox>
		</Box>
	);
};

export default RentPopover;
