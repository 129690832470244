import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import {
	TextField,
	InputAdornment,
	IconButton,
	Box,
	useTheme,
} from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import RightArrowIcon from '@/assets/unlmtd/RightArrowIcon.svg';
import { getStyle } from '@/utils/theme/fonts';

type TPincodeInputProps = {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checkPincode: () => void;
	currentlySelectedPincode?: number;
	pincodeError?: string;
};

const PincodeInput = (props: TPincodeInputProps) => {
	function onFormSubmit(e: any) {
		e.preventDefault();
		props.checkPincode();
	}

	const theme = useTheme();

	function onKeyUp(e: any) {
		if (e.keyCode === 13) {
			props.checkPincode();
		}
	}

	return (
		<FlexBox direction='column' fullWidth alignItems={'center'}>
			<form onSubmit={onFormSubmit} style={{ width: '100%' }}>
				<TextField
					value={props.value}
					// autoFocus={true}
					variant='standard'
					autoComplete='off'
					type='mobile'
					inputProps={{
						inputMode: 'numeric',
						pattern: '[0-9]*',
						minLength: 5,
						maxLength: 6,
						disableUnderline: true,
					}}
					onChange={props.onChange}
					placeholder='Enter your pincode'
					error={!!props.pincodeError}
					helperText={props.pincodeError}
					onKeyUp={onKeyUp}
					InputProps={{
						type: 'tel',
						disableUnderline: true,
						endAdornment: (
							<InputAdornment
								position='end'
							>
								<IconButton
									onClick={props.checkPincode}
									sx={{ height: '36px', width: '36px', paddingLeft:'0px' }}
								>
									<Box>
										<RightArrowIcon />
									</Box>
								</IconButton>
							</InputAdornment>
						),
					}}
					sx={{
						marginTop: '28px',
						width: '100%', 
						
						borderRadius: '30px',
						// background: theme?.colors?.lencoPrimaryP2,
						'& .MuiInputBase-root': {
							borderRadius: '30px',
							border: !!props.pincodeError
							? `1px solid ${getColor('Danger +1')}`
							: `1px solid ${getColor('Gray -4')}`,
							
						},
						'& .MuiInputBase-input': {
							padding: '20px 20px 20px 32px',
							width:'72%',
							color: theme?.colors?.lencoPrimaryN6,
						},
						...getStyle('Small-Medium'),
						input: {
							// background: theme?.colors?.lencoPrimaryP2,
							'&::placeholder': {
								...getStyle('Small-Medium'),
								color: theme?.colors?.lencoPrimaryN6,
								opacity: '1',
							},
							color: theme?.colors?.lencoPrimaryN6,
							...getStyle('Small-Medium'),
						},
					
					}}
				/>
			</form>
			{props.currentlySelectedPincode && (
				<FlexBox mt={'18px'}>
					<LencoTypography
						mr='4px'
						variant='Small_Medium'
						color={getColor('Gray -4')}
					>
						Currently selected pincode :{' '}
					</LencoTypography>
					<LencoTypography
						variant='Small_SemiBold'
						sx={{ color: theme?.colors?.lencoPrimaryN3 }}
					>
						{props.currentlySelectedPincode}
					</LencoTypography>
				</FlexBox>
			)}
		</FlexBox>
	);
};

export default PincodeInput;
