export const CART_LOADING = 'CART_LOADING';
export const CART_LOADING_SUCCESS = 'CART_LOADING_SUCCESS';
export const CART_LOADING_ERRORED = 'CART_LOADING_ERRORED';

export enum GET_CART {
    LOADING = 'CART_LOADING',
    SUCCESS = 'CART_LOADING_SUCCESS',
    FAILED = 'CART_LOADING_FAILED',
}

export enum UPDATE_CART {
    SUCCESS = 'UPDATE_CART_SUCCESS',
}

export enum ADD_ITEM_TO_CART {
    LOADING = 'ADD_ITEM_TO_CART',
    SUCCESS = 'ADD_ITEM_TO_CART_SUCCESS',
    FAILED = 'ADD_ITEM_TO_CART_FAILED',
}

export enum UPDATE_CART_ITEM_QUANTITY {
    LOADING = 'UPDATE_CART_ITEM_QUANTITY',
    SUCCESS = 'UPDATE_CART_ITEM_QUANTITY_SUCCESS',
    FAILED = 'UPDATE_CART_ITEM_QUANTITY_FAILED',
}

export enum REMOVE_ITEM_FROM_CART {
    LOADING = 'REMOVE_ITEM_FROM_CART',
    SUCCESS = 'REMOVE_ITEM_FROM_CART_SUCCESS',
    FAILED = 'REMOVE_ITEM_FROM_CART_FAILED',
}

export enum APPLY_COUPON {
    LOADING = 'APPLY_COUPON',
    SUCCESS = 'APPLY_COUPON_SUCCESS',
    FAILED = 'APPLY_COUPON_FAILED',
}

export enum UPDATE_CART_ITEM_TENURE {
    LOADING = 'UPDATE_CART_ITEM_TENURE',
    SUCCESS = 'UPDATE_CART_ITEM_TENURE_SUCCESS',
    FAILED = 'UPDATE_CART_ITEM_TENURE_FAILED',
}

export enum REMOVE_COUPON {
    SUCCESS = 'REMOVE_COUPON_SUCCESS',
}

export enum APPLY_VAS {
    LOADING = 'APPLY_VAS',
    SUCCESS = 'APPLY_VAS_SUCCESS',
    FAILED = 'APPLY_VAS_FAILED',
}

export enum CREATE_CART_CHECKOUT_ORDER {
    LOADING = 'CREATE_CART_CHECKOUT_ORDER',
    SUCCESS = 'CREATE_CART_CHECKOUT_ORDER_SUCCESS',
    FAILED = 'CREATE_CART_CHECKOUT_ORDER_FAILED',
}
