import BaseService from '@/services/base-service';
import { GetProductApiResponse } from './models/pdp';
import { GetProductConfigurationApiResponse } from './models/productConfig';
import { GetCategoriesApiResponse } from './models/categories';
import { GetProductAddonsApiResponse, GetProductAddonsApiResponseData } from './models/product-addons-recommendations';
import { IProductData } from '@/components/search-results/model';
// import { MetaApiResponse } from './models/meta';

class ProductListing extends BaseService {
  constructor() {
    super({
      serviceName: 'listing',
    });
  }

  public getProductListings(query: any): Promise<any> {
    return this.client.get(`/catalogue/products?${query}`);
  }

  public getListingMeta(category: string): Promise<any> {
    return this.client.get(`/catalogue/collection/${category}/meta`);
  }

  public getProduct(productName: string): Promise<GetProductApiResponse> {
    return this.client.get(`/catalogue/products/${productName}`);
  }

  public getRelatedProducts(permalink: string, query: any): Promise<IProductData[]> {

    return this.client.get(`/catalogue/products/${permalink}/recommendations`, {
      params: query
    }).then(res => res.data?.data?.products ?? []);
  }

  public getProductAddons(query: { filters: { collection_id: number[]; }; }): Promise<GetProductAddonsApiResponseData> {
    let queryString = '?';
    query.filters.collection_id.forEach((id) => {
      queryString += `filters[collection_id]=${id}&`;
    });

    return this.client.get(`/catalogue/products/addons/recommendations${queryString}`).then(res => res.data?.data);
  }

  public getProductConfig(productId: number, queryString: string): Promise<GetProductConfigurationApiResponse> {
    return this.client.get(`/catalogue/variant-group/${productId}/configurations?${queryString}`);
  }

  getCategories(): Promise<GetCategoriesApiResponse> {
    return this.client.get('/catalogue/categories');
  }

  getBulkRelatedProduct(payload: { permalinks: string[]; }): Promise<Record<string, IProductData[]>> {
    return this.client.post(`/catalogue/products/recommendations`, payload).then(res => res.data.data);
  }
}

/** @deprecated */
export const ListingService = new ProductListing();
export const CatalogueService = ListingService;
