import BaseService from '@/services/base-service';
import { TGetSegmentResponse } from './models/get-segment';

class SegmentService extends BaseService {
  constructor() {
    super({
      serviceName: 'Segment'
    });
  }

  getSegment(): Promise<TGetSegmentResponse> {
    return this.client.get(`/segment`)
  }
}

export const segmentService = new SegmentService();
