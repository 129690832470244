import BaseService from '@/services/base-service';
import { TApplyCouponCodeApiPayload, TApplyVasPayload, TUpdateCartPayload, TUpdateCartApiResponse } from './models/update-cart';
import { TCreatePaymentPayload, TCreatePaymentApiResponse, TUpdateTenureApiResponse } from './models/create-payment';
import { TUpdateCartItemQuantityPayload } from './models/update-cart-quantity';
import { TUpdateCartItemPayload } from './models/add-item-to-cart';
import { CART_TYPE, TGetCartApiResponse } from './models/cart';
import { appendLocationToQueryParams } from '@/utils/interceptors';
import { TUpdateTenurePayload } from '@/hooks/useCart/types';
import { CartContext } from '@/utils/cart';
// import { MetaApiResponse } from './models/meta';

class CartService extends BaseService {
  constructor() {
    super({
      serviceName: 'Cart',
      customOptions: {
        interceptors: {
          request: appendLocationToQueryParams
        }
      }
    });
  }

  public getCarts(query: any): Promise<TGetCartApiResponse> {
    return this.client.get('/carts', {
      params: query
    });
  }
  public addItemToCart(cartId: number, payload: TUpdateCartItemPayload): Promise<TUpdateCartApiResponse> {
    return this.client.post(`/carts/${cartId}/cartItems`, payload);
  }
  public updateCartItemQuantity(cartId: number, itemId: string | number, cartType: CART_TYPE, payload: TUpdateCartItemQuantityPayload): Promise<TUpdateCartApiResponse> {
    return this.client.put(`/carts/${cartId}/cartItems/${itemId}`, payload, {
      params: {
        cartType
      }
    });
  }
  public applyCouponCode(cartId: number, cartType: CART_TYPE, payload: TApplyCouponCodeApiPayload): Promise<TUpdateCartApiResponse> {
    return this.client.post(`/carts/${cartId}/applyCoupon`, payload, {
      params: {
        cartType
      }
    });
  }
  public removeItemFromCart(cartId: number, itemId: number, cartType: CART_TYPE): Promise<TUpdateCartApiResponse> {
    return this.client.delete(`/carts/${cartId}/cartItems/${itemId}`, {
      params: {
        cartType
      }
    });
  }
  public updateTenure(cartId: number, itemId: number, payload: TUpdateTenurePayload): Promise<TUpdateTenureApiResponse> {
    return this.client.put(`/carts/${cartId}/cartItems/${itemId}`, payload, {
      params: {
        cartType: 'RENTAL'
      }
    });
  }
  public updateCart(cartId: number, updatedData: TUpdateCartPayload): Promise<TUpdateCartApiResponse> {
    return this.client.put(`/carts/${cartId}`, updatedData);
  }

  public applyVas(cartId: number, cartType: CART_TYPE, payload: TApplyVasPayload): Promise<TUpdateCartApiResponse> {
    return this.client.post(`/carts/${cartId}/applyVas`, payload, {
      params: {
        cartType
      }
    });
  }

  public removeCouponCode(cartId: number, cartType: CART_TYPE): Promise<any> {
    return this.client.delete(`/carts/${cartId}/removeCoupon`, {
      params: {
        cartType
      }
    });
  }

  public createPayment(cartId: number, payload: TCreatePaymentPayload): Promise<TCreatePaymentApiResponse> {
    return this.client.post(`/carts/${cartId}/createPayment`, payload);
  }

  public moveToWishlist(cartId: number, cartType: CART_TYPE, itemId: number): Promise<TUpdateCartApiResponse> {
    return this.client.post(`/carts/${cartId}/cartItems/${itemId}/move-to-wishlist`, {}, {
      params: {
        cartType
      }
    });
  }

  public bulkRemoveCartItems(payload: { shouldRemoveAllOOSItems: boolean; context: CartContext; cartItems: number[]; }): Promise<TUpdateCartApiResponse> {
    return this.client.delete(`/carts/cartItems/remove/bulk`, {
      data: payload
    });
  }

  public bulkMoveCartItemsToWishlist(payload: { catalogIds: number[], context: CartContext, shouldRemoveAllOOSItems: boolean; }): Promise<TUpdateCartApiResponse> {
    return this.client.post(`/carts/cartItems/move-to-wishlist/bulk`, payload);
  }

  public replaceCartItem(cartItemId: number, payload: { id: number, context: CartContext, tenure?: number; }): Promise<TUpdateCartApiResponse> {
    return this.client.put(`/carts/cartItems/${cartItemId}`, payload);
  }
  public bulkTenureUpdate(cartId:number,payload:{tenure:number}){
		return this.client.put(`/carts/${cartId}/tenure`,payload)
	}
}

export const cartService = new CartService();
