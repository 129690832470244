import { IBuyCartItems, TCart, IRentCartItem, TVerticalCart } from '@/services/cart/models/cart';
import { ApiState } from '@/models/generics';
import { CART_TYPE } from '@/services/cart/models/cart';
import { TApplyCouponCodeApiPayload, TApplyVasPayload, TUpdateCartApiResponse } from '@/services/cart/models/update-cart';
import { TUpdateCartItemQuantityPayload } from '@/services/cart/models/update-cart-quantity';
import { TOrder } from '@/services/checkout/models/create-cart-order';


export enum CART_STATUS {
  PRODUCTS_IN_CART = 'products_in_cart',
  EMPTY_CART = 'empty_cart',
}

export interface IListAndStringified<RawType> {
  getList: () => RawType[]; // required for moe events
  getString: () => string;
}

export type TUpdateTenurePayload = {
  tenure: number;
  quantity: number;
};

export type TUpdateCartItemPayload = {
  id: number;
  tenure: number;
};

export type CartAttributes = {
  buyProductIds: number[];
  rentalProductIds: number[];
  lineOfProducts: string[];
  verticals: string[];
  cartStatus: CART_STATUS;
  buyProductIdsString: string;
  rentalProductIdsString: string;
  lineOfProductsString: string;
  verticalsString: string;
};

export interface TUseCart {
  id?: number,
  getCartApiState: ApiState,
  cart?: TCart,
  rentCart?: TVerticalCart,
  buyCart?: TVerticalCart,
  canCheckout: boolean,
  applyingVas?: boolean,
  cartCheckoutOrder?: TOrder,
  totalCartItems: number;
  rentalCartItemsCount: number;
  buyCartItemsCount: number;
  buyCartItems: IBuyCartItems[];
  rentalCartItems: IRentCartItem[];
  cartErrorDisplayMessage?: string;
  getCartAttributes: () => CartAttributes;
  cartActions: {
    getCart: (query?: any) => void;
    addItemToCart: (payload: TUpdateCartItemPayload) => Promise<TUpdateCartApiResponse>;
    updateTenure: (itemId: number, payload: TUpdateTenurePayload) => Promise<TUpdateCartApiResponse>;
    removeItemFromCart: (itemId: number, cartType: CART_TYPE) => Promise<TUpdateCartApiResponse>;
    updateCartItemQuantity: (itemId: number, cartType: CART_TYPE, payload: TUpdateCartItemQuantityPayload) => Promise<TUpdateCartApiResponse>;
    applyCouponCode: (cartType: CART_TYPE, payload: TApplyCouponCodeApiPayload) => Promise<TUpdateCartApiResponse>;
    applyVas: (cartType: CART_TYPE, payload: TApplyVasPayload) => Promise<TUpdateCartApiResponse>;
    removeCoupon: (cartType: CART_TYPE) => Promise<TUpdateCartApiResponse>;
  };
}