import BaseService from '@/services/base-service';
import { TRegisterUserApiResponse, TRegisterUserPayload, TVerifyOtpApiResponse, TVerifyOtpPayload, TVerifyPhoneApiResponse, TVerifyPhonePayload, TLogOutUserApiResponse } from './models/meta';

class AuthService extends BaseService {
  constructor() {
    super({
      serviceName: "Login"
    });
  }

  public verifyPhone(payload: TVerifyPhonePayload): Promise<TVerifyPhoneApiResponse> {
    return this.client.post('/users/verify-account', payload);
  }

  public verifyOtp(payload: TVerifyOtpPayload): Promise<TVerifyOtpApiResponse> {
    return this.client.post('/users/verify-otp', payload);
  }

  public registerUser(payload: TRegisterUserPayload): Promise<TRegisterUserApiResponse> {
    return this.client.post('/users/register', payload);
  }

  public logOutUser(): Promise<TLogOutUserApiResponse> {
    return this.client.delete('/users/logout');
  }
}

export const authService = new AuthService();
