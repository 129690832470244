import React from 'react';

const fontWeightMapper = {
    Regular: 400,
    Medium: 500,
    SemiBold: 600,
    
};

declare module '@mui/material/styles' {
    interface TypographyVariants {
        poster: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        H3_Regular: React.CSSProperties;
        H2_Medium: React.CSSProperties;
        H6_SemiBold: React.CSSProperties;
        H5_Regular: React.CSSProperties;
        H5_Medium: React.CSSProperties;
        H5_SemiBold: React.CSSProperties;
        H5A_Regular: React.CSSProperties;
        H5A_Medium: React.CSSProperties;
        H5A_SemiBold: React.CSSProperties;
        H7_Medium: React.CSSProperties;
        H9_Regular: React.CSSProperties;
        H9_Medium: React.CSSProperties;
        H9_SemiBold: React.CSSProperties;
        H9A_Regular: React.CSSProperties;
        H9A_Medium: React.CSSProperties;
        H9A_SemiBold: React.CSSProperties;
        H10_Regular: React.CSSProperties;
        H10_Medium: React.CSSProperties;
        H10_SemiBold: React.CSSProperties;
        H10A_Regular: React.CSSProperties;
        H10A_Medium: React.CSSProperties;
        H10A_SemiBold: React.CSSProperties;
        H11_Regular: React.CSSProperties;
        H11_Medium: React.CSSProperties;
        H11_SemiBold: React.CSSProperties;
        H11A_Regular: React.CSSProperties;
        H11A_Medium: React.CSSProperties;
        H11A_SemiBold: React.CSSProperties;
        H14_Regular: React.CSSProperties;
        H14_Medium: React.CSSProperties;
        H14_SemiBold: React.CSSProperties;
        P_Regular: React.CSSProperties;
        P_Medium: React.CSSProperties;
        P_SemiBold: React.CSSProperties;
        Small_Regular: React.CSSProperties;
        Small_Medium: React.CSSProperties;
        Small_SemiBold: React.CSSProperties;
        Tiny_Regular: React.CSSProperties;
        Tiny_Medium: React.CSSProperties;
        Tiny_SemiBold: React.CSSProperties;
        Recline_16_Regular: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        H8_Medium: true,
        H6_Medium: true,
        H3_Regular: true;
        H2_Medium: true;
        H3_Medium: true,
        H5_Regular: true,
        H6_SemiBold: true,
        H7_Medium: true,
        'H5-Regular': true,
        H5_Medium: true,
        H5_SemiBold: true,
        H5A_Regular: true,
        H5A_Medium: true,
        H5A_SemiBold: true,
        H9_Regular: true,
        H9_Medium: true,
        H9_SemiBold: true,
        H9A_Regular: true,
        H9A_Medium: true,
        H9A_SemiBold: true,
        H10_Regular: true,
        H10_Medium: true,
        H10_SemiBold: true,
        H10A_Regular: true,
        H10A_Medium: true,
        H10A_SemiBold: true,
        H11_Regular: true,
        H11_Medium: true,
        H11_SemiBold: true,
        H11A_Regular: true,
        H11A_Medium: true,
        H11A_SemiBold: true,
        H14_Regular: true,
        H14_Medium: true,
        H14_SemiBold: true,
        P_Regular: true,
        P_Medium: true,
        P_SemiBold: true,
        Small_Regular: true,
        Small_Medium: true,
        Small_SemiBold: true,
        Tiny_Regular: true,
        Tiny_Medium: true,
        Tiny_SemiBold: true,
        Recline_16_Regular: true,
    }
}

export const typoGraphyConfig = {
    "H8_Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 28,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H7_Medium": {
        fontFamily: "Recline",
        fontSize: "30px",
        fontWeight: fontWeightMapper["Medium"]
    },
    "H6_Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 32,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H2_Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 42,
        fontWeight: fontWeightMapper["Medium"]
    },
    "H1A_Medium":{
        fontFamily: "Recline",
        fontSize: 56,
        fontWeight: fontWeightMapper["Medium"]

    },
    "H2_SemiBold": {
        fontFamily: "Work Sans",
        fontSize: 48,
        fontWeight: fontWeightMapper["SemiBold"]
    },
    "H3_Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 40,
        fontWeight: fontWeightMapper["Medium"]
    },
    "H6_SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 32,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H5_Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H5_Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H5_SemiBold": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H5A_Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H5A_Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H5A_SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    'H2-SemiBold':{
        fontFamily:"work Sans"
    },
    "H9_Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H9_Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H9_SemiBold": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H9A_Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H9A_Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H9A_SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H10_Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H10_Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H10_SemiBold": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H10A_Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H10A_Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H10A_SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H11_Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H11_Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H11_SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "Recline_16_Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 16,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H11A_Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H11A_Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H11A_SemiBold": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H14_Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 16,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H14_Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 16,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H14_SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 16,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "P_Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 14,
        fontWeight: fontWeightMapper["Regular"],
    },
    "P_Medium": {
        fontFamily: "Work Sans !important",
        // lineHeight: 1,
        fontSize: 14,
        fontWeight: fontWeightMapper["Medium"],
    },
    "P_SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 14,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "Small_Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 12,
        fontWeight: fontWeightMapper["Regular"],
    },
    "Small_Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 12,
        fontWeight: fontWeightMapper["Medium"],
    },
    "Small_SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 12,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "Tiny_Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 10,
        fontWeight: fontWeightMapper["Regular"],
    },
    "Tiny_Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 10,
        fontWeight: fontWeightMapper["Medium"],
    },
    "Tiny_SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 10,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H3_Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 40,
        fontWeight: fontWeightMapper["Regular"]
    },
};

export const variantMapping = {
    H8_Medium: 'p',
    H6_Medium: 'p',
    H5_Regular: 'p',
    H5_Medium: 'p',
    H5_SemiBold: 'p',
    H5A_Regular: 'p',
    H5A_Medium: 'p',
    H5A_SemiBold: 'p',
    H9_Regular: 'p',
    H9_Medium: 'p',
    H9_SemiBold: 'p',
    H9A_Regular: 'p',
    H9A_Medium: 'p',
    H9A_SemiBold: 'p',
    H10_Regular: 'p',
    H10_Medium: 'p',
    H10_SemiBold: 'p',
    H10A_Regular: 'p',
    H10A_Medium: 'p',
    H10A_SemiBold: 'p',
    H11_Regular: 'p',
    H11_Medium: 'p',
    H11_SemiBold: 'p',
    H11A_Regular: 'p',
    H11A_Medium: 'p',
    H11A_SemiBold: 'p',
    H14_Regular: 'p',
    H14_Medium: 'p',
    H14_SemiBold: 'p',
    P_Regular: 'p',
    P_Medium: 'p',
    P_SemiBold: 'p',
    Small_Regular: 'p',
    Small_Medium: 'p',
    Small_SemiBold: 'p',
    Tiny_Regular: 'p',
    Tiny_Medium: 'p',
    Tiny_SemiBold: 'p',
    Recline_16_Regular: 'p'
};

export const getStyle = (typeScale: string) => {
    return typoGraphyConfig[typeScale as keyof typeof typoGraphyConfig];
};
