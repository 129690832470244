import { LencoTypography } from '@/atomic-components/LencoTypography';
import Auth from '.';
import { useState } from 'react';
import { getColor } from '@/utils/theme/colors';
import { useUser } from '@/hooks/useUser';
import { useLogin } from '@/hooks/useLogin';

type LoginTriggerButtonProps = {};

const LoginTriggerButton = (props: LoginTriggerButtonProps) => {
	const { isLoggedIn, loginActions } = useLogin();

	function openAuthFlow() {
		loginActions.showLoginFlow();
	}

	function onLoginClicked() {
		if (!isLoggedIn) {
			openAuthFlow();
		}
	}

	return (
		<>
			<LencoTypography
				m='16px 0px'
				className='cursor-pointer'
				sx={{ textDecoration: 'underline', color: getColor('Minty +1') }}
				onClick={onLoginClicked}
			>
				Login
			</LencoTypography>
		</>
	);
};

export default LoginTriggerButton;
