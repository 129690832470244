import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { Input, InputProps , useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

type SingleOTPInputProps = InputProps & {
	focus: boolean;
	otpValueAtIndex: string;
	border: string;
	VerifyOtp: Function;
};

const SingleOtpBox = (props: SingleOTPInputProps) => {
	const inputBoxRef = useRef<HTMLInputElement>(null);
	const { focus, ...rest } = props;
	const theme = useTheme();

	useEffect(() => {
		if (inputBoxRef.current) {
			if (props.focus) {
				inputBoxRef.current.focus();
			}
		}
	}, [focus]);
	return (
		<FlexBox>
			<Input
				disableUnderline
				inputRef={inputBoxRef}
				value={props.otpValueAtIndex}
				onKeyUp={(e) => {
					if (e.keyCode == 13) {
						props.VerifyOtp();
					}
				}}
				sx={{
					width: '46px',
					height: '50px',
					// border: `${props.border}`,
					borderRadius: '8px',
					backgroundColor: theme?.colors?.lencoPrimaryP2,
					paddingTop: '2px',
					paddingLeft: '14px',
					marginRight: '8px',
					color:'white'
				}}
				{...rest}
			/>
		</FlexBox>
	);
};

export default SingleOtpBox;
