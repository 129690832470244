import { segmentService } from '@/services/segment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initalizeHaptik } from '@/hooks/useHaptik';
import { partnerService } from '@/services/partner';
import { setshowLogin } from '@/store/haptik/actions';
import { useFullScreenLoader } from '@/hooks/useFullScreenLoader';

type HaptikProps = {
	children: any;
	onClick?: () => any;
	onLaunch?: () => any;
};

function HaptikHoc(props: HaptikProps) {
	const { isLoggedIn, isMetaLoaded } = useSelector((state: any) => {
		return state.user;
	});

	const [segment, setSegment] = useState<string[]>();

	const [sdkPr, setSDKPr] = useState<any>(null);
	const [isFirstVisit, setIsFirstVisit] = useState<any>(null);
	const [isSignUpSuccessful, setIsSignUpSuccessful] = useState<any>(false);

	const isRentalCustomer = segment?.includes('RENTAL_CUSTOMER');
	const isSaleCustomer = segment?.includes('SALE_CUSTOMER');
	const isUnlimitedCustomer = segment?.includes('UNLMTD_CUSTOMER');
	const userStore = useSelector((state: any) => state?.user);
	const hideComposer = isLoggedIn
		? !(isRentalCustomer || isSaleCustomer || isUnlimitedCustomer)
		: true;

	const dispatch: any = useDispatch();
	const { loader } = useFullScreenLoader();

	const destroyHaptikUserSession = () => {
		(window as any).HaptikSDK.destroy();
		setSDKPr(null);
		setIsSignUpSuccessful(false);
		document.getElementById('haptik-jssdk')?.remove();
	};

	function getUserSegments() {
		return segmentService.getSegment().then((res: any) => {
			setSegment(res?.data?.data?.segments);
		});
	}

	useEffect(() => {
		if (isSignUpSuccessful) {
			destroyHaptikUserSession();
			setIsFirstVisit(null);
		}
		if (isMetaLoaded) {
			getUserSegments();
		}
	}, [isLoggedIn, isMetaLoaded]);

	function _signupGuest(ghostUser: any) {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			try {
				const { data: data } = await partnerService.generatePartnerToken({
					partnerName: 'haptik',
				});

				(window as any).HaptikSDK &&
					(window as any).HaptikSDK.signup(
						{
							auth_id: data.data,
							auth_code: data.data,
							custom_data: {
								name: 'Guest',
								medium: 'web',
								isLoggedIn: isLoggedIn?.toString(),
								isRentalCustomer: isRentalCustomer?.toString(),
								isSaleCustomer: isSaleCustomer?.toString(),
								isUnlimitedCustomer: isUnlimitedCustomer?.toString(),
							},
						},
						(status: any) => {
							if (status) {
								resolve(true);
							} else {
								reject(true);
							}
						}
					);
			} catch (error) {
				reject('[Haptik] SIGN UP ERROR');
			}
		});
	}

	function _subscriberSignUp(user: any) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await partnerService.generatePartnerToken({
					partnerName: 'haptik',
				});
				if (
					(window as any)?.HaptikSDK &&
					typeof (window as any)?.HaptikSDK?.signup === 'function'
				) {
					(window as any)?.HaptikSDK?.signup(
						{
							username: user?.first_name,
							mobile_no: user?.contact_no.toString(),
							email: user?.email_id,
							auth_id: user?.id.toString(),
							auth_code: response.data.data,
							custom_data: {
								medium: 'web',
								name: user?.first_name,
								mobile_no: user?.contact_no.toString(),
								email: user?.email_id,
								isLoggedIn: isLoggedIn?.toString(),
								isRentalCustomer: isRentalCustomer?.toString(),
								isSaleCustomer: isSaleCustomer?.toString(),
								isUnlimitedCustomer: isUnlimitedCustomer?.toString(),
							},
						},
						(status: any) => {
							if (status) {
								resolve('[Haptik] SIGN UP SUCCESS');
							} else {
								reject('[Haptik] SIGN UP ERROR');
							}
						}
					);
				}
			} catch (error) {
				console.error(error);
			}
		});
	}

	function onClick() {
		if (typeof props.onClick == 'function') {
			props.onClick();
		}

		loadSdk();
	}

	function onLaunch() {
		if (typeof props.onLaunch == 'function') {
			props.onLaunch();
		}
	}

	function initiateUserHandshake() {

		sdkPr
			.then(() =>
				isLoggedIn? _subscriberSignUp(userStore?.user): _signupGuest(userStore?.ghostUser)
			)
			.then(() => {
				setIsSignUpSuccessful(true);
				(window as any).HaptikSDK.launchMessage('Main Menu', null, true);
				setIsFirstVisit(false);
			})
			.catch(() => {
				return Promise.reject('[Haptik], An Error has occured');
			})
			.finally(() => {
				onLaunch();
			});
	}

	useEffect(() => {
		if (sdkPr) {
			initiateUserHandshake();
		}
	}, [sdkPr]);

	const showLoginModal = () => {
		dispatch(setshowLogin(true));
	};

	const loadSdk = async () => {
		if (!segment) {
			await getUserSegments();
		}

		if (sdkPr == null) {
			setSDKPr(initalizeHaptik(hideComposer, showLoginModal));
		} else {
			if (isFirstVisit === null) {
				(window as any)?.HaptikSDK?.launchMessage('Main Menu', null, true);
				setIsFirstVisit(false);
				onLaunch();
			} else {
				(window as any)?.HaptikSDK?.expandWidget();
				onLaunch();
			}
		}
	};

	return <div onClick={onClick}>{props.children}</div>;
}

export default HaptikHoc;
