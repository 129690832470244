import Hoverable from '@/atomic-components/Hoverable';
import WishlistsMenuItemIcon from '@/assets/profile/wishlist.svg';
import WishlistsMenuItemHoveredIcon from '@/assets/profile/wishlist-hovered.svg';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { MenuItemProps } from './HelpCenter';

const WishlistsMenuItem = (props: MenuItemProps) => {
	return (
		<Hoverable
			icons={{
				before: <WishlistsMenuItemIcon />,
				after: <WishlistsMenuItemHoveredIcon />,
			}}
			sx={{ display: 'flex', flexDirection: 'row' }}
			active={props.active}
		>
			<LencoTypography ml='16px'>My Wishlists</LencoTypography>
		</Hoverable>
	);
};

export default WishlistsMenuItem;
