export function initalizeHaptik(hideComposer = false,showLoginModal:any) {
    try {
        (window as any).haptikInitSettings = {
            'business-id': 6596,
            'client-id': '80682e195ddc020f595841312e962d3b6c423999',
            'api-key': 'furlenco:7704770c0b2973e2576ad2a8fbef78f3e996d5ee',
            'base-url': ENV.Haptik.baseURL,
            'signup-type': 'third_party',
            'custom-button': true,
            'primary-color': '#059BAA',
            'ignore-storage': true,
            'widget-positioning': 'bottom-right',
            'hide-composer': hideComposer,
            sdkEvents:(event: { data: { value: { message: string; }; }; })=>{
                if(event?.data?.value?.message=='Login'){
                    showLoginModal();
                }
            }
            
        };
        ((d, s, id) => {
            const hjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                d.getElementById('haptik-jssdk')?.remove();
            }
            const js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.defer = true;
            js.src =
                'https://toolassets.haptikapi.com/platform/javascript-xdk/production/loader.js';
            (hjs as any).parentNode.insertBefore(js, hjs);
        })(document, 'script', 'haptik-jssdk');
    } catch (error) {
        console.error(error);
        console.error('Unable to Initalize Haptik SDk');
    }

    // tslint:disable-next-line: no-shadowed-variable
    const sdkPr = new Promise((resolve) => {
        document.addEventListener('haptik_sdk', () => {
            // console.log('[Haptik] SDK mounted');
            resolve(true);
        });
    });
   return sdkPr
}