import { useState } from 'react';
import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { Box, Fade, Input, useTheme } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import SearchIcon from '../../../assets/unlmtd/searchIcon.svg';
import RecentSearch from '@/assets/RecentSearch';
import SimpleClose from '../../assets/SimpleClose.svg';
import SearchTop from '../../assets/SearchTop.svg';
import { Search_Service } from '../../../services/search/index';
import { useRouter } from 'next/router';
import SearchBarTile from './searchBarTile';
import { getStyle } from '@/utils/theme/fonts';

const searchDummyData = [
	{
		Label: 'Sofa',
		options: '42 options',
	},
	{
		Label: 'Flex 3 seater',
		options: '48 options',
	},
	{
		Label: 'Refrigerator',
		options: '25 options',
	},
	{
		Label: 'Recliner',
		options: '8 options',
	},
	{
		Label: 'Dining Table',
		options: '33 options',
	},
];

const SearchBar = () => {
	const [searchText, setSearchText] = useState('');
	const [dropdownVis, setDropdownVis] = useState(false);
	const [dropdownData, setDropdownData] = useState([]);
	const router = useRouter();
	const theme = useTheme();

	const onclick = (name: string) => {
		setDropdownData([]);
		router.push(`/unlmtd/search/results?q=${name}&lineOfProduct=UNLMTD`);
	};

	const fetchSearchData = (value: string) => {
		setSearchText(value);
		if (value.length <= 2) return;
		Search_Service.getSuggestions(value).then((res: any) =>
			setDropdownData(res.data.data.autoComplete)
		);
	};

	return (
		<FlexBox
			sx={{
				position: 'relative',
				alignItems: 'center',
				background: theme.colors?.lencoPrimaryP3,
				boxShadow: dropdownVis ? 'none' : '0px 8px 16px rgba(0, 0, 0, 0.05)',
				width: '400px',
				border: dropdownVis
					? `1px solid ${theme.colors?.lencoPrimaryP0}`
					: 'none',
				borderBottom: 'none',
				padding: '4px 12px',
				borderRadius: dropdownVis ? '24px 24px 0 0' : '30px',
				justifyContent: 'space-between',
			}}
			onClick={() => setDropdownVis(!dropdownVis)}
		>
			<Input
				sx={{
					pl: '16px',
					background: theme.colors?.lencoPrimaryP3,
					'input::placeholder': {
						color: theme?.colors?.lencoPrimaryN6,
						...getStyle('P_Regular'),
					},
					'.MuiInputBase-input': {
						color: 'white',  
					  }
				}}
				onBlur={() =>
					setTimeout(() => {
						setDropdownVis(false);
					}, 300)
				}
				placeholder={
					dropdownVis ? 'Start Searching' : 'What are you searching for?'
				}
				fullWidth
				disableUnderline
				onChange={(e) => fetchSearchData(e.target.value)}
				onKeyUp={(e) => {
					if (e.key == 'Enter') {
						onclick(searchText);
					}
				}}
				onKeyDown={(e) => {
					searchText.length <= 1 && setDropdownData([]);
				}}
			/>

			<Box
				sx={{ pt: '6px' }}
				onClick={() => {
					searchText.length > 0 && onclick(searchText);
				}}
			>
				<SearchIcon />
			</Box>
			{dropdownVis && (
				<Box
					sx={{
						boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.05)',
						boxSizing: 'content-box',
						position: 'absolute',
						width: '100%',
						background: theme?.colors?.lencoPrimaryP3,
						top: 30,
						left: '-1px',
						borderRadius: '24px',
						padding: '24px 0',
						border: `1px solid ${theme?.colors?.lencoPrimaryP0}`,
						overflow: 'hidden',
						zIndex: -1,
					}}
				>
					<Fade in={true} timeout={500}>
						<Box>
							{dropdownData.length > 0
								? dropdownData?.map((data: any) => {
										return (
											<SearchBarTile
												isDummyData={false}
												key={data?.Label}
												name={data?.Label}
												onClick={onclick}
											/>
										);
								  })
								: searchDummyData.map((data) => {
										return (
											<SearchBarTile
												isDummyData={true}
												key={data?.Label}
												name={data?.Label}
												onClick={onclick}
											/>
										);
								  })}
						</Box>
					</Fade>
				</Box>
			)}
		</FlexBox>
	);
};

export default SearchBar;
