import { ViewStates } from '@/models/generics';
import { AUTH_FLOW_TRIGGER } from '@/store/appConfig/actionTypes';
import { useAppConfig } from './useAppConfig';
import { useLencoDispatch } from './useLencoDispatch';
import { useUser } from './useUser';

type UseLogin = {
  isLoggedIn: boolean;
  authFlowViewState: ViewStates;
  onLogin?: () => void;
  loginActions: {
    showLoginFlow: (onLoginCallback?: () => void) => any;
    hideLoginFlow: () => any;
  };
};

export function useLogin(): UseLogin {
  const { isLoggedIn } = useUser();
  const dispatch = useLencoDispatch();
  const {
    appConfig: { authFlowViewState, onLogin },
  } = useAppConfig();

  function hideLoginFlow() {
    dispatch({
      type: AUTH_FLOW_TRIGGER.CLOSE_AUTH_FLOW,
    });
  }

  function showLoginFlow(onLoginCallback?: () => void) {
    
    dispatch({
      type: AUTH_FLOW_TRIGGER.OPEN_AUTH_FLOW,
      onLogin: onLoginCallback,
    });
    
  }

  return {
    isLoggedIn,
    authFlowViewState,
    onLogin,
    loginActions: {
      showLoginFlow,
      hideLoginFlow,
    },
  };
}
