export const getOS = () => {
    const userAgent = navigator.platform;
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    } else if (/android/i.test(userAgent)) {
      return 'Android';
    } else if ([
        'Macintosh',
        'MacIntel',
        'MacPPC',
        'Mac68K',
        'iPhone',
        'iPad',
        'iPod',
      ].includes(userAgent)) {
      return 'iOS';
    }
    return 'unknown';
  };
  