import { TCart } from '@/services/cart/models/cart';
import { ApiState, BaseApiResponse } from '@/models/generics';
import { ADD_ITEM_TO_CART, APPLY_COUPON, APPLY_VAS, GET_CART, REMOVE_COUPON, REMOVE_ITEM_FROM_CART, UPDATE_CART, UPDATE_CART_ITEM_QUANTITY, UPDATE_CART_ITEM_TENURE } from "./actionTypes";

export interface TCartState {
    getCartApiState: ApiState,
    cart?: TCart,
    getCartError?: BaseApiResponse<null>,
    displayErrorMessage?: string,
}

const initialState: TCartState = {
    getCartApiState: ApiState.IDLE,
    cart: undefined,
    getCartError: undefined,
    displayErrorMessage: undefined,
};

export default function cartReducer(state = initialState, action: any): TCartState {
    switch (action.type) {
        case GET_CART.LOADING:
            return {
                ...state,
                getCartApiState: ApiState.LOADING,
            };
        case GET_CART.SUCCESS:
            return {
                ...state,
                getCartApiState: ApiState.SUCCESS,
                cart: action.payload,
                getCartError: undefined,
                displayErrorMessage: undefined,
            };
        case GET_CART.FAILED:
            return {
                ...state,
                getCartApiState: ApiState.FAILED,
                getCartError: action.payload,
                displayErrorMessage: action.displayErrorMessage,
            };
        case ADD_ITEM_TO_CART.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case REMOVE_COUPON.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case UPDATE_CART_ITEM_QUANTITY.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case REMOVE_ITEM_FROM_CART.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case UPDATE_CART_ITEM_TENURE.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case APPLY_VAS.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case APPLY_COUPON.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case UPDATE_CART.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        default: return state;
    }
}