import { useNotification } from '@/hooks/useNotification';
import NotifyToast from '../NotifyToast';
import { Box } from '@mui/material';

const NotificationToasts = () => {
	const { notifications, actions } = useNotification();

	function onClose(id: string) {
		actions.closeNotification(id);
	}

	return (
		<Box sx={{ position: 'fixed', right: '0px', top: '80px', zIndex: 1000000 }}>
			{notifications.map((notification) => {
				return (
					<NotifyToast
						id={notification.id}
						key={notification.id}
						severity={notification.severity}
						message={notification.message}
						ctaText={notification?.ctaText}
						ctaLink={notification?.ctaLink}
						onClose={() => onClose(notification.id)}
					/>
				);
			})}
		</Box>
	);
};

export default NotificationToasts;
