import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { Box, Grid, Modal } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import EmailIcon from '@/assets/auth/emailIcon.svg';
import { AUTH_STATES } from '..';
import { dataLayerPushWithEvent } from '@/utils/dataLayer';

interface IEmailPopUpProps {
	setPage: Function;
	userDetails: any;
	verifyEmailId: (values: any) => void;
}

const EmailConfirmationPopUp = (props: IEmailPopUpProps) => {
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '10px',
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
	};

	const actionOnContinue = () => {
		props.verifyEmailId(props.userDetails);
		props.setPage(AUTH_STATES.VERIFY_OTP);
	};

	return (
		<Modal
			open={true}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<>
				<Grid container>
					<Grid md={10} lg={10} item>
						<FlexBox direction='column' sx={style}>
							<LencoTypography
								variant='H9_SemiBold'
								sx={{ color: getColor('Gray 0') }}
							>
								Oops!
							</LencoTypography>
							<LencoTypography variant='P_Regular' sx={{ color: '#666666' }}>
								Looks like this email ID is already registered with us.
							</LencoTypography>
							<FlexBox
								sx={{
									border: `1px solid ${getColor('Minty 0')}`,
									padding: '20px',
									marginTop: '34px',
									marginBottom: '34px',
									borderRadius: '8px',
									cursor: 'pointer',
								}}
								onClick={() => {
									actionOnContinue();
								}}
							>
								<EmailIcon />
								<FlexBox direction='column' sx={{ paddingLeft: '8px' }}>
									<LencoTypography
										variant='P_Regular'
										sx={{ color: '#666666' }}
									>
										Continue as{' '}
									</LencoTypography>
									<LencoTypography
										variant='H11_SemiBold'
										sx={{ color: '#333333' }}
									>
										{props.userDetails.email}
									</LencoTypography>
								</FlexBox>
							</FlexBox>
							<FlexBox direction='column' alignItems='center'>
								<LencoTypography
									variant='P_SemiBold'
									color={getColor('Gray -3')}
								>
									OR
								</LencoTypography>
								<Box
									onClick={() => {
										props.setPage(AUTH_STATES.USER_SIGN_UP);
										dataLayerPushWithEvent('moe_event', {
											moe_event_name: 'New Signup Page Opened',
										});
									}}
									sx={{ cursor: 'pointer' }}
								>
									<LencoTypography
										variant='H14_Medium'
										sx={{ color: getColor('Minty 0'), marginTop: '18px' }}
									>
										Skip and Create a New Account
									</LencoTypography>
								</Box>
								<LencoTypography variant='P_Regular' mt='18px' mb='34px'>
									with a new email ID
								</LencoTypography>
							</FlexBox>
						</FlexBox>
					</Grid>
				</Grid>
			</>
		</Modal>
	);
};

export default EmailConfirmationPopUp;
