import {Cloudinary} from 'cloudinary-core';
const cl = new Cloudinary({
  cloud_name: 'altaria',
  cname: 'assets.furlenco.com',
  private_cdn: true,
  secure_distribution: 'assets.furlenco.com'
});
const imageOptions = {
  quality: 'auto',
  fetchFormat: 'auto',
  dpr: '',
};


const getCloudinaryPublicId = (url: string) => {
    const keyRegex = /(?<protocol>.*)assets\.furlenco\.com\/(?<key>.*)/g
    const matches = url.matchAll(keyRegex);
    const key =  matches.next().value.groups.key;
    return key;
}
const  buildImageUrl = (cloudinaryPublicId: string, overrideOptions?: any) => {
  const cloudinarySrcSetURLs: string[] = [];
  const imgOptions: any = {...imageOptions};
  for (let i = 1; i < 5; i++) {
    imgOptions.dpr = i.toFixed(1);
    const srcSet: string = cl.url(cloudinaryPublicId,  {...imgOptions, ...overrideOptions});
    if (srcSet) {
      cloudinarySrcSetURLs.push(srcSet + ' ' + i + 'x');
    }
  }
  return cloudinarySrcSetURLs.join(', ');
}
const  buildCloudinaryImageSrcSet = (cloudinaryPublicId: string, overrideOptions?: any) => {
  return buildImageUrl(cloudinaryPublicId, overrideOptions);
}

const buildCloudinaryTinyImageUrl = (cloudinaryPublicId: string, overrideOptions?: any) => {
  const preloadImageOptions = {
    width: 20,
    quality: 0.5,
    crop: 'scale',
    fetchFormat: 'auto',
    effect: 'blur:75'
  };
  return cl.url(cloudinaryPublicId, Object.assign({}, preloadImageOptions, overrideOptions));
}

export const isValidForLazy = (url: string) => {
  return url.includes('assets.furlenco.com');
}

export const sourceGenerator = (url: string, overrideOptions?: any) => {
  if(url){if(isValidForLazy(url)) {
    const cloudinaryPublicId = getCloudinaryPublicId(url);
    return {
      srcset: buildCloudinaryImageSrcSet(cloudinaryPublicId, overrideOptions),
      src: buildCloudinaryTinyImageUrl(cloudinaryPublicId),
    }
  } else {
    return {src: url, srcset: undefined};
  }}
  else{
    return {src:"https://assets.furlenco.com/s3-furlenco-images/grogu/Group+5.png", srcset: undefined}
  }
};
