import BaseService from '@/services/base-service';
import { getLocationFromLocalStorage } from '@/utils/location';
import { TGetWishlistApiResponse } from './models/wishlist';

class WishlistService extends BaseService {
	constructor() {
		super({
			serviceName: 'Wishlists',
			customOptions: {
				interceptors: {
					request: (config) => {
						const location = getLocationFromLocalStorage();
						config.params = {
							...config.params,
							...location
						};
						return config;
					},
				},
			},
		});
	}

	public getWishlists(query?:any): Promise<TGetWishlistApiResponse> {
		return this.client.get(`/wishlists`,{
			params:query
		});
	}

	public addToWishList(id: number,query?:any): Promise<any> {
		return this.client.post(`/wishlists`, {
			catalogId: id,
		},{
			params:query
		});
	}

	public deleteFromWishlist(id: number,query?:any): Promise<any> {
		return this.client.delete(`/wishlists/${id}`,{
			params:query
		});
	}
}

export const wishlistService = new WishlistService();
