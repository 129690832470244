import FlexBox from '@/atomic-components/FlexBox';
import { Box, Drawer, Divider as MuiDivider , useTheme} from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import CloseIcon from '@/assets/unlmtd/CrossIcon.svg';
import { useEffect, useState } from 'react';
import { useLocation } from '@/hooks/useLocation';
import { locationService } from '@/services/location';
import { TGetServiceableCitiesApiResponseData } from '@/services/location/models/get-serviceable-cities';
import { ApiState } from '@/models/generics';
import { TCity } from '@/models/location';
import { getColor } from '@/utils/theme/colors';
import { TCheckPincodeServiceabilityApiResponseData } from '@/services/location/models/check-pincode-serviceability';
import PincodeInput from './PincodeInput';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import { TLocation } from '@/store/location/reducer';
import { getLocationFromLocalStorage } from '@/utils/location';
import { dataLayerPushWithEvent } from '@/utils/dataLayer';
import { useFullScreenLoader } from '@/hooks/useFullScreenLoader';
import { withFullScreenLoader } from '@/hoc/withFullScreenLoader';
// import OtherCities from './OtherCities';
import TopCities from './TopCities';
import LocationSelectorShimmer from './Shimmer';
import usePlan from '@/hooks/unlmtd/usePlan';

type TLocationSelectorProps = {
	onClose?: (location?: TLocation) => any;
};

enum CitiesListView {
	TOP_CITIES = 'TOP_CITIES',
	OTHER_CITIES = 'OTHER_CITIES',
}

const Divider = () => {
	const theme = useTheme(); 

	return (
		<MuiDivider sx={{ width: '30%', borderColor: theme?.colors?.lencoPrimaryN1 }} />
	);
};

const LocationSelectorDrawer = (props: any) => {
	const { selectedLocation, locationActions, isLocationSelectorOpen } =
		useLocation();

	const {planActions} = usePlan()
	const { loader } = useFullScreenLoader();
	const [pincode, setPincode] = useState<string>('');
	const [locationSelectorViewState, setLocationSelectorViewState] =
		useState<ApiState>(ApiState.LOADING);
	const [checkPincodeViewState, setCheckPincodeViewState] = useState<ApiState>(
		ApiState.IDLE
	);
	const [serviceableCitiesResponse, setServiceableCitiesService] =
		useState<TGetServiceableCitiesApiResponseData>();
	const [pincodeError, setPincodeError] = useState<string | undefined>();
	const [citiesListViewState, setCitiesListViewState] =
		useState<CitiesListView>(CitiesListView.TOP_CITIES);

	const containsOtherCities =
		!!serviceableCitiesResponse?.otherCities &&
		Array.isArray(serviceableCitiesResponse?.otherCities) &&
		serviceableCitiesResponse?.otherCities.length > 0;
	
	const theme = useTheme();

	function onPincodeEnter(e: React.ChangeEvent<HTMLInputElement>) {
		setPincodeError(undefined);
		setPincode(e.currentTarget.value);
	}

	function checkIfPincodeValid(pincode: string) {
		const regex = /^[1-9][0-9]{5}$/;

		return regex.exec(pincode);
	}

	async function getEnteredPincodeDetails(): Promise<TCheckPincodeServiceabilityApiResponseData | null> {
		return locationService
			.getPincodeServiceabilityService(Number(pincode))
			.then((res) => {
				setCheckPincodeViewState(ApiState.COMPLETED);
				return res.data?.data;
			})
			.catch((error) => {
				setCheckPincodeViewState(ApiState.FAILED);
				return null;
			});
	}

	async function getServiceableCities() {
		setLocationSelectorViewState(ApiState.LOADING);

		try {
			const res = await locationService.getServiceableCitiesService();

			if (res.data?.success) {
				setServiceableCitiesService(res.data?.data);
				setLocationSelectorViewState(ApiState.SUCCESS);
			}
		} catch (error) {
			console.error(error);
			setLocationSelectorViewState(ApiState.FAILED);
		}
	}

	async function checkPincode() {
		loader.show();
		dataLayerPushWithEvent('moe_event', {
			moe_event_name: 'Pincode Entered',
			moe_event_data: {
				pincode: pincode,
			},
		});
		setCheckPincodeViewState(ApiState.LOADING);
		const isPincodeValid = checkIfPincodeValid(pincode.toString());

		if (isPincodeValid) {
			try {
				const pincodeDetails = await getEnteredPincodeDetails();
				if (pincodeDetails?.isServiceable) {
					saveLocationAndGetMeta({
						cityId: pincodeDetails.city.id,
						cityName: pincodeDetails.city.name,
						pincode: Number(pincode),
					});
				} else {
					setPincodeError('Pincode is not serviceable');
					dataLayerPushWithEvent('moe_event', {
						moe_event_name: 'Unserviceable Area Error',
						moe_event_data: {
							pincode: pincode,
						},
					});
				}
			} catch (error: any) {
				setPincodeError(
					error?.response?.data?.error?.message ?? 'Something Went Wrong'
				);
				console.error(error);
			}
		} else {
			setPincodeError('Invalid Pincode Entered');
		}

		setCheckPincodeViewState(ApiState.COMPLETED);
		loader.hide();
	}

	function saveLocationAndGetMeta(location: TLocation) {
		locationActions.updateSelectedLocationAndNotify(location);

		locationActions.triggerCitySelector(
			LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_CLOSE
		);
	}

	function onCityClicked(city: TCity) {
		dataLayerPushWithEvent('moe_event', {
			moe_event_name: 'City Selected',
			moe_event_data: {
				selected_city: city.id,
			},
		});
		const location: TLocation = {
			pincode: city.default_pincode,
			cityId: city.id,
			cityName: city.name,
		};
		planActions.removePlan();
		setCitiesListViewState(CitiesListView.TOP_CITIES);
		saveLocationAndGetMeta(location);
	}

	const displayOtherCities = () => {
		setCitiesListViewState(CitiesListView.OTHER_CITIES);
	};

	const displayTopCities = () => {
		setCitiesListViewState(CitiesListView.TOP_CITIES);
	};

	const closeLocationSelector = () => {
		locationActions.triggerCitySelector(
			LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_CLOSE
		);

		if (!selectedLocation.pincode) {
			/*
				if location doesnot exist in localstorage,
				save default location to localstorage
			*/
			locationActions.updateSelectedLocationAndNotify({
				pincode: 560068,
				cityId: 1,
				cityName: 'Bengaluru',
			});
		}

		setCitiesListViewState(CitiesListView.TOP_CITIES);
		props.onClose && props.onClose();
	};


	useEffect(() => {
		if (isLocationSelectorOpen) {
			setPincode('');
			dataLayerPushWithEvent('moe_event', {
				moe_event_name: 'City Selection Page Loaded',
			});
		}
	}, [isLocationSelectorOpen]);

	useEffect(() => {
		if (!serviceableCitiesResponse) {
			getServiceableCities();
		}
	}, []);

	return (
		<Drawer
			anchor='left'
			open={isLocationSelectorOpen}
			onClose={closeLocationSelector}
		>
			<FlexBox
				direction='column'
				alignItems={'center'}
				sx={{
					width: '577px',
					// minHeight:'100vh',
					padding: '80px',
					background: theme?.colors?.lencoPrimaryP3,
				}}
				id='location-selector'
			>
				<FlexBox fullWidth style={{justifyContent:'space-between'}}>
					<LencoTypography variant='H10A_Regular' sx={{color:theme?.colors?.lencoPrimaryN6}}>
						Select Delivery Location
						
					</LencoTypography>
					<Box className='cursor-pointer' onClick={closeLocationSelector}>
						<CloseIcon />
					</Box>
				</FlexBox>
				<PincodeInput
					value={pincode}
					onChange={onPincodeEnter}
					currentlySelectedPincode={selectedLocation?.pincode || 560068}
					checkPincode={checkPincode}
					pincodeError={pincodeError}
				/>
				{[ApiState.LOADING, ApiState.FAILED].includes(
					locationSelectorViewState
				) ? (
					<LocationSelectorShimmer />
				) : (
					<>
						{citiesListViewState == CitiesListView.TOP_CITIES && (
							<TopCities
								displayOtherCities={displayOtherCities}
								topCities={serviceableCitiesResponse?.topCities}
								onCityClicked={onCityClicked}
								showOtherCitiesButton={containsOtherCities}
							/>
						)}
						{/* {citiesListViewState == CitiesListView.OTHER_CITIES && (
							<OtherCities
								displayTopCities={displayTopCities}
								otherCities={serviceableCitiesResponse?.otherCities}
								onCityClicked={onCityClicked}
							/>
						)} */}
					</>
				)}
			</FlexBox>
		</Drawer>
	);
};

export default withFullScreenLoader(LocationSelectorDrawer);
