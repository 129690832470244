import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { NextComponentType, NextPage, NextPageContext } from 'next';
import BaseLayout from '@/layouts/BaseLayout';
import theme from '@/utils/theme';
import { Box } from '@mui/material';
import LocationSelectorDrawer from '../LocationSelector';
import { useUser } from '@/hooks/useUser';
import { useLencoDispatch } from '@/hooks/useLencoDispatch';
import { readUserSelectedLocationFromStorageAction } from '@/store/location/actions';
import { useLocation } from '@/hooks/useLocation';
import { ApiState, ViewStates } from '@/models/generics';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import { dataLayerPush } from '@/utils/dataLayer';
import useMoengage from '@/hooks/useMoengage';
import { WithRouterConfig } from '@/hoc/withRouterConfig';
import NotificationToasts from './Notifications';
import { useNotification } from '@/hooks/useNotification';
import ChatBotFloatingButton from './ChatBotFloatingButton';
import Auth from '../Auth';
import { useLogin } from '@/hooks/useLogin';
import { useRouter } from 'next/router';
import { useAppConfig } from '@/hooks/useAppConfig';
import { WithCitySelector } from '@/hoc/withCitySelector';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const getPage = (
	Comp: NextComponentType<NextPageContext, any, any> &
		NextPageWithLayout<{}, {}>,
	pageProps: any
) => {
	if (!Comp.getLayout) {
		return (
			<BaseLayout>
				<Comp {...pageProps} />
			</BaseLayout>
		);
	}

	return Comp.getLayout(<Comp {...pageProps} />);
};

dataLayerPush({ medium: 'web' });

const Root = (props: AppPropsWithLayout) => {
	const { Component, pageProps } = props;
	const [isHaptikEnabled, setIsHaptikEnabled] = useState(false);
	const dispatch = useLencoDispatch();
	const { selectedLocationSyncState, selectedLocation, locationActions } =
		useLocation();
	const { getUserApiState, userActions, user, ghostUser } = useUser();
	const moe = useMoengage();
	const { authFlowViewState, loginActions } = useLogin();

	const router = useRouter();
	const { appConfigActions } = useAppConfig();

	function initMoengage() {
		moe.init();
		if (!!moe) {
			if (!!user) {
				moe.setUserId(user.id);
				moe.updateUserAttributes({
					firstName: user.first_name,
					email: user.email_id,
					mobile: user.contact_no,
					userName: user.first_name,
				});
			} else if (!!ghostUser) {
				moe.setUserId(ghostUser?.id as number);
			}
		}
	}

	function closeAuthDrawer() {
		loginActions.hideLoginFlow();
	}


	function onRouteChange() {
		appConfigActions.addHistory(router.asPath);
	}

	useEffect(() => {
		router.events.on('routeChangeStart', onRouteChange);
		return () => {
			router.events.off('routeChangeStart', onRouteChange);
		};
	}, []);

	useEffect(() => {
		if (getUserApiState == ApiState.SUCCESS) {
			initMoengage();
		}
	}, [getUserApiState]);

	useEffect(() => {
		dispatch(readUserSelectedLocationFromStorageAction());
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<NotificationToasts />
			<Auth
				open={authFlowViewState == ViewStates.VISIBLE}
				onClose={closeAuthDrawer}
			/>
			<Box id='main'>{getPage(Component, pageProps)}</Box>
			<LocationSelectorDrawer />
			<WithRouterConfig>
				<ChatBotFloatingButton />
			</WithRouterConfig>
		</ThemeProvider>
	);
};

export default WithCitySelector(Root);
