
import { CART_TYPE } from '@/services/cart/models/cart';
import { TApplyVasPayload } from '@/services/cart/models/update-cart';
import { TUpdateCartItemQuantityPayload } from '@/services/cart/models/update-cart-quantity';
import { checkoutService } from '@/services/checkout';
import { GroguDispatch } from '..';
import { cartService } from '../../services/cart';
import { APPLY_VAS, CREATE_CART_CHECKOUT_ORDER, GET_CART, REMOVE_ITEM_FROM_CART, UPDATE_CART_ITEM_QUANTITY, UPDATE_CART_ITEM_TENURE } from './actionTypes';

export const getUserCartAction = (query?: any) => async (dispatch: GroguDispatch) => {
    try {
        dispatch({
            type: GET_CART.LOADING
        });
        const res = await cartService.getCarts(query);

        dispatch({
            type: GET_CART.SUCCESS,
            payload: res.data?.data?.cart,
        });
    } catch (error: any) {
        let errorMessage = "Something went wrong";
        const validationErrorsString = error?.response?.data?.error?.data?.validation_errors?.join('');

        if (validationErrorsString?.includes('Pincode is not serviceable')) {
            errorMessage = "Your selected pincode is not serviceable. Please select another pincode to continue";
        }

        dispatch({
            type: GET_CART.FAILED,
            payload: error,
            displayErrorMessage: errorMessage,
        });
    }
};

export const updateCartItemQuantityAction = (cartId: number, itemId: string | number, cartType: CART_TYPE, payload: TUpdateCartItemQuantityPayload) => async (dispatch: GroguDispatch) => {
    try {
        dispatch({
            type: UPDATE_CART_ITEM_QUANTITY.LOADING
        });
        const res = await cartService.updateCartItemQuantity(cartId, itemId, cartType, payload);

        dispatch({
            type: UPDATE_CART_ITEM_QUANTITY.SUCCESS,
            payload: res.data?.data?.cart
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CART_ITEM_QUANTITY.FAILED,
            payload: error
        });
    }
};

export const removeItemFromCartAction = (cartId: number, itemId: number, cartType: CART_TYPE) => async (dispatch: GroguDispatch) => {
    try {
        dispatch({
            type: REMOVE_ITEM_FROM_CART.LOADING
        });

        const res = await cartService.removeItemFromCart(cartId, itemId, cartType);

        dispatch({
            type: REMOVE_ITEM_FROM_CART.SUCCESS,
            payload: res.data?.data?.cart
        });
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_FROM_CART.FAILED,
            payload: error
        });
    }
};

export const updateCartItemTenureAction = (cartId: number, itemId: number, payload: any) => async (dispatch: GroguDispatch) => {
    try {
        dispatch({
            type: UPDATE_CART_ITEM_TENURE.LOADING
        });

        const res = await cartService.updateTenure(cartId, itemId, payload);

        dispatch({
            type: UPDATE_CART_ITEM_TENURE.SUCCESS,
            payload: res.data?.data?.cart
        });
    } catch (error) {
        console.error({ error });
        dispatch({
            type: UPDATE_CART_ITEM_TENURE.FAILED,
            payload: error
        });
    }
};

export const applyVasAction = (cartId: number, cartType: CART_TYPE, payload: TApplyVasPayload, successCallback?: Function) => async (dispatch: GroguDispatch) => {
    try {
        dispatch({
            type: APPLY_VAS.LOADING
        });

        const res = await cartService.applyVas(cartId, cartType, payload);

        dispatch({
            type: APPLY_VAS.SUCCESS,
            payload: res.data?.data?.cart
        });

        if (typeof successCallback === 'function') {
            successCallback();
        }
    } catch (error) {
        console.error({ error });
        dispatch({
            type: APPLY_VAS.FAILED,
            payload: error
        });
    }
};

export const createCheckoutOrderAction = (cartId: number, onSuccess?: Function, onFailure?: Function) => async (dispatch: GroguDispatch) => {
    try {
        dispatch({
            type: CREATE_CART_CHECKOUT_ORDER.LOADING
        });

        const res = await checkoutService.createCheckoutOrder(cartId);

        dispatch({
            type: CREATE_CART_CHECKOUT_ORDER.SUCCESS,
            payload: res.data?.data?.order
        });

        if (!!res.data?.data?.order && !!onSuccess) {
            onSuccess();
        } else if (!!onFailure) {
            onFailure();
        }
    } catch (error) {
        console.error({ error });
        dispatch({
            type: CREATE_CART_CHECKOUT_ORDER.FAILED,
            payload: error
        });

        if (!!onFailure) {
            onFailure();
        }
    }
};