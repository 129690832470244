import { ButtonClasses, ComponentsOverrides, Theme } from '@mui/material';
import { getColor } from './colors';
import { getStyle } from './fonts';

export const muiButtonOverrides = {
	styleOverrides: {
		root: (options: any) => ({
			...(options.ownerState.variant === 'contained' &&
				options.ownerState.color === 'primary' && {
					backgroundColor: getColor('Minty 0'),
					color: '#fff',
					borderRadius: '30px',
					border: `solid 1px ${getColor('Minty 0')}`,
					...getStyle('P_SemiBold'),
					':hover': {
						border: `solid 1px ${getColor('Minty 0')}`,
						backgroundColor: getColor(''),
						color: 'black',
					},
				}),
			...(options.ownerState.variant === 'contained' &&
				options.disabled && {
					backgroundColor: '#069BAA',
					opacity: 0.4,
          color: '#fff',
					borderRadius: '30px',
					border: `solid 1px ${getColor('')}`,
					...getStyle('P_SemiBold'),
					':hover': {
						border: `solid 1px ${getColor('')}`,
					},
				}),
			...(options.ownerState.variant === 'contained' &&
				options.ownerState.color === 'secondary' && {
					borderRadius: '30px',
					backgroundColor: getColor('white'),
					color: getColor('Minty 0'),
					border: `solid 1px ${getColor('Minty 0')}`,
					...getStyle('P_SemiBold'),
					':hover': {
						border: `solid 1px ${getColor('Minty 0')}`,
						color: 'white',
						backgroundColor: getColor('Minty 0'),
					},
				}),
		}),
	},
	defaultProps: {
		sx: {
			borderRadius: '30px',
			':hover': {
				background: 'white',
				color: getColor('Minty 0'),
			},
		},
	},
};
