import CheckedIcon from '@/assets/checked-box.svg';
import { getColor } from '@/utils/theme/colors';
import { Box } from '@mui/material';

type CheckboxProps = {
	checked: boolean;
	onChange?: (value: boolean) => void;
};

const Checkbox = (props: CheckboxProps) => {
	return (
		<Box
			className='cursor-pointer'
			onClick={() => props.onChange && props.onChange(!props.checked)}
		>
			{props.checked ? (
				<Box sx={{ width: '20px', height: '20px' }}>
					<CheckedIcon />
				</Box>
			) : (
				<Box>
					<Box
						sx={{
							width: '20px',
							height: '20px',
							border: `1px solid ${getColor('Gray -2')}`,
							borderRadius: '4px',
						}}
					></Box>
				</Box>
			)}
		</Box>
	);
};

export default Checkbox;
