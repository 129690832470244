type TRightArrowIconProps = {
	color?: string;
	hoverClass?: string;
	width?: number;
	height?: number;
};

const RightArrowIcon = (props: TRightArrowIconProps) => {
	const { color = 'white', width = 24, height = 24 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5 12.9453H19'
				stroke={color ?? 'white'}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
				className='right-arrow-icon'
			/>
			<path
				d='M12 5.94531L19 12.9453L12 19.9453'
				stroke={color ?? 'white'}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
				className='right-arrow-icon'
			/>
		</svg>
	);
};

export default RightArrowIcon;
