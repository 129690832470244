import { ApiState } from '@/models/generics';
import { SET_SELECTED_LOCATION, LOCATION_SELECTOR_TRIGGER_OPTIONS } from "./actionTypes";
import { getLocationFromLocalStorage } from '@/utils/location';


export type TLocation = {
    cityId?: number;
    pincode?: number;
    cityName: string;
};

export interface ILocationStoreState {
    selectedLocationSyncState: ApiState,
    selectedLocation: TLocation,
    isLocationSelectorOpen: boolean;

}


const initialState: ILocationStoreState = {
    selectedLocationSyncState: ApiState.LOADING,
    selectedLocation: {
        pincode: undefined,
        cityId: undefined,
        cityName: "",
    },
    isLocationSelectorOpen: false
};

export default function locationReducer(state = initialState, action: any): ILocationStoreState {
    switch (action.type) {
        case SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_SAVING:
            return {
                ...state,
                selectedLocationSyncState: ApiState.LOADING,
            };
        case SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_SUCCESS:
            return {
                ...state,
                selectedLocation: action.selectedLocation,
                selectedLocationSyncState: ApiState.COMPLETED,
            };
        case SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_FAILED:
            return {
                ...state,
                selectedLocationSyncState: ApiState.FAILED,
            };
        case LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_OPEN:
            return {
                ...state,
                isLocationSelectorOpen: true
            };
        case LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_CLOSE:
            return {
                ...state,
                isLocationSelectorOpen: false
            };
        default: return state;
    }
}