import { LencoTypography } from '@/atomic-components/LencoTypography';
import Auth from '.';
import { useState } from 'react';
import { getColor } from '@/utils/theme/colors';
import { useUser } from '@/hooks/useUser';
import { useLogin } from '@/hooks/useLogin';
import { useTheme } from '@mui/material';

type LoginTriggerButtonProps = {
	isLoggedIn:boolean;
};

const LoginTriggerButton = (props: LoginTriggerButtonProps) => {
	const {  loginActions } = useLogin();
	const theme = useTheme();

	function openAuthFlow() {
		loginActions.showLoginFlow();
	}
	function onLoginClicked() {
		console.log("login",props.isLoggedIn)
		if (!props.isLoggedIn) {

			openAuthFlow();
		}
	}

	return (
		<>
			<LencoTypography
				m='16px 0px'
				className='cursor-pointer'
				sx={{
					textDecoration: 'underline',
					'&:hover': {
						color: getColor('gray -5') 
					}
				}}
				onClick={onLoginClicked}
			>
				Login
			</LencoTypography>
		</>
	);
};

export default LoginTriggerButton;
