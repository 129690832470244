//@ts-nocheck

import { useUser } from '@/hooks/useUser';
import { useRouter } from 'next/router';
import { useEffect, useState, useContext } from 'react';
import React from 'react';

const YmContext = React.createContext({sdkLoaded: false});

const NonChatBotPages = ['/unlmtd/cart','/unlmtd/checkout/','/unlmtd/checkout/address','/unlmtd/checkout/payment'] 

export const YmProvider = ({ children }:any) => {
  const [sdkLoaded, setSdkLoaded] = useState(false);

  const {ghostUser,user} = useUser();

  const token = ghostUser?.ghost_identifier || user?.session_token?.token;
  
  const routeChangeHandler = (url:string) => {
    if (token !== undefined && token !== null) {
      // console.log('[yellow] token is', token);
      (window as any).YellowMessengerPlugin.init({
        ymAuthenticationToken: token,
      });
    }
    if(NonChatBotPages.includes(url)){
      console.log('[yellow] hiding chatbot');
      (window as any).YellowMessengerPlugin.hide();
    }
  };

  
  const router = useRouter();

  const unmountYellowMessenger = () => {
    // Check if the Yellow Messenger widget is initialized
    if (window.YellowMessenger) {
      console.log('[yellow] Unmounting Yellow Messenger');
      
      // Check if there's a destroy method to cleanly remove the widget
      if (typeof window.YellowMessenger === 'function') {
        window.YellowMessenger('destroy');
      }
      
      // Alternatively, remove all Yellow Messenger-related scripts and elements
      const yellowMessengerScript = document.querySelector('script[src*="yellowmessenger"]');
      if (yellowMessengerScript) {
        yellowMessengerScript.remove();
        console.log('[yellow] Yellow Messenger script removed');
      }
  
      // Optionally, clean up any divs or elements added by the widget
      const ymWidget = document.querySelector('.ym-embedded-widget');
      if (ymWidget) {
        ymWidget.remove();
        console.log('[yellow] Yellow Messenger widget DOM removed');
      }
    } else {
      console.log('[yellow] Yellow Messenger not initialized, nothing to unmount');
    }
  };
  

  useEffect(() => {
    if(sdkLoaded){
      
      if(NonChatBotPages.includes(router?.pathname)){

        (window as any).YellowMessengerPlugin.hide();
      }
      else{
      routeChangeHandler(router.pathname)

      } 
    }

 
  }, [router.pathname, sdkLoaded, token]);


  useEffect(() => {
    if (typeof window !== 'undefined') {
      const ymConfig = {
        bot: 'x1666161086114',
        host: 'https://cloud.yellow.ai',
      };
      window.ymConfig = ymConfig;
      (function () {
        console.log('[yellow use effect to intialise sdk firing]')
        var w = window,
          ic = w.YellowMessenger;
        if ('function' === typeof ic)
          ic('reattach_activator'), ic('update', ymConfig);
        else {
          var d = document,
            i = function (...args: any[]) {
              i.c(args);
            };
          i.q = [];
          i.c = function (e: any) {
            i.q.push(e);
          };

          function l() {
            var e = d.createElement('script');
            (e.type = 'text/javascript'),
              (e.async = !0),
              (e.src =
                'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js');
            var t = d.getElementsByTagName('script')[0];
            if (t.parentNode) {
              t.parentNode.insertBefore(e, t);
            }
            e.onload = () => {
              console.log('[yellow] sdk loaded successfullay');
              setSdkLoaded(true);
            };
          }
          (w.YellowMessenger = i),
            l();
        }
      })();
    }
  }, [router?.pathname]);

  return <YmContext.Provider value={{ sdkLoaded }}>{children}</YmContext.Provider>;
};

export const useYmChat = () => useContext(YmContext);

