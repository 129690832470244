import { createTheme } from '@mui/material/styles';
import { muiButtonOverrides } from './button';
import { getColor } from './colors';
import { typoGraphyConfig, variantMapping } from './fonts';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1440,
    }
  },
  palette: {
    primary: {
      main: getColor('Minty 0'),
    },
  },
  typography: {
    fontFamily: ['Work Sans', 'Recline'].join(','),
    fontSize: 12,
    ...typoGraphyConfig,
  },
  components: {
    MuiCircularProgress: {
      defaultProps: {
        size: 20,
      }
    },
    MuiButton: muiButtonOverrides,
    MuiTypography: {
      defaultProps: {
        variantMapping,
      },
    },
  },
});

theme.spacing(2.5);

export default theme;
