import Checkbox from '@/atomic-components/Checkbox';
import FlexBox from '@/atomic-components/FlexBox';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { Box } from '@mui/material';
import WhatsappSvg from '@/assets/Whatsapp.svg';
import { useState } from 'react';

export enum WhatsappConsentStatusValues {
	CONSENTED = 'CONSENTED',
	NOT_CONSENTED = 'NOT_CONSENTED',
}

interface WhatsappConsentSelectionProps {
	defaultValue?: WhatsappConsentStatusValues;
	onUpdate?: (value: WhatsappConsentStatusValues) => void;
}

export const WhatsappConsentSelection = (
	props: WhatsappConsentSelectionProps
) => {
	const [consentValue, setConsentValue] = useState<WhatsappConsentStatusValues>(
		props.defaultValue ?? WhatsappConsentStatusValues.CONSENTED
	);

	const onChange = () => {
		if (consentValue == WhatsappConsentStatusValues.CONSENTED) {
			setConsentValue(WhatsappConsentStatusValues.NOT_CONSENTED);
			props.onUpdate &&
				props.onUpdate(WhatsappConsentStatusValues.NOT_CONSENTED);
		} else {
			setConsentValue(WhatsappConsentStatusValues.CONSENTED);
			props.onUpdate && props.onUpdate(WhatsappConsentStatusValues.CONSENTED);
		}
	};

	return (
		<Box>
			<FlexBox mt='24px' alignItems='center'>
				<Box mr='8px'>
					<Checkbox
						checked={consentValue == WhatsappConsentStatusValues.CONSENTED}
						onChange={onChange}
					/>
				</Box>
				<LencoTypography mr='4px' sx={{ lineHeight: 1 }}>
					Get order updates on
				</LencoTypography>
				<WhatsappSvg />
				<LencoTypography ml='4px' sx={{ lineHeight: 1 }}>
					Whatsapp
				</LencoTypography>
			</FlexBox>
		</Box>
	);
};
