import { unlmtdPlanService } from '@/services/unlmtd/plan';
import {
	getPlanFulfilled,
	getPlanPending,
	getPlanRejected,
	PlanSelectorStatus,
	showOrHidePlanSelectorSlice,
	setSelectedPlanIdInStore,
} from '@/store/unlmtd/slices/planConfigSlice';
import { deleteKeyFromLocalStorage, getDataFromLocalStorage } from '@/utils/storage';
import { useDispatch, useSelector } from 'react-redux';

type PlanHooks = {
	selectedPlanId: any;
	plans: any;
	currentPlan: any;
	planSelectorStatus: any;
	planActions: {
		getPlans: any;
		setPlan: any;
		removePlan: any;
		getPlanApiState: any;
		showOrHidePlanSelector: any;
	};
};

const usePlan = (): PlanHooks => {
	const plan = useSelector((state: any) => state?.plans);
	const currentselectedPlan = useSelector(
		(state: any) => state?.plans?.selectedPlanId
	);
	const setSelectedPlanId = (id: string | null) => {
		dispatch(setSelectedPlanIdInStore(id));
	};

	const selectedPlanId =
		currentselectedPlan ||
		(getDataFromLocalStorage('selectedPlan') as string) ||
		null;

	const planSelectorStatus = useSelector(
		(state: any) => state?.plans?.planSelectorStatus
	);

	let currentPlan;


	

	const dispatch = useDispatch();

	const getPlanApiState = () => {
		return plan?.status;
	};

	const refetchPlanId = () => {
		setSelectedPlanId(
			(getDataFromLocalStorage('selectedPlan') as string) || '0'
		);
	};

	const getPlans = async () => {
		dispatch(getPlanPending());
		try {
			const response = await unlmtdPlanService.getPlans(
				selectedPlanId as string
			);
			
			dispatch(getPlanFulfilled(response.data?.data?.plans));
			currentPlan = response.data?.data?.plans?.filter(
				(plan: any) => plan.id == selectedPlanId
			)[0];
		} catch (error: any) {
			dispatch(getPlanRejected(error.toString()));
		}
	};

	const setPlan = (planId: string) => {
		setSelectedPlanId(planId);
		localStorage.setItem('selectedPlan', planId);
	};

	const removePlan = () => {
		deleteKeyFromLocalStorage('selectedPlan');
		setSelectedPlanId(null);
	};

	const showOrHidePlanSelector = (action: keyof typeof PlanSelectorStatus) => {
		dispatch(showOrHidePlanSelectorSlice(action));
	};


	if(!getDataFromLocalStorage('selectedPlan')){
		setSelectedPlanId(null);
		showOrHidePlanSelector(PlanSelectorStatus.PLAN_SHOW);
	}

	return {
		selectedPlanId,
		plans: plan?.plans,
		currentPlan,
		planSelectorStatus,
		planActions: {
			getPlans,
			setPlan,
			removePlan,
			getPlanApiState,
			showOrHidePlanSelector,
		},
	};
};

export default usePlan;
