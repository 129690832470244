import Hoverable from '@/atomic-components/Hoverable';
import CartIconSvg from '@/assets/unlmtd/Cart/CartIcon.svg';
// import CartIconHoveredSvg from '@/assets/cart/CartIconHovered.svg';
// import CartIconClickedSvg from '@/assets/cart/CartIconClicked.svg';

type CartIconProps = React.HTMLAttributes<any> & {
	onClick?: () => void;
};

const CartIcon = (props: CartIconProps) => {
	function onClick() {
		if (typeof props.onClick == 'function') {
			return props.onClick();
		}
	}

	return (
		<Hoverable
			onClick={onClick}
			icons={{
				before: <CartIconSvg />,
				// after: <CartIconHoveredSvg />,
				// clicked: <CartIconClickedSvg />,
			}}
			id='header-cart-icon'
			className='move-up-transition'
		>
			{props.children}
		</Hoverable>
	);
};

export default CartIcon;
