import BaseService from '@/services/base-service';
import { TCheckPincodeServiceabilityApiResponse } from './models/check-pincode-serviceability';
import { TGetServiceableCitiesApiResponse } from './models/get-serviceable-cities';
import { TGetMMITokenApiResponse } from './models/map-my-india-token';

class LocationService extends BaseService {
  constructor() {
    super({
      serviceName: "Location",
    });
  }

  public getServiceableCitiesService(): Promise<TGetServiceableCitiesApiResponse> {
    return this.client.get('/location/serviceable-cities', { withCredentials: false });
  }

  public getPincodeServiceabilityService(pincode: number): Promise<TCheckPincodeServiceabilityApiResponse> {
    return this.client.get(`/location/pincodes/${pincode}`);
  }

  public getMMIToken(): Promise<TGetMMITokenApiResponse> {
    return this.client.get('/tokens/mapmyindia');
  }
}

export const locationService = new LocationService();
