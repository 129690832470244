import { Plan, PlanData } from "../models/plan";
import { ApiState } from '../../../models/generics';
import { createSlice } from "@reduxjs/toolkit";
type PlanState= {
	planSelectorStatus: keyof typeof PlanSelectorStatus;
    plans: Plan[] | []
	status: ApiState;
	error: string;
	selectedPlanId: string | null;
}

export enum PlanSelectorStatus {
	PLAN_SHOW = 'PLAN_SHOW',
	PLAN_HIDE = 'PLAN_HIDE',
}

const initialState: PlanState = {
	planSelectorStatus: PlanSelectorStatus.PLAN_HIDE,
	plans: [],
	status: ApiState.IDLE,
	error: '',
	selectedPlanId: null

};

const planSlice = createSlice({
	name: 'plans',
	initialState,
	reducers: {
		getPlanPending: (state) => {
			state.status = ApiState.LOADING;
		},
		getPlanFulfilled: (state, action) => {
			state.status = ApiState.SUCCESS;
			state.plans = action.payload;
			state.error = '';
		},
		getPlanRejected: (state, action) => {
			state.status = ApiState.FAILED;
			state.error = action.payload as string;
		},
		showOrHidePlanSelectorSlice: (state,action) => {
			state.planSelectorStatus = PlanSelectorStatus[action.payload as keyof typeof PlanSelectorStatus];
		},
		setSelectedPlanIdInStore: (state, action) => {
			state.selectedPlanId = action.payload;
		}
	},
});

export const {
	getPlanPending,
	getPlanFulfilled,
	getPlanRejected,
	showOrHidePlanSelectorSlice,
	setSelectedPlanIdInStore
} = planSlice.actions;

export default planSlice.reducer;
