import { unlmtdSubscriptionService } from "@/services/unlmtd/subscription";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionRejected, getSusbcriptionPending,getSubscriptionFulfilled } from '@/store/unlmtd/slices/subscriptionSlice'
import { useRef } from "react";


const useSubscription = () => {
    // State variables
	const isFetching = useRef(false);

    const dispatch = useDispatch();
    const subscription = useSelector((state:any) => state?.subscription?.subscription);
    const subscriptionStatus = useSelector((state:any) => state?.subscription?.subscriptionStatus);

    
    const getSubscription = ()=>{
		if (isFetching.current) return;
        isFetching.current = true;
        dispatch(getSusbcriptionPending());
        unlmtdSubscriptionService.getSubscriptions().then((response)=>{
            console.log(response.data.data)
            dispatch(getSubscriptionFulfilled(response.data.data))
        })
        .catch((error)=>{
            dispatch(getSubscriptionRejected())
        }).finally(()=>{
            isFetching.current = false;
        })
    }
    const clearSubscription = ()=>{
        dispatch(getSubscriptionRejected())
    }

    return { 
        subscription,
        subscriptionStatus ,
        subscriptionActions:{
            getSubscription,
            clearSubscription
        }
    };
};

export default useSubscription;