import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import UserData, { TUserState } from './user/reducer';
import locationReducer, { ILocationStoreState } from './location/reducer';
import WishlistsReducer, { IWishlistStore } from './wishlist/reducer';
import cartReducer, { TCartState } from './cart/reducer';
import SeoState from '@/store/seo/reducer';
import HaptikReducer from '@/store/haptik/reducer';
import AppConfigReducer, { AppConfig } from '@/store/appConfig/reducer';

export interface IStoreState {
  user: TUserState,
  location: ILocationStoreState;
  cart: TCartState;
  wishlist: IWishlistStore;
  seoData: {
    data: {
      title: string;
      description: string;
    };
  };
  routerConfig: {
    isHaptikEnabled: boolean;
  };
  appConfig: AppConfig;
}

export function makeStore() {
  return configureStore({
    reducer: { user: UserData, cart: cartReducer, location: locationReducer, wishlist: WishlistsReducer, seo: SeoState, routerConfig: HaptikReducer, appConfig: AppConfigReducer },
  });
}

const store = makeStore();

export type TAppDispatch = typeof store.dispatch;

export type GroguState = ReturnType<typeof store.getState>;

export type GroguDispatch = typeof store.dispatch;

export type GroguThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  GroguState,
  unknown,
  Action<string>
>;

export default store;

