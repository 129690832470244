import { TCallbacks } from '@/models/generics';
import {authService} from '@/services/auth';
import { metaService } from '@/services/meta';
import { userService } from '@/services/user';
import { deleteKeyFromLocalStorage, setDataToLocalStorage } from '@/utils/storage';
import { setUser } from '@sentry/nextjs';
import store from '..';
import { TLocation } from '../location/reducer';

import { GET_PROFILE_MENU, GET_META, LOGOUT_USER, GET_USER_ADDRESSES, CREATE_USER_ADDRESS } from './actionTypes';
import { getUserCartAction } from '../cart/actions';
import { getWishlistsAction } from '../wishlist/action';
import { GET_WISHLISTS } from '../wishlist/actionTypes';
import { useTheme } from '@mui/material';
import { useCart } from '@/hooks/unlmtd/useCart';

export const getMetaAction = (options?: TLocation, callback?: Function) => async (dispatch: any) => {
    try {
        dispatch({
            type: GET_META.LOADING,
        });


        const res = await metaService.getMeta(options);

        const panemUser = res.data.data?.meta?.user;
        const ghostUser = res.data.data?.meta?.ghost_user;

        dispatch({
            type: GET_META.SUCCESS,
            payload: res.data?.data?.meta
        });
        const isLoggedIn = !!panemUser?.id;

        setUser({
            id: isLoggedIn ? panemUser?.id?.toString() : ghostUser?.id?.toString(),
            email: panemUser?.email_id,
            username: panemUser?.first_name
        });

       

        dispatch(getUserCartAction());
        dispatch(getUserProfileMenu());

        if (isLoggedIn) {
            if(window?.location?.pathname.includes('unlmtd')){
                dispatch(getWishlistsAction({ lineOfProduct: 'UNLMTD' }));
            }
            else{
                dispatch(getWishlistsAction());
            }
        }

        if (!!callback) {
            callback();
        }
    } catch (error) {
        dispatch({
            type: GET_META.FAILED,
            payload: error
        });
    }
};



export const getUserProfileMenu = () => async (dispatch: any) => {
    try {
        dispatch({
            type: GET_PROFILE_MENU.LOADING,
        });

        const res = await userService.getUserProfileMenu();

        dispatch({
            type: GET_PROFILE_MENU.SUCCESS,
            payload: res.data?.data
        });
    } catch (error) {
        dispatch({
            type: GET_PROFILE_MENU.FAILED,
            payload: error,
        });
    }
};

export const logOutUserAction = () => async (dispatch: any) => {
    try {
        dispatch({
            type: LOGOUT_USER.LOADING,
        });

        const res = await authService.logOutUser();
        const data = res?.data?.data;

        dispatch({
            type: LOGOUT_USER.SUCCESS,
            payload: res.data?.data
        });

        dispatch(getMetaAction());
        dispatch({
            type: GET_WISHLISTS.SUCCESS,
            payload: undefined,
            wishlistedProductsMap: {}
        });
    } catch (error) {

        dispatch({
            type: LOGOUT_USER.FAILED,
            payload: error,
        });
    }
};

export const getUserAddressesAction = () => async (dispatch: any) => {
    try {
        dispatch({
            type: GET_USER_ADDRESSES.LOADING,
        });

        const res = await userService.getAddress();
        const data = res?.data?.data;
        
        dispatch({
            type: GET_USER_ADDRESSES.SUCCESS,
            payload: data
        });
    } catch (error) {

        dispatch({
            type: GET_USER_ADDRESSES.FAILED,
            payload: error,
        });
    }
};

export const createUserAddressesAction = (payload: any, callbacks?: TCallbacks) => async (dispatch: any) => {
    try {
        dispatch({
            type: CREATE_USER_ADDRESS.LOADING,
        });

        const res = await userService.addAddress(payload);

        if (res.data?.success) {
            dispatch({
                type: CREATE_USER_ADDRESS.SUCCESS,
            });
        } else {
            dispatch({
                type: CREATE_USER_ADDRESS.FAILED,
            });
        }
    } catch (error) {
        dispatch({
            type: CREATE_USER_ADDRESS.FAILED,
            payload: error,
        });
    }

    if (callbacks?.finally) {
        callbacks?.finally();
    }
};
