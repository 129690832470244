import Hoverable from '@/atomic-components/Hoverable';
import AccountIconSvg from '@/assets/account/AccountIcon.svg';
import AccountIconHoveredSvg from '@/assets/account/AccountIconHovered.svg';
import AccountIconClickedSvg from '@/assets/account/AccountIconClicked.svg';

type AccountIconProps = {
	onClick?: () => void;
};

const AccountIcon = (props: AccountIconProps) => {
	function onClick() {
		if (typeof props.onClick == 'function') {
			return props.onClick();
		}
	}

	return (
		<Hoverable
			onClick={onClick}
			icons={{
				before: <AccountIconSvg />,
				after: <AccountIconHoveredSvg />,
				clicked: <AccountIconClickedSvg />,
			}}
		></Hoverable>
	);
};

export default AccountIcon;
