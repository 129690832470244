import FlexBox from '@/atomic-components/FlexBox';
import GlobalHeader from '@/components/Headers/GlobalHeader';
import { Box } from '@mui/material';

type TBaseLayoutProps = {
	children?: React.ReactNode;
};

const BaseLayout = (props: TBaseLayoutProps) => {
	return (
		<Box className="sf-ym">
			<GlobalHeader />
			<Box
				id='main'
				sx={{
					marginTop: '110px',
				}}
			>
				{props.children}
			</Box>
		</Box>
	);
};

export default BaseLayout;
