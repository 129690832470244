export enum LocalStorageKeys {
  SELECTED_CITY_NAME = "selectedCityName",
  SELECTED_CITY_ID = "x-city-id",
  SELECTED_PINCODE = "x-pincode",
  SELECTED_LOCATION = "location"
}

export const getDataFromLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }
  return null;
};

export const setDataToLocalStorage = (key: string, value: any) => {
  if (typeof window !== 'undefined') {
    return localStorage.setItem(key, value);
  }
};

export const deleteKeyFromLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.removeItem(key);
  }
};
