import { useLencoSelector } from '@/hooks/useLencoSelector';
import { ApiState } from '@/models/generics';
import { TGhostUser, TPanemUser } from '@/services/meta/models/meta';
import { TGetAddressesApiResponseData } from '@/services/user/models/addresses';
import { ProfileMenuApiResponseData } from '@/services/user/models/profile-menu.types';
import { TLocation } from '@/store/location/reducer';
import {
	getMetaAction,
	getUserAddressesAction,
	getUserProfileMenu,
	logOutUserAction,
} from '@/store/user/action';
import { useLencoDispatch } from './useLencoDispatch';
import { useCart } from './unlmtd/useCart';
import { useTheme } from '@mui/material';
import usePlan from './unlmtd/usePlan';

type TUseUser = {
	getUserApiState: ApiState;
	getAddressessApiState: ApiState;
	ghostUser?: TGhostUser;
	user?: TPanemUser;
	isLoggedIn: boolean;
	loadingProfileMenu: boolean;
	addresses?: TGetAddressesApiResponseData;
	profileMenu?: ProfileMenuApiResponseData;
	userActions: {
		getUser: (location?: TLocation) => Promise<any>;
		logoutUser: () => Promise<any>;
		getProfileMenu: () => Promise<any>;
		getAddresses: (callback: () => void) => void;
	};
};

export function useUser(): TUseUser {
	const {
		user,
		ghostUser,
		profileMenu,
		addresses,
		getUserApiState,
		getAddressessApiState,
		loadingProfileMenu,
	} = useLencoSelector((store) => ({
		user: store?.user?.user,
		ghostUser: store?.user?.ghostUser,
		profileMenu: store.user.profileMenu,
		addresses: store.user.addresses,
		getUserApiState: store.user.getUserApiState,
		getAddressessApiState: store.user.getAddressessApiState,
		loadingProfileMenu: store.user.loadingProfileMenu,
	}));


	const {planActions} = usePlan();
	const dispatch = useLencoDispatch();
	const unlmtdCart=useCart();
	const theme = useTheme();
	const logoutUser = () => dispatch(logOutUserAction()).then(()=>{
		// if(theme?.name == 'UNLMTD'){
			unlmtdCart?.cartActions?.clearCart();

		// }
	});
	const getUser = () => dispatch(getMetaAction()).then(()=>{
			unlmtdCart?.cartActions?.fetchCart();
			planActions.getPlans();

	});
	const getAddresses = () => dispatch(getUserAddressesAction);
	const getProfileMenu = () => dispatch(getUserProfileMenu());

	const isLoggedIn = !!user?.id;

	return {
		getUserApiState,
		getAddressessApiState,
		user,
		ghostUser,
		isLoggedIn,
		profileMenu,
		addresses,
		loadingProfileMenu,
		userActions: {
			getUser,
			logoutUser,
			getAddresses,
			getProfileMenu,
		},
	};
}
