import { useLencoSelector } from '@/hooks/useLencoSelector';
import { useLencoDispatch } from './useLencoDispatch';
import { NotifyToastProps } from '@/components/NotifyToast';
import { NOTIFCATION_TOAST } from '@/store/appConfig/actionTypes';

type UseNotification = {
	notifications: NotifyToastProps[];
	actions: {
		notifyUser: (toast: NotifyToastProps) => void;
		closeNotification: (id: string) => void;
	};
};

export function useNotification(): UseNotification {
	const dispatch = useLencoDispatch();

	const { notifications } = useLencoSelector((store) => ({
		notifications: store.appConfig.notifications,
	}));

	function notifyUser(toast: NotifyToastProps) {
		const newToast: NotifyToastProps = {
			...toast,
			id: toast.id ?? crypto.randomUUID(),
		};
		dispatch({
			type: NOTIFCATION_TOAST.ADD_TOAST,
			payload: newToast,
		});
	}

	function closeNotification(id: string) {
		const updatedNotifications = [...notifications].filter(
			(notification) => notification.id != id
		);

		dispatch({
			type: NOTIFCATION_TOAST.REMOVE_TOAST,
			payload: updatedNotifications,
		});
	}

	return {
		notifications,
		actions: {
			notifyUser,
			closeNotification,
		},
	};
}
