export const toHHMMSS = (sec: number): string => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - (hours * 3600)) / 60);
  const seconds = Math.floor(sec - (hours * 3600) - (minutes * 60));
  return `${minutes > 0 ? `${minutes} min` : '00:'} ${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const startCounter = (timeToWait: number, counter: string, setTimeLeftToResendOtp: Function, timeOverCallback: () => void) => {

  let remainingTime = timeToWait * 60 - 1;
  const timeInterval = setInterval(() => {
    if (remainingTime == -1) {
      timeOverCallback();
      clearInterval(timeInterval);

    } else if (counter) {
      // counterRef.current.innerText = toHHMMSS(remainingTime);
      if (remainingTime < 10) {
        setTimeLeftToResendOtp(`00:0${remainingTime}`);
      }
      else {
        setTimeLeftToResendOtp(`00:${remainingTime}`);
      };
      remainingTime--;
    }
  }, 1000);
  return timeInterval;
};