import { Box, SxProps } from '@mui/material';
import { CSSProperties, DOMAttributes, memo, useState } from 'react';

type HoverableProps = React.HTMLAttributes<any> & {
	icons: { before: any; after?: any; clicked?: any };
	onClick?: () => void;
	sx?: SxProps;
	active?: boolean;
};

const Hoverable = (props: HoverableProps) => {
	const { before, after, clicked } = props.icons ?? {};
	const [visibleIcon, setVisibleIcon] = useState<any>(before);

	const onMouseEnter = () => {
		if (!after) return;

		setVisibleIcon(after);
	};

	const onMouseLeave = () => {
		setVisibleIcon(before);
	};

	const onClick = () => {
		if (!clicked) return;
		setVisibleIcon(clicked);

		if (typeof props.onClick == 'function') {
			props.onClick();
		}
	};

	return (
		<Box
			{...props}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
			className={`hoverable ${props.className ?? ''}`}
			sx={{
				...props.sx,
			}}
		>
			{props.active ? after : visibleIcon}
			{props.children}
		</Box>
	);
};

export default memo(Hoverable);
