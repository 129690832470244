import { getColor } from "../colors";
import { getStyle } from "../fonts";

export const getRootStyles = ({ ownerState }: { ownerState: any }) => {
    const baseStyle = {
      width: '100%',
      height: '48px',
      background: 'transparent',
      borderRadius: '30px',
      lineHeight: '16px',
    };
  
    let variantStyle = {};
  
    if (ownerState.variant === 'contained' && ownerState.color === 'primary') {
      variantStyle = {
        backgroundColor: getColor('Minty 0'),
        color: '#fff',
        ...getStyle('P-SemiBold'),
      };
    } else if (ownerState.variant === 'outlined' && ownerState.color === 'primary') {
      variantStyle = {
        border: `1px solid ${getColor('Minty 0')}`,
        color: getColor('Minty 0'),
        ...getStyle('P-SemiBold'),
      };
    } else if (ownerState.variant === 'contained' && ownerState.color === 'error') {
      variantStyle = {
        backgroundColor: getColor('Danger -1'),
        ...getStyle('P-SemiBold'),
      };
    }
  
    return {
      ...baseStyle,
      ...variantStyle,
    };
  };
  
  