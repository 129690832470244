import FlexBox from '@/atomic-components/FlexBox';
import { Box, TextField } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { getColor } from '@/utils/theme/colors';
import IndiaFlag from '@/assets/auth/india-flag.svg';
import RightArrowIcon from '@/assets/RightArrowIcon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import LencoButton from '@/atomic-components/LencoButton';
import { ApiState } from '@/models/generics';
import { dataLayerPushWithEvent } from '@/utils/dataLayer';

type VerifyPhoneNumberProps = {
	verifyingAccount: boolean;
	loginError: string;
	loginErrorCode: string;
	userDetails: any;
	values: any;
	errors: any;
	handleChange: any;
	handleSubmission: any;
	setPage: Function;
	viewState: ApiState;
};

const VerifyPhoneNumber = (props: VerifyPhoneNumberProps) => {
	const inputMobileRef = useRef<HTMLInputElement>(null);
	const [open, setOpen] = useState(true);
	// const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		if (inputMobileRef.current) {
			inputMobileRef.current.focus();
		}
	}, [inputMobileRef.current]);

	const changeHandler = (e: any) => {
		const { value } = e.target;
		const firstNumberFormat = /^[6789]/;
		const numberFormat = /\d+$/;

		if (
			(value.length === 1 && value.match(firstNumberFormat)) ||
			value === ''
		) {
			props.handleChange(e);
		} else if (
			(value.length !== 1 && value.match(numberFormat)) ||
			value === ''
		) {
			props.handleChange(e);
		}
	};

	const isDisabled = useMemo(
		() =>
			(!props.errors?.mobile && props.values?.mobile?.length != 10) ||
			props.verifyingAccount ||
			props.values?.mobile?.length != 10,
		[props.errors?.mobile, props.values?.mobile, props.verifyingAccount]
	);

	const border = useMemo(
		() =>
			props.errors?.mobile ? `1px solid ${getColor('Danger +1')}` : 'none',
		[props.errors?.mobile]
	);

	const router = useRouter();

	const buttonBgColor = isDisabled ? 'Gray -5' : 'Minty +1';

	useEffect(() => {
		if (router.query.mobile) {
			props.handleSubmission();
		}
	}, [router.query]);

	dataLayerPushWithEvent('moe_event', {
		moe_event_name: 'Login Page Opened',
	});

	return (
		<FlexBox direction='column'>
			<LencoTypography
				sx={{
					fontWeight: '500',
					fontSize: '36px',
					fontStyle: 'italic',
					fontFamily: 'Recline',
					color: '#303133',
				}}
			>
				Let’s
			</LencoTypography>
			<LencoTypography
				sx={{
					fontWeight: '500',
					fontSize: '36px',
					fontStyle: 'italic',
					fontFamily: 'Recline',
					color: '#303133',
				}}
			>
				get{' '}
				<LencoTypography
					component='span'
					sx={{
						fontWeight: '500',
						fontSize: '36px',
						fontStyle: 'italic',
						fontFamily: 'Recline',
						color: getColor('Minty 0'),
					}}
				>
					Started
				</LencoTypography>
			</LencoTypography>
			<LencoTypography
				variant='P_Medium'
				color={getColor('Gray -3')}
				sx={{ marginTop: '24px', marginBottom: '24px' }}
			>
				Enter your phone number to proceed
			</LencoTypography>
			<form
				style={{ width: '100%' }}
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<Box
					sx={{
						padding: '20px 30px',
						borderRadius: '8px',
						background: '#F3F3F3',
						border: `${border}`,
					}}
				>
					<TextField
						onKeyUp={(e) => {
							if (e.keyCode == 13) {
								props.handleSubmission(e);
							}
						}}
						sx={{
							width: '100%',
							input: {
								'&::placeholder': {
									color: getColor('Gray -3'),
									// variant: 'H14_Medium',
									fontWeight: '500',
									fontSize: '12px',
									fontFamily: 'Work Sans',
								},
							},
						}}
						name='mobile'
						placeholder='Mobile Number'
						variant='standard'
						autoComplete='off'
						inputRef={inputMobileRef}
						onChange={changeHandler}
						value={props.values?.mobile ? props.values.mobile : ''}
						InputProps={{
							disableUnderline: true,
							type: 'tel',
							startAdornment: (
								<FlexBox mr='12px' alignItems={'center'}>
									<IndiaFlag />
									<FlexBox ml='8px' sx={{ transform: 'rotate(90deg)' }}>
										{/* <RightArrowIcon color={getColor('Gray -2')} /> */}
									</FlexBox>
								</FlexBox>
							),
						}}
						inputProps={{
							minLength: 10,
							maxLength: 10,
						}}
					></TextField>
				</Box>
				{props.loginError && (
					<LencoTypography>{props.loginError}</LencoTypography>
				)}
				{props.errors && (
					<LencoTypography
						sx={{
							paddingTop: '8px',
							color: getColor('Danger +1'),
						}}
					>
						{props.errors.mobile}
					</LencoTypography>
				)}
				<Box mt='24px'>
					<LencoButton
						onClick={props.handleSubmission}
						fullWidth
						variant='contained'
						sx={{ padding: '16px 48px' }}
						isLoading={props.viewState == ApiState.LOADING}
					>
						<LencoTypography mr='10px'>CONFIRM & CONTINUE</LencoTypography>
						<RightArrowIcon />
					</LencoButton>
				</Box>
			</form>
		</FlexBox>
	);
};

export default VerifyPhoneNumber;
