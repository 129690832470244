import { LencoImage } from '@/atomic-components/Image';
import { TCity } from '@/models/location';
import { getColor } from '@/utils/theme/colors';
import { Box, useTheme } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import FlexBox from '@/atomic-components/FlexBox';
import { getDataFromLocalStorage } from '@/utils/storage';

type TCityIconProps = {
	city: TCity;
	onClick: (cityId: TCity) => void;
};

const City = (props: TCityIconProps) => {
	function onClick() {
		props.onClick(props.city);
	}
	const theme=useTheme()
	const isSelectedLocation = getDataFromLocalStorage('location');
	const selected = isSelectedLocation ? JSON.parse(isSelectedLocation) : null;
	const isCitySelected = selected?.cityId === props.city.id;

	return (
		<Box className='cursor-pointer' width='80px' m='16px' onClick={onClick} >
			<FlexBox sx={{width:'80px',height:'100%',flexDirection:'column'}}>
			<Box
			sx={{
				background:isCitySelected?theme?.colors?.lencoSecondaryN3 :'linear-gradient(180deg, #6653A4 0%, #B6A8E1 100%)',
				padding:'18px 14px 0 14px',
				borderRadius:'12px',
				overflow:'hidden',
				flexDirection:'column',
				justifyContent:'flex-end',
				alignItems:'center',
				width: '100%',
				height: 'auto',
				marginBottom:'auto',
				':hover':{
					background:theme?.colors?.lencoSecondaryN3
				}
			}}
			>
			<LencoImage aspectRatio={1} url={props.city.unlmtd_icon_url}
			lazy={false} imageCss={{height:'100%'}}
			></LencoImage>
			</Box>	
			
			<LencoTypography
				color={theme?.colors?.lencoPrimaryN6}
				variant='P_Medium'
				mt='10px'
				alignItems='center'
				textAlign='center'
			>
				{props.city.name}
			</LencoTypography>
			</FlexBox>
		</Box>
	);
};

export default City;
