import FlexBox from '@/atomic-components/FlexBox';
import { Box, Skeleton } from '@mui/material';

const HeaderShimmer = () => (
	<FlexBox justifyContent='center' mt='28px'>
		<Skeleton width='140px' height='32px' />
	</FlexBox>
);

const CityShimmer = () => (
	<FlexBox direction='column' alignItems='center'>
		<Skeleton
			variant='rectangular'
			animation='wave'
			height={'68px'}
			width={'80px'}
			sx={{ margin: '0 20px', borderRadius: '16px' }}
		/>
		<Skeleton
			variant='rectangular'
			animation='wave'
			height={'14px'}
			width={'60px'}
			sx={{ margin: '0 20px', borderRadius: '8px', mt: '10px' }}
		/>
	</FlexBox>
);

const LocationSelectorShimmer = () => {
	return (
		<Box>
			<HeaderShimmer />
			<FlexBox mt='16px'>
				<CityShimmer />
				<CityShimmer />
				<CityShimmer />
			</FlexBox>
			<FlexBox mt='32px'>
				<CityShimmer />
				<CityShimmer />
				<CityShimmer />
			</FlexBox>
			<FlexBox mt='32px'>
				<CityShimmer />
				<CityShimmer />
				<CityShimmer />
			</FlexBox>
		</Box>
	);
};

export default LocationSelectorShimmer;
