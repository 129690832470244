import { ApiState } from '@/models/generics';
import {
	saveUserSelectedLocationAction,
	triggerCitySelection,
} from '@/store/location/actions';
import { useLencoDispatch } from './useLencoDispatch';
import { useLencoSelector } from './useLencoSelector';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import { TLocation } from '@/store/location/reducer';
import { locationService } from '@/services/location';
import { useUser } from './useUser';
import { useCart as unlmtdUseCard } from './unlmtd/useCart';
import { useCart } from './useCart';
import { useEffect } from 'react';
import { useNotification } from './useNotification';
import { NotificationSeverity } from '@/components/NotifyToast';
import { useRouter } from 'next/router';
import { getCityFromUrl } from '@/utils/location';
import usePlan from './unlmtd/usePlan';

type TUseLocation = {
	/** @deprecated */
	pincode?: number;
	/** @deprecated */
	cityId?: number;
	/** @deprecated */
	cityName?: string;
	selectedLocation: TLocation;
	selectedLocationSyncState: ApiState;
	locationActions: {
		triggerCitySelector: (type: LOCATION_SELECTOR_TRIGGER_OPTIONS) => void;
		updateSelectedLocation: (location: TLocation) => void;
		updateSelectedLocationAndNotify: (location: TLocation) => void;
		getPincodeDetails: (pincode: number) => void;
	};
	isLocationSelectorOpen: boolean;
};

export function useLocation(): TUseLocation {
	const router = useRouter();
	const {
		selectedLocation,
		selectedLocationSyncState,
		isLocationSelectorOpen,
	} = useLencoSelector((state) => state.location);
	const {
		userActions: { getUser },
	} = useUser();
	const {
		cartActions: { getCart },
	} = useCart();
	const {
		cartActions: { fetchCart ,clearCart},
	} = unlmtdUseCard();
	const dispatch = useLencoDispatch();
	const { actions } = useNotification();
	const { planActions } = usePlan();

	function updateSelectedLocation(location: TLocation) {
		dispatch(saveUserSelectedLocationAction(location));
		clearCart();	
		if (location?.pincode != selectedLocation?.pincode) {
			getUser();
			getCart();
			fetchCart();
		}
		const pathCity = getCityFromUrl();
		if (pathCity && typeof window != 'undefined') {
			const updatedUrl = window.location.href.replaceAll(
				pathCity,
				location.cityName.toLowerCase()
			);
			router.replace(updatedUrl);
		}
		// planActions.getPlans();
	}

	function updateSelectedLocationAndNotify(location: TLocation) {
		updateSelectedLocation(location);

		actions.notifyUser({
			message: 'Location has been updated',
			severity: NotificationSeverity.SUCCESS,
		});
	}

	function getPincodeDetails(pincode: number) {
		return locationService.getPincodeServiceabilityService(pincode);
	}

	const triggerCitySelector = (type: LOCATION_SELECTOR_TRIGGER_OPTIONS) => {
		dispatch(triggerCitySelection(type));
	};

	return {
		isLocationSelectorOpen,
		selectedLocation,
		...selectedLocation,
		selectedLocationSyncState,
		locationActions: {
			triggerCitySelector,
			updateSelectedLocation,
			updateSelectedLocationAndNotify,
			getPincodeDetails,
		},
	};
}
