import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { NextComponentType, NextPage, NextPageContext } from 'next';
import BaseLayout from '@/components/unlmtd/BaseLayout';
import theme from '@/utils/theme';
import { Box } from '@mui/material';
import { useUser } from '@/hooks/useUser';
import { useLencoDispatch } from '@/hooks/useLencoDispatch';
import { readUserSelectedLocationFromStorageAction } from '@/store/location/actions';
import { useLocation } from '@/hooks/useLocation';
import { ApiState, ViewStates } from '@/models/generics';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import { dataLayerPush } from '@/utils/dataLayer';
import useMoengage from '@/hooks/useMoengage';
import { WithRouterConfig } from '@/hoc/withRouterConfig';
import NotificationToasts from './Notifications';
import { useNotification } from '@/hooks/useNotification';
import ChatBotFloatingButton from './ChatBotFloatingButton';
import Auth from '../../unlmtd/Auth';
import { useLogin } from '@/hooks/useLogin';
import { useRouter } from 'next/router';
import { useAppConfig } from '@/hooks/useAppConfig';
import { WithCitySelector } from '@/hoc/withCitySelector';
import { unlmtdTheme } from '@/utils/theme/unlmtd';
import UnlmtdLayout from '@/layouts/unlmtdLayout';
import usePlan from '@/hooks/unlmtd/usePlan';
import { PlanSelectorStatus } from '@/store/unlmtd/slices/planConfigSlice';
import WithSubscription from '@/hoc/unlmtd/withSubscription'
import LocationSelector from '../LocationSelector';


export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};
function isClientBot() {
	const botRegx = new RegExp(
		'(.*)AdsBot(.*)|(.*)googlebot(.*)|(.*)yahoo(.*)|(.*)bingbot(.*)|(.*)baiduspider(.*)|(.*)facebookexternalhit(.*)|(.*)twitterbot(.*)|(.*)rogerbot(.*)|(.*)embedly(.*)|(.*)bufferbot(.*)|(.*)quora(.*)|(.*)preview(.*)|(.*)showyoubot(.*)|(.*)outbrain(.*)|(.*)pinterest/0.(.*)|(.*)developers.google.com/+/web/snippet(.*)|(.*)www.google.com/webmasters/tools/richsnippets(.*)|(.*)slackbot(.*)|(.*)vkShare(.*)|(.*)W3C_Validator(.*)|(.*)redditbot(.*)|(.*)Applebot(.*)|(.*)WhatsApp(.*)|(.*)flipboard(.*)|(.*)tumblr(.*)|(.*)bitlybot(.*)|(.*)SkypeUriPreview(.*)|(.*)nuzzel(.*)|(.*)Discordbot(.*)|(.*)GooglesPagesSpeed(.*)|(.*)Qwantify(.*)|(.*)GooglesStructuredsDatasTestingsTool'
	);
	const isBot =
		botRegx.test(window.navigator.userAgent) ||
		botRegx.test(window.navigator.userAgent.toLowerCase());

	return isBot;
}
const getPage = (
	Comp: NextComponentType<NextPageContext, any, any> &
		NextPageWithLayout<{}, {}>,
	pageProps: any
) => {
	if (!Comp.getLayout) {
		return (
			<UnlmtdLayout>
				<Comp {...pageProps} />
			</UnlmtdLayout>
		);
	}

	return Comp.getLayout(<Comp {...pageProps} />);
};

dataLayerPush({ medium: 'web' });

const UnlmtdRoot = (props: AppPropsWithLayout) => {
	const { Component, pageProps } = props;
	const [isHaptikEnabled, setIsHaptikEnabled] = useState(false);
	const dispatch = useLencoDispatch();
	const { selectedLocationSyncState, selectedLocation, locationActions } =
		useLocation();
	const { getUserApiState, userActions, user, ghostUser } = useUser();
	const moe = useMoengage();
	const { authFlowViewState, loginActions } = useLogin();
	
	const router = useRouter();
	const { selectedPlanId,planActions }=usePlan();
	const { appConfigActions } = useAppConfig();

	function checkLocationAndGetUser() {
		if (selectedLocation.cityId && selectedLocation.pincode) {
			

			userActions.getUser();
		} else {
			const isBot = isClientBot();
			if (isBot) {
				locationActions.updateSelectedLocation({
					pincode: 560068,
					cityId: 1,
					cityName: 'Bengaluru',
				});
			} else {
				// locationActions.triggerCitySelector(
				// 	LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_OPEN
				// );
			}
		}
	}

	function initMoengage() {
		moe.init();
		if (!!moe) {
			if (!!user) {
				moe.setUserId(user.id);
				moe.updateUserAttributes({
					firstName: user.first_name,
					email: user.email_id,
					mobile: user.contact_no,
					userName: user.first_name,
				});
			} else if (!!ghostUser) {
				moe.setUserId(ghostUser?.id as number);
			}
		}
	}

	function closeAuthDrawer() {
		loginActions.hideLoginFlow();
	}


	function onRouteChange() {
		appConfigActions.addHistory(router.asPath);
	}

	useEffect(() => {
		router.events.on('routeChangeStart', onRouteChange);
		return () => {
			router.events.off('routeChangeStart', onRouteChange);
		};
	}, []);
	useEffect(() => {
		if (selectedLocationSyncState == ApiState.COMPLETED) {
			checkLocationAndGetUser();
		}
	}, [selectedLocationSyncState]);

	useEffect(() => {
		if (getUserApiState == ApiState.SUCCESS) {
			initMoengage();
		}
	}, [getUserApiState]);
	useEffect(() => {
		if(!selectedPlanId){
			if(!selectedLocation){
				locationActions.triggerCitySelector(LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_OPEN);
			}
			else{
				planActions.showOrHidePlanSelector(PlanSelectorStatus.PLAN_SHOW)
			}
		}
	},[selectedLocation,selectedPlanId])

	useEffect(() => {
		dispatch(readUserSelectedLocationFromStorageAction());
	}, [])
	
	return (
		<ThemeProvider theme={unlmtdTheme}>
			<NotificationToasts />
			<Auth
				open={authFlowViewState == ViewStates.VISIBLE}
				onClose={closeAuthDrawer}
			/>
			<Box id='main' className="unlmtd">{getPage(Component, pageProps)}</Box>
			<LocationSelector />
			<WithRouterConfig>
				<ChatBotFloatingButton />
			</WithRouterConfig>
		</ThemeProvider>
	);
};

export default WithSubscription(WithCitySelector(UnlmtdRoot));
