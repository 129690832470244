
import { useSelector } from "react-redux";

export const ListingUrlHelper = ({ collectionType, name, sortBy, lineOfProduct }: { collectionType: string; name: string, sortBy?: string; lineOfProduct?: string; }) => {
    const cityName = useSelector((state: any) => state?.location?.selectedLocation?.cityName?.toLowerCase());
    if (!['CATEGORY_RENT', 'CATEGORY_BUY' , 'UNLMTD'].includes(collectionType)) {
        return getDefaultListingUrl({ collectionType, name, sortBy });
    }
    else {
        if (collectionType === 'CATEGORY_RENT') {
            if (cityName) {
                const query = new URLSearchParams();
                query.set('collectionType', collectionType);

                if (sortBy) {
                    query.set('sortBy', sortBy);
                }

                let url = `/${cityName.toLowerCase()}/${name}?${query.toString()}`;
                return url;
            } else {
                return getDefaultListingUrl({ collectionType, name });
            }
        }
        if (collectionType === 'CATEGORY_BUY') {
            const query = new URLSearchParams();

            query.set('collectionType', collectionType);

            if (sortBy) {
                query.set('sortBy', sortBy);
            }

            if (sortBy) {
                query.set('sortBy', sortBy);
            }

            if (lineOfProduct) {
                query.set('lineOfProduct', lineOfProduct);
            }

            let url = `/buy/${name}?${query.toString()}`;

            return url;
        }
        if (collectionType === 'UNLMTD') {
            const query = new URLSearchParams();

            query.set('collectionType', 'CATEGORY_RENT');

            if (sortBy) {
                query.set('sortBy', sortBy);
            }

            if (sortBy) {
                query.set('sortBy', sortBy);
            }

            if (lineOfProduct) {
                query.set('lineOfProduct', lineOfProduct);
            }

            let url = `/unlmtd/${cityName.toLowerCase()}/${name}?${query.toString()}`;

            return url;
        }
    }
};

const getDefaultListingUrl = ({ name, collectionType, sortBy }: { name: string; collectionType: string; sortBy?: string; }) => {
    let url = collectionType == 'UNLMTD' ? `/unlmtd/listing?collection=${name}` : `/listing?collection=${name}`
    url = collectionType ? url.concat(`&collectionType=${collectionType
        }`) : url;
    if (sortBy) {
        url = url.concat(`&sortBy=${sortBy}`);
    }
    return url;
};

export const PdpUrlHelper = ({ lop, permalink }: { lop?: string; permalink: string; }) => {
    if (!lop) {
        return `/products/${permalink}`;
    } 

    else {
        return `/${lop === 'RENT' ? 'rent' : lop == 'UNLMTD'? 'unlmtd' : 'buy'}/products/${permalink}`;
    }

};   