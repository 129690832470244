import BaseService from '@/services/base-service';
import { appendLocationToQueryParams } from '@/utils/interceptors';
import { CART_TYPE } from '../cart/models/cart';
import { TUpdateCartItemPayload } from '../cart/models/add-item-to-cart';
import { TUpdateCartApiResponse } from '../cart/models/update-cart';

class CartService extends BaseService {
	constructor() {
		super({
			serviceName: 'Cart',
			axios: {
				baseURL: `${ENV.CIA_HOST}/api/v1/unlmtd`,
			},
			customOptions: {
				interceptors: {
					request: appendLocationToQueryParams,
				},
			},
		});
	}

	public getCarts(query: any): Promise<any> {
		return this.client.get('/carts', { params: query });
	}
	public updateCart(
		cartId: number,
		updatedData: any
	): Promise<TUpdateCartApiResponse> {
		return this.client.put(`/carts/${cartId}`, updatedData);
	}
	public addItemToCart(
		payload: TUpdateCartItemPayload
	): Promise<TUpdateCartApiResponse> {
		return this.client.post(`/carts/add`, payload);
	}

	public updateCartItemQuantity(
		cartId: string | number,
		itemId: string | number,
		cartType: CART_TYPE,
		payload: any
	): Promise<TUpdateCartApiResponse> {
		return this.client.put(`/carts/${cartId}/cartItems/${itemId}`, payload, {
			params: {
				cartType,
			},
		});
	}

	public removeItemFromCart(payload: any): Promise<TUpdateCartApiResponse> {
		return this.client.delete(`/carts/remove`, { data: payload });
	}

	public createPayment(payload: any): Promise<any> {
		return this.client.post('/carts/create-payment', payload);
	}
	public getServicePacks(cityPlanId:any): Promise<any> {
		return this.client.get(`/city-plans/${cityPlanId}/service-packs?type=VAS`);
	}
}

export const unlmtdCartService = new CartService();
