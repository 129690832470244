import FlexBox from '@/atomic-components/FlexBox';
import SearchIcon from '@/assets/SearchIcon.svg';
import { Box, Typography } from '@mui/material';
import { getColor } from '@/utils/theme/colors';
import TrendingSearchIcon from '@/assets/trending-search-icon.svg'

function SearchBarTile(props: any) {
	return (
		<FlexBox
			sx={{
				justifyContent: 'start',
				alignItems: 'center',
				cursor: 'pointer',
				transition: '0.3s all ease',
				':hover': {
					background: `${getColor('Minty -5')}`,
					'#bot-div': {
						padding: '4px 32px',
					},
				},
			}}
			onClick={() => {
				props.onClick(props.name);
			}}
		>
			<FlexBox
				id='bot-div'
				sx={{ padding: '4px 24px', transition: '0.3s all ease' }}
				alignItems='center'
			>
				{!props?.isDummyData ? <Box sx={{ mt: '6px', transform: 'scale(0.7)' }}>
					<SearchIcon />
				</Box>:
                <Box sx={{ mt: '6px', }}>
                    <TrendingSearchIcon />
                </Box>
                }
				<Typography sx={{ color: getColor('Gray 0'), pl: '8px' }}>
					{props.name}
				</Typography>
			</FlexBox>
		</FlexBox>
	);
}

export default SearchBarTile;
