import BaseService from '@/services/base-service';
import { appendLocationToQueryParams } from '@/utils/interceptors';


class UnlmtdPlanService extends BaseService {
	constructor() {
		super({
			serviceName: 'Plan',
			customOptions: {
				interceptors: {
					request: appendLocationToQueryParams,
				},
			},
		});
	}

    public getPlans(cityPlanId:string): Promise<any> {
        return this.client.get(`/catalogue/plans`,{params:{cityPlanId}});
    }
	
}

export const unlmtdPlanService = new UnlmtdPlanService();
