// userSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { userService } from '@/services/user';
import { ApiState } from '@/models/generics';
import { GET_META, GET_USER ,GET_USER_ADDRESSES, LOGOUT_USER} from '@/store/user/actionTypes';
import { TGhostUser, TPanemUser } from '@/services/meta/models/meta';
import { ProfileMenuApiResponseData } from '@/services/user/models/profile-menu.types';
import {  TGetAddressesApiResponseData } from '@/services/user/models/addresses';

export interface IUserState {
  getUserApiState: ApiState;
  getAddressessApiState: ApiState;
  isLoggedIn: boolean;
  ghostUser?: TGhostUser;
  user?: TPanemUser;
  loadingProfileMenu: boolean;
  profileMenu?: ProfileMenuApiResponseData;
  errorLoadingProfileMenu: boolean;
  loggingOut: boolean;
  loggingOutFailed: boolean;
  loggingOutSuccess: boolean;
  addresses?: TGetAddressesApiResponseData;
  creatingAddress: boolean;
}

const initialState: IUserState = {
  getUserApiState: ApiState.LOADING,
  getAddressessApiState: ApiState.IDLE,
  isLoggedIn: false,
  ghostUser: undefined,
  user: undefined,
  loadingProfileMenu: true,
  profileMenu: undefined,
  errorLoadingProfileMenu: false,
  loggingOut: false,
  loggingOutFailed: false,
  loggingOutSuccess: false,
  addresses: undefined,
  creatingAddress: false,
};

// Async actions
export const getUserAddressesAction = createAsyncThunk(
  'user/getUserAddresses',
  async (_, { rejectWithValue }) => {
    try {
      const res = await userService.getAddress();
      const data = res?.data?.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Define the slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserLoading(state) {
      state.getUserApiState = ApiState.LOADING;
    },
    getUserSuccess(state, action: PayloadAction<{ ghostUser: TGhostUser, user: TPanemUser, isLoggedIn: boolean }>) {
      state.getUserApiState = ApiState.SUCCESS;
      state.ghostUser = action.payload.ghostUser;
      state.user = action.payload.user;
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    getUserFailed(state) {
      state.getUserApiState = ApiState.FAILED;
    },
    getProfileMenuLoading(state) {
      state.loadingProfileMenu = true;
      state.errorLoadingProfileMenu = false;
    },
    getProfileMenuSuccess(state, action: PayloadAction<ProfileMenuApiResponseData>) {
      state.loadingProfileMenu = false;
      state.profileMenu = action.payload;
    },
    getProfileMenuFailed(state) {
      state.loadingProfileMenu = false;
      state.errorLoadingProfileMenu = true;
    },
    logoutUserLoading(state) {
      state.loggingOut = true;
    },
    logoutUserSuccess(state, action: PayloadAction<TGhostUser>) {
      state.loggingOut = false;
      state.loggingOutSuccess = true;
      state.ghostUser = action.payload;
      state.isLoggedIn = false;
      state.user = undefined;
    },
    logoutUserFailed(state) {
      state.loggingOut = false;
      state.loggingOutFailed = true;
    },
    createUserAddressLoading(state) {
      state.creatingAddress = true;
    },
    createUserAddressSuccess(state) {
      state.creatingAddress = false;
    },
    createUserAddressFailed(state) {
      state.creatingAddress = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GET_USER.LOADING, (state) => {
        state.getUserApiState = ApiState.LOADING;
      })
      .addCase(GET_USER.SUCCESS, (state, action: any) => {
        state.getUserApiState = ApiState.SUCCESS;
        state.ghostUser = action?.ghostUser,
        state.user= action?.user,
        state.isLoggedIn= action?.isLoggedIn
      })
      .addCase(GET_USER.FAILED, (state) => {
        state.getUserApiState = ApiState.FAILED;
      })
      .addCase(GET_USER_ADDRESSES.LOADING, (state) => {
        state.getAddressessApiState = ApiState.LOADING;
      })
      .addCase(GET_USER_ADDRESSES.SUCCESS, (state,action:any) => {
        state.getAddressessApiState = ApiState.COMPLETED;
        state.addresses = action.payload;
      })
      .addCase(GET_USER_ADDRESSES.FAILED, (state,action) => {
        state.getAddressessApiState = ApiState.FAILED;
      })
      .addCase(GET_META.LOADING, (state) => {
        state.getUserApiState = ApiState.LOADING;
      })
      .addCase(GET_META.SUCCESS, (state, action: any) => {
        state.getUserApiState = ApiState.SUCCESS;
        state.ghostUser = action?.payload?.ghost_user,
        state.user= action?.payload?.user,
        state.isLoggedIn= action?.payload?.isLoggedIn
      })
      .addCase(GET_META.FAILED, (state) =>{
        state.getUserApiState = ApiState.FAILED;
      })
      .addCase(LOGOUT_USER.LOADING, (state) => {
        state.loggingOut = true;
      })
      .addCase(LOGOUT_USER.SUCCESS, (state, action: any) => {
        state.loggingOut = false;
        state.loggingOutSuccess = true;
        state.ghostUser = action?.payload;
        state.isLoggedIn = false;
        state.user = undefined;
      })
      .addCase(LOGOUT_USER.FAILED, (state) => {
        state.loggingOut = false;
        state.loggingOutFailed = true;
      })
  }
});

export const {
  getUserLoading,
  getUserSuccess,
  getUserFailed,
  getProfileMenuLoading,
  getProfileMenuSuccess,
  getProfileMenuFailed,
  logoutUserLoading,
  logoutUserSuccess,
  logoutUserFailed,
  createUserAddressLoading,
  createUserAddressSuccess,
  createUserAddressFailed
} = userSlice.actions;

export default userSlice.reducer;
