import Hoverable from '@/atomic-components/Hoverable';
import WishlistIconSvg from '@/assets/wishlist/wishlistIcon.svg';
import WishlistIconHoveredSvg from '@/assets/wishlist/WishlistIconHovered.svg';
import WishlistIconClickedSvg from '@/assets/wishlist/WishlistIconClicked.svg';

type WishlistIconProps = {
	onClick: () => void;
};

const WishlistIcon = (props: WishlistIconProps) => {
	function onClick() {
		if (typeof props.onClick == 'function') {
			return props.onClick();
		}
	}

	return (
		<Hoverable
			onClick={onClick}
			icons={{
				before: <WishlistIconSvg />,
				after: <WishlistIconHoveredSvg />,
				clicked: <WishlistIconClickedSvg />,
			}}
			className='move-up-transition'
		></Hoverable>
	);
};

export default WishlistIcon;
