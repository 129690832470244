import Hoverable from '@/atomic-components/Hoverable';
import MyOrdersIcon from '@/assets/profile/my-orders.svg';
import MyOrdersHoveredIcon from '@/assets/profile/my-orders-hovered.svg';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { MenuItemProps } from './HelpCenter';

const MyOrdersMenuItem = (props: MenuItemProps) => {
	return (
		<Hoverable
			icons={{ before: <MyOrdersIcon />, after: <MyOrdersHoveredIcon /> }}
			sx={{ display: 'flex', flexDirection: 'row' }}
			active={props.active}
		>
			<LencoTypography ml='16px'>My Orders</LencoTypography>
		</Hoverable>
	);
};

export default MyOrdersMenuItem;
