import BaseService from '@/services/base-service';
import { TGeneratePartnerTokenResponse } from './models/generate-partner-token';

class PartnerService extends BaseService {
  constructor() {
    super({
      serviceName: 'Partner'
    });
  }

  generatePartnerToken(payload: any): Promise<TGeneratePartnerTokenResponse> {
    return this.client.post(`/partner/generate_partner_token`, payload);
  }
}

export const partnerService = new PartnerService();
