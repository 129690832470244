import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { Box, TextField, useTheme } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { useEffect, useRef, useState } from 'react';
import useForm from '@/hooks/useForm';
import { useLencoDispatch } from '@/hooks/useLencoDispatch';
import {authService} from '@/services/auth';
import { getMetaAction } from '@/store/user/action';
import { useRouter } from 'next/router';
import RightArrowIcon from '@/assets/RightArrowIcon';
import BackArrow from '@/assets/unlmtd/backArrow.svg';
import { AUTH_STATES } from '..';
import LencoButton from '@/atomic-components/LencoButton';
import { ApiState } from '@/models/generics';
import { dataLayerPushWithEvent } from '@/utils/dataLayer';
import useMoengage from '@/hooks/useMoengage';
import { TPanemUser } from '@/services/meta/models/meta';
import { useNotification } from '@/hooks/useNotification';
import { NotificationSeverity } from '@/components/NotifyToast';
import { userService } from '@/services/user';
import { GET_META } from '@/store/user/actionTypes';
import { getStyle } from '@/utils/theme/fonts';

type UserSignUpProps = {
	setPage: Function;
	userDetails: any;
	setUserDetails: Function;
	onSignUp?: () => void;
};

const formConfig = {
	email: {
		defaultValue: '',
		required: {
			isRequired: true,
			msg: 'Required',
		},

		valid: {
			regex: /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
			msg: 'Enter a valid email!',
		},
	},
	name: {
		defaultValue: '',
		required: {
			isRequired: true,
			msg: 'Required',
		},
		valid: {
			regex: /[A-Za-z][A-Za-z][A-Za-z]+$/,
			msg: 'Name must be atleast 3 character long',
		},
	},
};
const UserSignUp = (props: UserSignUpProps) => {
	let inputEmailRef = useRef<HTMLInputElement>(null);
	const dispatch = useLencoDispatch();
	const [emailError, setEmailError] = useState('');
	const [registerUserApiState, setRegisterUserApiState] = useState<ApiState>(
		ApiState.IDLE
	);
	
	const { userDetails, setUserDetails, setPage } = props;
	const moengage = useMoengage();
	useEffect(() => {
		if (inputEmailRef.current) {
			inputEmailRef.current.focus();
		}
	}, []);
	const { actions } = useNotification();

	const theme = useTheme();

	// const handleOpen = () => setOpen(true);
	function onChange(e: any) {
		const regex = /^[a-zA-Z]+([\sa-zA-Z]+)*$/;
		const { value, name } = e.target;
		// setEmailError('');

		if (name === 'name') {
			// setClearInputNameField(false);
			if (value.match(regex) || value === '') {
				handleChange(e);
			}
		} else if (name === 'email') {
			// setClearEmailInputField(false);
			handleChange(e);
		}
	}


	async function registerUser() {
		setRegisterUserApiState(ApiState.LOADING);
		const payload = {
			contact_no: userDetails.mobile,
			email_id: values.email,
			name: values.name,
		};

		try {
			const res = await authService.registerUser(payload);
			if (res?.data?.data?.has_registered && res?.data?.success) {
				// updateWhatsappConsent();
				const user = res.data?.data?.user as TPanemUser;

				actions.notifyUser({
					message: 'Successfully registered',
					severity: NotificationSeverity.SUCCESS,
				});

				moengage.updateUserId(user?.id as number);
				moengage.updateUserAttributes({
					firstName: user.first_name,
					email: user.email_id,
					mobile: user.contact_no,
					userName: user.first_name,
				});

				dataLayerPushWithEvent('moe_event', {
					moe_event_name: 'Login Completed',
					moe_event_data: { login_type: 'new' },
				});
				dataLayerPushWithEvent('moe_event', {
					moe_event_name: 'New Signup Details Submitted',
				});

				await dispatch(getMetaAction());
				props.onSignUp && props.onSignUp();
			}
		} catch (err: any) {
			if (err?.response?.data?.error?.name === 'EMAIL_ALREADY_REGISTERED') {
				setUserDetails((prev: any) => ({
					...prev,
					email: values.email,
					name: values.name,
				}));
				values.email = '';
				actions.notifyUser({
					message: 'Email already registered!',
					severity: NotificationSeverity.WARNING,
				});
				setPage(AUTH_STATES.EMAIL_ALREADY_REGISTERED);
			} else {
				setEmailError(err?.response?.data?.error?.message);
				actions.notifyUser({
					message: err?.response?.data?.error?.message,
					severity: NotificationSeverity.ERROR,
				});
			}
		}
		// registeringUser.setValue(false);
		setRegisterUserApiState(ApiState.COMPLETED);
	}

	const { values, errors, handleChange, handleSubmission } = useForm(
		formConfig,
		registerUser
	);

	const border = errors.email ? `1px solid ${getColor('Danger +1')}` : 'none';
	const nameFieldBorder = errors.name
		? `1px solid ${getColor('Danger +1')}`
		: 'none';

	const goBack = () => props.setPage(AUTH_STATES.VERIFY_PHONE_NUMBER);

	return (
		<FlexBox direction='column'>
			<FlexBox onClick={goBack} sx={{ cursor: 'pointer', marginBottom: '24px' , width:"100%"}} style={{ justifyContent:'start' }}>
				<BackArrow />
			</FlexBox>
			<LencoTypography
				sx={{
					...getStyle('H1A_Medium'),
					color: getColor(''),
				}}
			>
				Looks like
			</LencoTypography>
			<LencoTypography
				component='span'
				sx={{
					...getStyle('H1A_Medium'),
					color: getColor(''),
				}}
			>
				you are{' '}
				<LencoTypography
					component='span'
					sx={{
						...getStyle('H1A_Medium'),
						color: theme?.colors?.lencoSecondaryN3,
					}}
				>
					New
				</LencoTypography>
			</LencoTypography>
			<form style={{ width: '100%' }}>
				<TextField
					autoComplete='false'
					name='name'
					// autoFocus
					onChange={onChange}
					value={values.name ? values.name : ''}
					sx={{
						width: '100%',
						padding: '8px 32px 8px 32px',
						borderRadius: '8px',
						background: theme?.colors?.lencoPrimaryP2,
						border: `${nameFieldBorder}`,
						marginTop: '8px',
						input: {
							'&::placeholder': {
								color: getColor('Gray -5'),
								...getStyle('H14_Medium'),
							},
							color: getColor('Gray -5'),
						},
					}}
					placeholder='Name'
					variant='standard'
					InputProps={{
						disableUnderline: true,
					}}
					inputProps={{
						minLength: 3,
					}}
				/>
				{errors.name && (
					<LencoTypography
						sx={{ color: theme?.colors?.lencoSecondaryN3, marginTop: '24px' }}
					>
						{errors.name}
					</LencoTypography>
				)}
				<TextField
					autoComplete='false'
					name='email'
					value={values.email ? values.email : ''}
					onChange={onChange}
					sx={{
						width: '100%',
						padding: '8px 32px 8px 32px',
						borderRadius: '8px',
						background: theme?.colors?.lencoPrimaryP2,
						border: `${nameFieldBorder}`,
						marginTop: '8px',
						input: {
							'&::placeholder': {
								color: getColor('Gray -5'),
								...getStyle('H14_Medium'),
							},
							color: getColor('Gray -5'),
						},
					}}
					placeholder='Email Address'
					variant='standard'
					InputProps={{
						disableUnderline: true,
					}}
				/>
				

				{(errors.email || emailError) && (
					<LencoTypography
						sx={{ color: theme?.colors?.lencoSecondaryN3, marginTop: '24px' }}
					>
						{errors.email}
					</LencoTypography>
				)}
				<Box mt='24px'>
					<LencoButton
						fullWidth
						onClick={handleSubmission}
						variant='contained'
						sx={{
							padding: '16px 48px',
							background:
								values.name !== '' && values.email !== ''
									? theme?.colors?.lencoSecondaryN1
									: theme?.colors?.lencoSecondaryP1,
							color:
								values.name !== '' && values.email !== ''
									? getColor('gray -5')
									: getColor('gray -3'),

							'&:hover': {
								background:
									values.name !== '' && values.email !== ''
										? theme?.colors?.lencoSecondaryN1
										: theme?.colors?.lencoSecondaryP1,
							},
							boxShadow: 'none',
						}}
						isLoading={registerUserApiState == ApiState.LOADING}
						color='secondary'
					>
						<LencoTypography mr='10px' sx={{ ...getStyle('H14_SemiBold') }}>
						CREATE YOUR ACCOUNT
						</LencoTypography>
						<RightArrowIcon
							color={
								values.name !== '' && values.email !== ''
									? getColor('Gray -5')
									: getColor('Gray -3')
							}
						/>
					</LencoButton>
				</Box>
			</form>
		</FlexBox>
	);
};

export default UserSignUp;
