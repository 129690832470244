import { createSlice } from '@reduxjs/toolkit';
import { cartService } from '@/services/cart';
import { ICartItem } from '@/services/cart/models/cart';
import { ApiState } from '../../../models/generics';
import { CART_TYPE } from '@/services/cart/models/cart';
interface CartState {
	cart: ICartItem[] | null;
	status: ApiState;
	error: string;
}

const initialState: CartState = {
	cart: null,
	status: ApiState.IDLE,
	error: '',
};

const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		getCartPending: (state) => {
			state.status = ApiState.LOADING;
		},
		getCartFulfilled: (state, action) => {
			state.status = ApiState.SUCCESS;
			state.cart = action.payload;
			state.error = '';
		},
		getCartRejected: (state, action) => {
			state.status = ApiState.FAILED;
			state.error = action.payload as string;
		},

		addItemToCartFulfilled: (state, action) => {
			state.cart = action.payload;
		},

		removeItemFromCartFulfilled: (state, action) => {
			state.cart = action.payload;
		},
		updateCartItemQuantityFulfilled: (state, action) => {
			state.status = ApiState.SUCCESS;
			state.cart = action.payload;
		},
	},
});

export const {
	getCartPending,
	getCartFulfilled,
	getCartRejected,
	addItemToCartFulfilled,
	removeItemFromCartFulfilled,
	updateCartItemQuantityFulfilled,
} = cartSlice.actions;

// export const fetchCart = (query?: any) => async (dispatch: any) => {
// 	dispatch(getCartPending());
// 	try {
// 		const response = await cartService.getCarts(query);
// 		dispatch(getCartFulfilled(response.data));
// 	} catch (error) {
// 		dispatch(getCartRejected((error as any).toString()));
// 	}
// };

// export const addItemToCart =
// 	({ cartId, item }: { cartId: number; item: any }) =>
// 	async (dispatch: any) => {
// 		try {
// 			const response = await cartService.addItemToCart(cartId, item);
// 			dispatch(addItemToCartFulfilled(response.data));
// 		} catch (error) {
// 			console.error('Failed to add item', error);
// 		}
// 	};

// export const updateCartItemQuantity =
// 	({
// 		cartId,
// 		itemId,
// 		cartType,
// 		payload,
// 	}: {
// 		cartId: number;
// 		itemId: number;
// 		cartType: CART_TYPE;
// 		payload: IUpdateCartItemQuantityPayload;
// 	}) =>
// 	async (dispatch: any) => {
// 		try {
// 			const response = await cartService.updateCartItemQuantity(
// 				cartId,
// 				itemId,
// 				cartType,
// 				payload
// 			);
// 			dispatch(updateCartItemQuantityFulfilled(response.data));
// 		} catch (error) {
// 			console.error('Failed to update item quantity', error);
// 		}
// 	};

// export const removeItemFromCart =
// 	({
// 		cartId,
// 		itemId,
// 		cartType,
// 	}: {
// 		cartId: number;
// 		itemId: number;
// 		cartType: CART_TYPE;
// 	}) =>
// 	async (dispatch: any) => {
// 		try {
// 			const response = await cartService.removeItemFromCart(
// 				cartId,
// 				itemId,
// 				cartType
// 			);
// 			dispatch(removeItemFromCartFulfilled(response.data));
// 		} catch (error) {
// 			console.error('Failed to remove item', error);
// 		}
// 	};

export default cartSlice.reducer;
