import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import PencilIcon from '@/assets/auth/pencil.svg';
import RightArrowIcon from '@/assets/auth/arrowRight.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { startCounter } from '@/utils/common';
import BackArrow from '@/assets/backArrow.svg';
import OTPInputComponent from './OtpInput';
import {authService} from '@/services/auth';
import { useLencoDispatch } from '@/hooks/useLencoDispatch';
import { getMetaAction } from '@/store/user/action';
import { AUTH_STATES } from '..';
import { ApiState } from '@/models/generics';
import { getStyle } from '@/utils/theme/fonts';
import { dataLayerPushWithEvent } from '@/utils/dataLayer';
import useMoengage from '@/hooks/useMoengage';
import { TPanemUser } from '@/services/meta/models/meta';
import LencoButton from '@/atomic-components/LencoButton';
import { useNotification } from '@/hooks/useNotification';
import { NotificationSeverity } from '@/components/NotifyToast';

type VerityOtpProps = {
	userDetails: any;
	resendOTP: Function;
	loginError: string;
	loginErrorCode: string;
	setPage: Function;
	setUserDetails: Function;
	onLogin: () => void;
};

const VerifyOtp = (props: VerityOtpProps) => {
	const [otp, setOTP] = useState('');
	const [error, setError] = useState('');
	const [isTimeOver, setIsTimeOver] = useState(false);
	const [verifyingOtp, setVerifyingOtp] = useState(false);
	const [otpResendCount, setOtpResendCount] = useState(0);
	const [open, setOpen] = useState(true);
	const [resendButtonAction, setResendButtonAction] = useState(false);
	const [verifyOtpViewState, setVerifyOtpViewState] = useState<ApiState>(
		ApiState.IDLE
	);
	const [timeLeftToResendOtp, setTimeLeftToResendOtp] = useState('00:30');
	const moengage = useMoengage();
	const {
		userDetails,
		resendOTP,
		setPage,
		setUserDetails,
		loginError,
		loginErrorCode,
	} = props;
	let setTimeIntervalCount: any;
	const { actions } = useNotification();

	useEffect(() => {
		setTimeIntervalCount = startCounter(
			0.5,
			timeLeftToResendOtp,
			setTimeLeftToResendOtp,
			() => {
				setIsTimeOver(true), setError('');
				setResendButtonAction(true);
			}
		);
		return () => {
			clearInterval(setTimeIntervalCount);
		};
	}, []);

	const dispatch = useLencoDispatch();
	const resetTimer = () => {
		setIsTimeOver(false);
		setTimeIntervalCount = startCounter(
			0.5,
			timeLeftToResendOtp,
			setTimeLeftToResendOtp,
			() => {
				setIsTimeOver(true);
				setResendButtonAction(true);
			}
		);
	};

	const handleResend = () => {
		resendOTP(userDetails);
		setOtpResendCount((prevCount) => prevCount + 1);
		setError('');
		setResendButtonAction(false);
		resetTimer();
	};

	async function verifyAccount() {
		const account = userDetails.mobile;
		const OTPType = userDetails.email ? 'Email' : 'Phone';
		dataLayerPushWithEvent('moe_event', {
			moe_event_name: 'OTP Submit Clicked',
			moe_event_data: {
				Type: OTPType,
			},
		});
		var payload = {
			account: account,
			otp: otp,
		};

		setVerifyOtpViewState(ApiState.LOADING);

		try {
			const res = await authService.verifyOtp(payload);
			const data = res.data?.data;
			if (!!data) {
				const hasRegistered = data.has_registered;
				if (OTPType == 'Email') {
					dataLayerPushWithEvent('moe_event', {
						moe_event_name: 'Login Completed',
					});
				}
				setUserDetails((prev: any) => ({
					...prev,
					has_registered: hasRegistered,
				}));
				actions.notifyUser({
					message: 'Logged in successfully',
					severity: NotificationSeverity.SUCCESS,
				});
				if (data && hasRegistered) {
					const user = res.data?.data?.user as TPanemUser;

					moengage.updateUserId(user?.id as number);
					moengage.updateUserAttributes({
						firstName: user.first_name,
						email: user.email_id,
						mobile: user.contact_no,
						userName: user.first_name,
					});
					await dispatch(getMetaAction());
					setVerifyOtpViewState(ApiState.SUCCESS);
					props.onLogin();
				} else if (data && !hasRegistered) {
					setPage(AUTH_STATES.USER_SIGN_UP);
					setVerifyOtpViewState(ApiState.SUCCESS);
				} else if (error) {
					setError('');
				}
				setError('');
			}
			return;
		} catch (err) {
			const error = (err as any).response?.data?.error;
			setError(error?.message);
			setVerifyOtpViewState(ApiState.FAILED);
			actions.notifyUser({
				message: error?.message,
				severity: NotificationSeverity.ERROR,
			});
			return;
		}
		setVerifyOtpViewState(ApiState.COMPLETED);
	}

	const goBack = () => setPage(AUTH_STATES.VERIFY_PHONE_NUMBER);

	const resendOTPButtonColor = resendButtonAction ? '#40B6C2' : '#666666';
	const resendOTPButtonTypography = resendButtonAction
		? 'H14_SemiBold'
		: 'H14_Regular';

	useEffect(() => {
		setError('');
	}, [otp]);

	return (
		<FlexBox direction='column'>
			<Box onClick={goBack} sx={{ marginBottom: '24px', cursor: 'pointer' }}>
				<BackArrow />
			</Box>
			<LencoTypography
				sx={{
					fontWeight: '500',
					fontSize: '36px',
					fontStyle: 'italic',
					fontFamily: 'Recline',
					color: '#303133',
				}}
			>
				Verify
			</LencoTypography>
			<LencoTypography
				sx={{
					fontWeight: '500',
					fontSize: '36px',
					fontStyle: 'italic',
					fontFamily: 'Recline',
					color: '#303133',
				}}
			>
				using&nbsp;
				<LencoTypography
					component='span'
					sx={{
						fontWeight: '500',
						fontSize: '36px',
						fontStyle: 'italic',
						fontFamily: 'Recline',
						color: getColor('Minty 0'),
					}}
				>
					OTP
				</LencoTypography>
			</LencoTypography>
			<LencoTypography sx={{ color: getColor('Gray -3'), marginTop: '4px' }}>
				Sent to {userDetails.email ? userDetails.email : userDetails.mobile}{' '}
				<Box
					component='span'
					onClick={() => {
						userDetails.email
							? setPage(AUTH_STATES.USER_SIGN_UP)
							: setPage(AUTH_STATES.VERIFY_PHONE_NUMBER);
					}}
					sx={{ cursor: 'pointer', paddingLeft: '8px' }}
				>
					<PencilIcon />
				</Box>
			</LencoTypography>
			<FlexBox sx={{ marginTop: '24px' }}>
				<OTPInputComponent
					length={userDetails.otp_length}
					autoFocus
					onChangeOTP={setOTP}
					otpError={error}
					VerifyOtp={verifyAccount}
				/>
			</FlexBox>
			{otpResendCount > 0 && (
				<LencoTypography
					variant='P_Regular'
					sx={{ color: getColor('Success +1'), marginTop: '24px' }}
				>
					OTP sent again
				</LencoTypography>
			)}
			{error && (
				<LencoTypography
					variant='P_Regular'
					sx={{ color: getColor('Danger +1'), marginTop: '24px' }}
				>
					{error}
				</LencoTypography>
			)}
			<Box mt='24px'>
				<LencoButton
					fullWidth
					onClick={verifyAccount}
					variant='contained'
					sx={{ padding: '16px 48px' }}
					isLoading={verifyOtpViewState === ApiState.LOADING}
				>
					<LencoTypography mr='10px'>CONFIRM & CONTINUE</LencoTypography>
					<RightArrowIcon />
				</LencoButton>
			</Box>
			<FlexBox sx={{ marginTop: '24px' }}>
				{resendButtonAction ? (
					<LencoTypography
						onClick={handleResend}
						sx={{
							...getStyle(`${resendOTPButtonTypography}`),
							color: `${resendOTPButtonColor}`,
							cursor: 'pointer',
						}}
					>
						Resend OTP
					</LencoTypography>
				) : (
					<LencoTypography
						sx={{
							...getStyle(`${resendOTPButtonTypography}`),
							color: `${resendOTPButtonColor}`,
						}}
					>
						Resend OTP
					</LencoTypography>
				)}
				{!resendButtonAction && (
					<LencoTypography
						sx={{
							...getStyle('H14_Regular'),
							paddingLeft: '4px',
							color: '#666666',
						}}
					>
						{timeLeftToResendOtp}
					</LencoTypography>
				)}
			</FlexBox>
		</FlexBox>
	);
};

export default VerifyOtp;
