import Hoverable from '@/atomic-components/Hoverable';
import HelpCenterIcon from '@/assets/profile/help-center.svg';
import HelpCenterHoveredIcon from '@/assets/profile/help-center-hovered.svg';
import { LencoTypography } from '@/atomic-components/LencoTypography';

export type MenuItemProps = {
	active: boolean;
};

const HelpCenterMenuItem = (props: MenuItemProps) => {
	return (
		<Hoverable
			icons={{ before: <HelpCenterIcon />, after: <HelpCenterHoveredIcon /> }}
			sx={{ display: 'flex', flexDirection: 'row' }}
			active={props.active}
		>
			<LencoTypography ml='16px'>Help Center</LencoTypography>
		</Hoverable>
	);
};

export default HelpCenterMenuItem;
