import ChatButton from '@/assets/chat.svg';
import { withFullScreenLoader } from '@/hoc/withFullScreenLoader';
import { useFullScreenLoader } from '@/hooks/useFullScreenLoader';
import HaptikHoc from '../../haptik';
import { YmProvider } from '../yellowMesssenger';

const NonChatBotPages = ['/cart','/checkout'] 


function ChatBotFloatingButton() {
	const { loader } = useFullScreenLoader();
	const onHaptikLaunched = () => {
		loader.hide();
	};

	const launchChatBot = () => {
		loader.show();
	};


	return (
		// <HaptikHoc onClick={launchChatBot} onLaunch={onHaptikLaunched}>
		<>
		<YmProvider>
			<div
				className={
					'haptik-lets-chat d-flex justify-content-center align-items-center'
				}
				style={{
					position: 'fixed',
					zIndex: '99',
					right: 28,
					bottom: 40,
					cursor: 'pointer',
				}}
			>
				<ChatButton />
			</div>
			</YmProvider>
			</>
		// </HaptikHoc>
	);
}

export default withFullScreenLoader(ChatBotFloatingButton);
