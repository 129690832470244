import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { Box, Divider, useTheme } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import AccountIcon from '@/components/unlmtd/Icons/AccountIcon';
import { useUser } from '@/hooks/useUser';
import { getStyle } from '@/utils/theme/fonts';
import LoginTriggerButton from '@/components/unlmtd/Auth/LoginTriggerButton';
import { MenuItem } from '@/services/user/models/profile-menu.types';
import { TPanemUser } from '@/services/meta/models/meta';
import {
	ProvideFullScreenLoader,
	useFullScreenLoader,
} from '@/hooks/useFullScreenLoader';
import Link from 'next/link';
import { MenuMap } from '@/utils/profileMenuItemDetails';
import { emitMoeEvent } from '@/utils/dataLayer';
import { useRouter } from 'next/router';
import useMoengage from '@/hooks/useMoengage';
import { useEffect } from 'react';
import usePlan from '@/hooks/unlmtd/usePlan';

type AccountPopoverContentProps = {
	user?: TPanemUser;
	menu: MenuItem[];
};

const LoggedInContent = (props: AccountPopoverContentProps) => {
	const { user, ghostUser, userActions } = useUser();
	const { loader } = useFullScreenLoader();
	const router = useRouter();
	const moengage = useMoengage();
	const {planActions} = usePlan();

	function calculateIsPrivateRoute() {
		return router.pathname.includes('/my/');
	}

	function onLoggedout() {
		moengage.destroySession();
		const isPrivateRoute = calculateIsPrivateRoute();
		loader.hide();

		planActions?.removePlan();

		// if (isPrivateRoute) {
			router.push('/unlmtd');
		// }
	}

	function onLogoutClicked() {
		loader.show();
		emitMoeEvent('Logout Button Clicked');

		userActions.logoutUser().finally(onLoggedout);
	}

	useEffect(() => {
		if (ghostUser?.id) {
			moengage.updateUserId(ghostUser.id);
		}
	}, [ghostUser?.id]);

	return (
		<Box
			width='186px'
			sx={{ ...getStyle('H14_Medium') }}
			className='cursor-pointer'
		>
			<LencoTypography>
				Hello! <br />
			</LencoTypography>
			<LencoTypography variant='P_SemiBold'>{user?.first_name}</LencoTypography>
			<Box mt='12px'>
				<Divider />
			</Box>
			<Box
				sx={{
					'p:hover': {
						...getStyle('P_Medium'),
						color: getColor('gray -4'),
					},
				}}
			>
				{props.menu?.map((menuItem) => {
					const href = MenuMap.has(menuItem.name)
						? (MenuMap.get(menuItem.name)?.path as string)
						: '';

					return (
						<Link href={href} key={href}>
							<LencoTypography key={menuItem.name} mt='12px'>
								{menuItem.title}
							</LencoTypography>
						</Link>
					);
				})}
				<LencoTypography
					mt='12px'
					onClick={onLogoutClicked}
					sx={{
						'&:hover': {
							color: getColor('gray -5'),
						},
					}}
				>
					Logout
				</LencoTypography>
			</Box>
		</Box>
	);
};

const LoggedOutContent = (props: AccountPopoverContentProps) => {
	const { isLoggedIn, user, profileMenu } = useUser();
	return (
		<Box width='186px' sx={{ ...getStyle('H14_Medium') }}>
			<LencoTypography>
				Hello! <br />
				User
			</LencoTypography>
			<LoginTriggerButton isLoggedIn={isLoggedIn} />
			<Divider />
			<Box mt='16px'>
				{props.menu?.map((menuItem) => {
					const href = MenuMap.has(menuItem.name)
						? (MenuMap.get(menuItem.name)?.path as string)
						: '';

					return (
						<Link href={href} key={href}>
							<LencoTypography key={menuItem.name} mt='12px'>
								{menuItem.title}
							</LencoTypography>
						</Link>
					);
				})}
			</Box>
		</Box>
	);
};

const AccountPopover = () => {
	const { isLoggedIn, user, profileMenu } = useUser();
	const theme = useTheme();
	return (
		<ProvideFullScreenLoader>
			<Box className='popover_wrapper'>
				<Box id='mouse-over-popover' className='popover_content popover_right'>
					<FlexBox
						direction='column'
						alignItems='flex-start'
						justifyContent='flex-start'
						sx={{
							p: '32px',
							borderRadius: '8px',
							color: getColor('gray -5'),
							background: theme?.colors?.lencoPrimaryP1,
						}}
					>
						{isLoggedIn ? (
							<LoggedInContent
								user={user}
								menu={profileMenu?.menu as MenuItem[]}
							/>
						) : (
							<LoggedOutContent menu={profileMenu?.menu as MenuItem[]} />
						)}
					</FlexBox>
				</Box>
				<FlexBox alignItems='center' className='popover_title'>
					<Box>
						<AccountIcon />
					</Box>
				</FlexBox>
			</Box>
		</ProvideFullScreenLoader>
	);
};

export default AccountPopover;
