import { TMetaApiResponse } from './models/meta';
import LencoAxios from '@/services/base-service';
import { TLocation } from '@/store/location/reducer';


export type GetMetaOptions = {
  hardRefresh: boolean;
};

class MetaService extends LencoAxios {
  constructor() {
    super({
      serviceName: 'Meta'
    });
  }

  getMeta(options?: TLocation): Promise<TMetaApiResponse> {
    return this.client.get('/meta', {
      headers: {
        ...(options && {
          'x-city-id': options?.cityId ?? 1,
          'x-pincode': options?.pincode
        })
      }
    });
  }
}

export const metaService = new MetaService();
