import { SxProps } from '@mui/system';
import { Box, BoxProps, Theme } from '@mui/material';
import { Property } from 'csstype';

export interface FlexBoxComponentProps extends BoxProps {
	direction?: Property.FlexDirection;
	alignItems?: Property.AlignItems;
	justifyContent?: Property.AlignItems;
	flexType?: 'flex' | 'inline-flex';
	grow?: 0 | 1;
	wrap?: Property.FlexWrap;
	sx?: SxProps<Theme>;
	width?: string;
	height?: string;
	fullWidth?: boolean;
}

const FlexBox = (props: FlexBoxComponentProps) => {
	const {
		flexType,
		direction,
		justifyContent,
		alignItems,
		wrap,
		grow,
		sx,
		fullWidth,
		...restProps
	} = props;

	let width = props.width;

	if (fullWidth) {
		width = '100%';
	}

	return (
		<Box
			{...restProps}
			sx={{
				width,
				height: props.height ?? 'auto',
				display: flexType || 'flex',
				flexDirection: direction || 'row',
				justifyContent,
				alignItems: alignItems,
				flexWrap: wrap || 'nowrap',
				flexGrow: grow || 0,
				...props.sx,
			}}
		></Box>
	);
};

export default FlexBox;