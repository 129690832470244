import Hoverable from '@/atomic-components/Hoverable';
import BuyIconSvg from '@/assets/buy/BuyIconOutlined.svg';
import BuyIconHoveredSvg from '@/assets/buy/BuyIconOutlinedHovered.svg';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { getStyle } from '@/utils/theme/fonts';
import { getColor } from '@/utils/theme/colors';
// import BuyIconClickedSvg from '@/assets/buy/BuyIconOutlinedClicked.svg';

type BuyIconProps = {
	onClick?: () => void;
};

const BuyIcon = (props: BuyIconProps) => {
	function onClick() {
		if (typeof props.onClick == 'function') {
			return props.onClick();
		}
	}

	return (
		<Hoverable
			onClick={onClick}
			icons={{
				before: <BuyIconSvg />,
				after: <BuyIconHoveredSvg />,
				// clicked: <BuyIconClickedSvg />,
			}}
			sx={{
				display: 'flex',
				alignItems: 'center',
				color: getColor('Gray -2'),
				':hover': {
					color: getColor('Minty 0'),
				},
			}}
		></Hoverable>
	);
};

export default BuyIcon;
