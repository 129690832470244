export const UnlmtdColors={
    primary:{
      "lencoPrimaryN6":'#EFECF9',
      "lencoPrimaryN5":'#DFD8F2',
      "lencoPrimaryN4":'#CFC5EB',
      "lencoPrimaryN3":'#B6A8E1',
      "lencoPrimaryN2":'#9D8AD6',
      "lencoPrimaryN1":'#856DCC',
      "lencoPrimaryP0":'#715BB6',
      "lencoPrimaryP1":'#6653A4',
      "lencoPrimaryP2":'#4F4280',
      "lencoPrimaryP3":'#39315C',
      "lencoPrimaryP4":'#222038',
      "lencoPrimaryP5":'#A09FC1',
    },
    secondary:{
      "lencoSecondaryN6":'#F2E3EE',
      "lencoSecondaryN5":'#E8CCE1',
      "lencoSecondaryN4":'#DEB6D4',
      "lencoSecondaryN3":'#D49FC7',
      "lencoSecondaryN2":'#CA88B9',
      "lencoSecondaryN1":'#C072AC',
      "lencoSecondaryP0":'#B65B9F',
      "lencoSecondaryP1":'#934482',
      "lencoSecondaryP2":'#702E65',
      "lencoSecondaryP3":'#4D1747',
    "lencoSecondaryP4":'#2A002A',
    }
    
    
    }