import Hoverable from '@/atomic-components/Hoverable';
import RentIconSvg from '@/assets/rent/RentIconOutlined.svg';
import RentIconHoveredSvg from '@/assets/rent/RentIconOutlinedHovered.svg';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { getStyle } from '@/utils/theme/fonts';
import { getColor } from '@/utils/theme/colors';
// import RentIconClickedSvg from '@/assets/buy/RentIconOutlinedClicked.svg';

type RentIconProps = {
	onClick?: () => void;
};

const RentIcon = (props: RentIconProps) => {
	function onClick() {
		if (typeof props.onClick == 'function') {
			return props.onClick();
		}
	}

	return (
		<Hoverable
			onClick={onClick}
			icons={{
				before: <RentIconSvg />,
				after: <RentIconHoveredSvg />,
				// clicked: <RentIconClickedSvg />,
			}}
			sx={{
				display: 'flex',
				alignItems: 'center',
				color: getColor('Gray -2'),
				':hover': {
					color: getColor('Minty 0'),
				},
			}}
		></Hoverable>
	);
};

export default RentIcon;
