import { Box,  IconButton, Modal  } from '@mui/material';
import CloseIcon from '@/assets/CloseIcon.svg';
import FlexBox from '@/atomic-components/FlexBox';

type AuthModelProps = {
	open: boolean;
	onClose: () => void;
	children: React.ReactElement;
};

const AuthModal = (props: AuthModelProps) => {

	return (
		<Modal
			open={props.open}
			onClose={props.onClose}
			sx={{
				flexDirection: 'row',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Box
				sx={{
					width: '100%',
					borderRadius: '10px',
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: '16px',
					maxWidth: '540px',
				}}
			>
				<FlexBox justifyContent='flex-end'>
					<IconButton onClick={props.onClose}>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				<Box
					sx={{
						p: '64px',
					}}
				>
					{props?.children}
				</Box>
			</Box>
		</Modal>
	);
};

export default AuthModal;
