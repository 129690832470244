import FlexBox from '@/atomic-components/FlexBox';
import GlobalHeader from '@/components/unlmtd/GlobalHeader';
import { Box } from '@mui/material';

type TBaseLayoutProps = {
	children?: React.ReactNode;
};

const unlmtdLayout = (props: TBaseLayoutProps) => {
	return (
		<Box sx={{background:"#222038"}} className="unlmtd">
			<GlobalHeader />
			<Box
				id='main'
				sx={{
					paddingTop: '110px',
                    background:"#222038"
				}}
			>
				{props.children}
			</Box>
		</Box>
	);
};

export default unlmtdLayout;
