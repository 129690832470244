import FlexBox from '@/atomic-components/FlexBox';
import { useLocation } from '@/hooks/useLocation';
import { Box } from '@mui/material';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import LocationPin from '@/components/LocationPin';


const SelectedLocationDisplay = () => {
	const {
		selectedLocation,
		locationActions: { triggerCitySelector },
	} = useLocation();

	function openLocationSelector() {
		triggerCitySelector(
			LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_OPEN
		);
	}

	return (
		<>
			<FlexBox onClick={openLocationSelector} sx={{ cursor: 'pointer' }}>
				<Box mr='4px' >
					<LocationPin pincode={selectedLocation.pincode as number} />
				</Box>
			</FlexBox>
		</>
	);
};

export default SelectedLocationDisplay;
