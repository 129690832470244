import Hoverable from '@/atomic-components/Hoverable';
import LocationPinIconSvg from '@/assets/location/LocationPin.svg';
import LocationPinIconHoveredSvg from '@/assets/location/LocationPinHovered.svg';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { getColor } from '@/utils/theme/colors';
import { Box } from '@mui/material';
import { getStyle } from '@/utils/theme/fonts';
import LocationPinIconClickedSvg from '@/assets/buy/LocationPinIconOutlinedClicked.svg';

type LocationPinIconProps = {
	onClick?: () => void;
	pincode: number;
};

const LocationPin = (props: LocationPinIconProps) => {
	function onClick() {
		if (typeof props.onClick == 'function') {
			return props.onClick();
		}
	}

	return (
		<Hoverable
			onClick={onClick}
			icons={{
				before: <LocationPinIconSvg />,
				after: <LocationPinIconHoveredSvg />,
				// clicked: <LocationPinIconClickedSvg />,
			}}
			sx={{
				display: 'flex',
				alignItems: 'center',
				':hover': { color: getColor('Minty 0') },
			}}
			id='header-location-pin' // id is used to modify text color, on hover. check global.css for more
		>
			<Box m='auto' ml='8px'>
				<LencoTypography
					variant='Small_Regular'
					color={getColor('Gray -3')}
					
				>
					Delivery to
				</LencoTypography>
				<LencoTypography
					variant='P_SemiBold'
					color={getColor('Gray -2')}
					
				>
					{props.pincode}
				</LencoTypography>
			</Box>
		</Hoverable>
	);
};

export default LocationPin;
