import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { TextField, InputAdornment, IconButton, Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import RightArrowIcon from '@/assets/RightArrowIcon';
import theme from '@/utils/theme';

type TPincodeInputProps = {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checkPincode: () => void;
	currentlySelectedPincode?: number;
	pincodeError?: string;
};

const PincodeInput = (props: TPincodeInputProps) => {
	function onFormSubmit(e: any) {
		e.preventDefault();
		props.checkPincode();
	}

	function onKeyUp(e: any) {
		if (e.keyCode === 13) {
			props.checkPincode();
		}
	}

	return (
		<FlexBox direction='column' fullWidth alignItems={'center'}>
			<form onSubmit={onFormSubmit} style={{ width: '100%' }}>
				<TextField
					value={props.value}
					autoFocus={true}
					type='mobile'
					inputProps={{
						inputMode: 'numeric',
						pattern: '[0-9]*',
						minLength: 5,
						maxLength: 6,
					}}
					onChange={props.onChange}
					placeholder='Enter your pincode'
					error={!!props.pincodeError}
					helperText={props.pincodeError}
					onKeyUp={onKeyUp}
					InputProps={{
						type: 'tel',
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									onClick={props.checkPincode}
									sx={{ height: '36px', width: '36px' }}
								>
									<Box>
										<RightArrowIcon color={getColor('Minty 0')} />
									</Box>
								</IconButton>
							</InputAdornment>
						),
					}}
					sx={{
						marginTop: '28px',
						width: '100%',
						'& .MuiInputBase-root': {
							borderRadius: '30px',
						},
						'& .MuiInputBase-input': {
							padding: '20px 32px',
						},
					}}
				/>
			</form>
			{props.currentlySelectedPincode && (
				<FlexBox mt={'18px'}>
					<LencoTypography
						mr='4px'
						variant='Small_Medium'
						color={getColor('Gray -2')}
					>
						Currently selected pincode :{' '}
					</LencoTypography>
					<LencoTypography
						variant='Small_SemiBold'
					
					>
						{props.currentlySelectedPincode}
					</LencoTypography>
				</FlexBox>
			)}
		</FlexBox>
	);
};

export default PincodeInput;
