export enum SeoActions{
    SEO_DATA_LOADING='SEO_DATA_LOADING',
    SEO_DATA_SUCCESS='SEO_DATA_SUCCESS',
    SEO_DATA_ERRORED='SEO_DATA_ERRORED'
}

export enum SeoDataState {
    LOADING = "LOADING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}