import { getColor } from '@/utils/theme/colors';
import { Box  ,  useTheme} from '@mui/material';
import React, { useCallback, useState } from 'react';
import SingleOtpBox from './SingleOtpBox'

interface OTPInputProps {
	length: number;
	onChangeOTP: (otp: string) => any;
	autoFocus?: boolean;
	otpError?: string;
	VerifyOtp: Function;
}

const OTPInputComponent = (props: OTPInputProps) => {
	const [activeInput, setActiveInput] = useState(0);
	const [otpValue, setOTPValue] = useState(
		Array<string>(props.length).fill('')
	);
	const handleOtpChange = useCallback(
		(otp: string[]) => {
			const otpValue = otp.join('');
			props.onChangeOTP(otpValue);
		},
		[props.onChangeOTP]
	);

	const theme = useTheme();
	const changeCodeAtFocus = useCallback(
		(value: string) => {
			const updatedOtp = [...otpValue];
			updatedOtp[activeInput] = value[0] || '';
			setOTPValue(updatedOtp);
			handleOtpChange(updatedOtp);
		},
		[handleOtpChange, activeInput, otpValue]
	);

	const focusInput = useCallback(
		(inputIndex: number) => {
			const selectedIndex = Math.max(Math.min(props.length - 1, inputIndex), 0);
			setActiveInput(selectedIndex);
		},
		[length]
	);
	const focusPrevInput = useCallback(() => {
		focusInput(activeInput - 1);
	}, [focusInput, activeInput]);

	const handleOnKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			const pressedKey = e.key;
			switch (pressedKey) {
				case 'Backspace':
				case 'Delete': {
					if (otpValue[activeInput]) {
						changeCodeAtFocus('');
					} else {
						focusPrevInput();
					}
				}
				default: {
					if (pressedKey.match(/^[^0-9]$/)) {
						e.preventDefault();
					}
					break;
				}
			}
		},
		[activeInput, changeCodeAtFocus, focusPrevInput, otpValue]
	);

	const focusNextInput = useCallback(() => {
		focusInput(activeInput + 1);
	}, [focusInput, activeInput]);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.currentTarget.value;
			if (!value) return;
			changeCodeAtFocus(value);
			focusNextInput();
		},
		[changeCodeAtFocus, focusNextInput]
	);

	const border = props.otpError
		?  `1px solid ${theme?.colors?.lencoSecondaryN3}`
		:  `1px solid ${theme?.colors?.lencoSecondaryN3}`

	return (
		<Box sx={{ display: 'flex' }}>
			{Array(props.length)
				.fill('')
				.map((_, index) => (
					<SingleOtpBox
						key={`SingleInput-${index}`}
						value={otpValue && otpValue[index]}
						focus={activeInput === index}
						otpValueAtIndex={otpValue && otpValue[index]}
						onChange={handleChange}
						onKeyDown={handleOnKeyDown}
						border={border}
						VerifyOtp={props.VerifyOtp}
					/>
				))}
		</Box>
	);
};

export default OTPInputComponent;
