import { getLocationFromLocalStorage } from '@/utils/location';
import { getDataFromLocalStorage } from '@/utils/storage';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

type LencoAxiosCustomOptionType = {
  interceptors: {
    request?: (axiosRequestConfig: AxiosRequestConfig<any>) => AxiosRequestConfig<any>;
    response?: () => any;
    error?: () => any;
  };
};
export type LencoAxiosRequestConfig = {
  serviceName: string;
  axios?: Partial<AxiosRequestConfig>;
  customOptions?: LencoAxiosCustomOptionType;
};

const defaultAxiosOptions: AxiosRequestConfig = {
  // @ts-ignore
  baseURL: `${ENV.CIA_HOST}/api/v1`,
  withCredentials: true,
};

const X_PINCODE_HEADER = 'x-pincode';
const X_CITY_ID_HEADER = 'x-city-id';
const X_MORIARITY = 'moriarty'

function appendLocationHeaders(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  const location = getLocationFromLocalStorage();

  const { cityId, pincode } = location;

  const selectedPlan = localStorage.getItem('selectedPlan');
  if (config?.headers && !config?.headers[X_CITY_ID_HEADER]) {
    config.headers[X_CITY_ID_HEADER] = cityId;
  }

  if (config?.headers && !config?.headers[X_PINCODE_HEADER]) {
    config.headers[X_PINCODE_HEADER] = pincode;
  }
  if(selectedPlan && window.location.pathname.includes('unlmtd')){
    config.params = {
      ...config.params,
      cityPlanId: selectedPlan 
    }
  }
    config.headers[X_MORIARITY]='web-1.0'
  return config;
}

export default class LencoAxios {
  public client: AxiosInstance;
  constructor(options: LencoAxiosRequestConfig) {
    const requestConfig = { ...defaultAxiosOptions, ...options.axios };
    this.client = LencoAxios.createClients(requestConfig, options.customOptions);
  }

  static createClients(requestConfig: AxiosRequestConfig, customOptions?: LencoAxiosCustomOptionType) {
    const client = axios.create(requestConfig);

    client.interceptors.request.use(appendLocationHeaders);

    if (customOptions?.interceptors?.request) {
      client.interceptors.request.use(LencoAxios.requestInterceptor(customOptions.interceptors.request));
    }
    client.interceptors.response.use(LencoAxios.responseLogger.bind(this), LencoAxios.apiError.bind(this));
    return client;
  }

  static requestInterceptor = (func: (...r: any) => any) => {
    return (configuration: any) => {
      LencoAxios.requestLogger(configuration);
      func(configuration);
      return configuration;
    };
  };

  static responseInterceptor = (func: (...r: any) => any) => {
    return (configuration: AxiosResponse) => {
      LencoAxios.responseLogger(configuration);
      func.call(this);
      return configuration;
    };
  };


  static requestLogger(configuration: AxiosRequestConfig) {
    return configuration;
  }

  static responseLogger(response: AxiosResponse) {
    return response;
  }

  static apiError(error: any) {
    return Promise.reject(error);
  }
}
