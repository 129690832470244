
import BaseService from '@/services/base-service';
import { appendLocationToQueryParams } from '@/utils/interceptors';

class SubscriptionService extends BaseService {
	constructor() {
		super({
			serviceName: 'Subscription',
			axios: {
				baseURL: `${ENV.CIA_HOST}/api/v1/unlmtd`,
			},
			customOptions: {
				interceptors: {
					request: appendLocationToQueryParams,
				},
			},
		});
	}

	public getSubscriptions(): Promise<any> {
        return this.client.get('/subscriptions');
    }

	
}

export const unlmtdSubscriptionService = new SubscriptionService();
