export enum SET_SELECTED_LOCATION {
    SET_SELECTED_LOCATION_SAVING = 'SET_SELECTED_LOCATION_SAVING',
    SET_SELECTED_LOCATION_SUCCESS = 'SET_SELECTED_LOCATION_SUCCESS',
    SET_SELECTED_LOCATION_FAILED = 'SET_SELECTED_LOCATION_FAILED',
}


export enum LOCATION_SELECTOR_TRIGGER_OPTIONS {
	LOCATION_SELECTOR_OPEN = "LOCATION_SELECTOR_OPEN",
	LOCATION_SELECTOR_CLOSE = "LOCATION_SELECTOR_CLOSE",
}