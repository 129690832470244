import FlexBox from '@/atomic-components/FlexBox';
import BuyIcon from '@/components/Icons/BuyIcon';
import { getColor } from '@/utils/theme/colors';
import { Box } from '@mui/material';
import CategoryPopover from './CategoryPopover';
import { Category } from '@/services/catalogue/models/categories';
import { useRouter } from 'next/router';

type BuyPopoverProps = {
	categories?: Category[];
};

const BuyPopover = (props: BuyPopoverProps) => {
	const router = useRouter();

	function navigateToBuy() {
		router.push('/buy');
	}

	return (
		<Box className='popover_wrapper' onClick={navigateToBuy}>
			<Box id='mouse-over-popover' className='popover_content'>
				<CategoryPopover
					title='Buy Furniture'
					description='Own Brand New or Refurbished furniture for your homes'
					categories={props.categories as Category[]}
				/>
			</Box>
			<FlexBox
				alignItems='center'
				className='popover_title'
				sx={{ '&:hover': { color: getColor('Minty 0') } }}
			>
				<Box>
					<BuyIcon />
				</Box>
			</FlexBox>
		</Box>
	);
};

export default BuyPopover;
