import FlexBox from '@/atomic-components/FlexBox';
import LencoButton from '@/atomic-components/LencoButton';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { TCity } from '@/models/location';
import theme from '@/utils/theme';
import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import { Box, Divider } from '@mui/material';

type OtherCitiesProps = {
	otherCities?: TCity[];
	onCityClicked: (city: TCity) => void;
	displayTopCities: () => void;
};

const OtherCities = (props: OtherCitiesProps) => {
	return (
		<Box width='100%'>
			<FlexBox
				mt={'28px'}
				justifyContent='space-between'
				alignItems={'center'}
				fullWidth
			>
				<Divider />
				<LencoTypography
					variant='H11A_Medium'
				
				>
					Other Cities
				</LencoTypography>
				<Divider />
			</FlexBox>
			<FlexBox
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				wrap={'wrap'}
				sx={{ overflow: 'auto' }}
				pt='24px'
			>
				{props.otherCities?.map((city) => (
					<Box
						width='48%'
						key={city.name}
						onClick={() => props.onCityClicked(city)}
						sx={{
							...getStyle('P_Medium'),
							color: getColor('Gray 0'),
							textTransform: 'capitalize',
							borderBottom: '1px solid #E8E8E8',
							p: '16px 0px',
							cursor: 'pointer',
							':hover': {
								...getStyle('P_SemiBold'),
								color: getColor('Minty 0'),
							},
						}}
					>
						<p>{city.name}</p>
					</Box>
				))}
			</FlexBox>
			<LencoButton
				variant='text'
				sx={{
					marginTop: '16px',
					textTransform: 'capitalize',
					textDecoration: 'underline',
					':hover': {
						textDecoration: 'underline',
					},
				}}
				onClick={props.displayTopCities}
			>
				Go Back
			</LencoButton>
		</Box>
	);
};

export default OtherCities;
