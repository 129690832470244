import {
	Box,
	Button,
	ButtonProps,
	CircularProgress,
	CircularProgressProps,
} from '@mui/material';
import FlexBox from '../FlexBox';

export type LencoButtonProps = ButtonProps & {
	isLoading?: boolean;
	circularProgressProps?: CircularProgressProps;
	color?: string
};

const LencoButton = (props: LencoButtonProps) => {
	const { isLoading, children, color ,circularProgressProps, ...rest } = props;

	return (
		<Button {...rest} disabled={isLoading || props.disabled} disableRipple>
			{isLoading ? (
				<FlexBox fullWidth justifyContent='center'>
					<CircularProgress {...circularProgressProps} color={color}/>
				</FlexBox>
			) : (
				children
			)}
		</Button>
	);
};

export default LencoButton;
