import ChatButton from '@/assets/chat.svg';
import { withFullScreenLoader } from '@/hoc/withFullScreenLoader';
import { useFullScreenLoader } from '@/hooks/useFullScreenLoader';
import HaptikHoc from '../haptik';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';

function ChatBotFloatingButton() {
	const { loader } = useFullScreenLoader();

	const onHaptikLaunched = () => {
		loader.hide();
	};

	const launchChatBot = () => {
		loader.show();
	};

	const router=useRouter();



	  const removeYmPluginContainer = () => {
		const ymContainer = document.getElementById('ymDivBar');
		
		if (ymContainer) {
		  ymContainer.remove();
		  console.log('[yellow] ymPluginDivContainerInitial removed from the DOM');
		} else {
		  console.log('[yellow] ymPluginDivContainerInitial not found');
		}
	  };
	  

	  useEffect(()=>{
		if(!router?.pathname?.includes('unlmtd')){
		removeYmPluginContainer();

		}
	  },[router.pathname])

	return (
		<Box>
		<HaptikHoc onClick={launchChatBot} onLaunch={onHaptikLaunched}>
			<div
				className={
					'haptik-lets-chat d-flex justify-content-center align-items-center'
				}
				style={{
					position: 'fixed',
					zIndex: '99',
					right: 28,
					bottom: 40,
					cursor: 'pointer',
				}}
			>
				<ChatButton />
			</div>
		</HaptikHoc>
		</Box>
	);
}

export default withFullScreenLoader(ChatBotFloatingButton);
